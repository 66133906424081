type Props = {
	text: string;
	placeholder: string;
	linkText: string;
	linkUrl: string;
};

function WordingMatrixStringWithLink({ text, placeholder, linkText, linkUrl }: Props) {
	const [before, after] = text.split(placeholder);

	return (
		<>
			{before}
			<a href={linkUrl} target="_blank" rel="noreferrer">
				{linkText}
			</a>
			{after}
		</>
	);
}

export default WordingMatrixStringWithLink;
