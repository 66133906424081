export default async function fetchCustomTokenAndEmailByIdToken(
	idToken: string,
): Promise<{ customToken: string; email: string } | undefined> {
	try {
		const res = await fetch('/token', {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({ idToken }),
		});

		if (res.ok) {
			return await res.json();
		} else {
			throw new Error(`Error fetching Custom Token by idToken | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		console.error(error);
	}
}
