import { css } from '@linaria/core';

export const InputWrapper = {
	base: css`
		display: flex;
		position: relative;

		input {
			padding-left: calc(var(--icon-sizing-icon-s) + (var(--input-spacing-padding-lr-rem) * 4));
			padding-right: calc(var(--icon-sizing-icon-s) + (var(--input-spacing-padding-lr-rem) * 4));
			text-transform: uppercase;
		}
	`,
};

export const ImageWrapper = {
	base: css`
		img {
			width: 100%;
		}
	`,
};

export const CardInfo = {
	base: css`
		display: flex;
		gap: 16px;
		align-items: center;
		> svg {
			fill: rgb(var(--text-color-body-01));
			width: var(--icon-sizing-icon-l);
		}
	`,
	isError: css`
		> svg {
			fill: rgb(var(--input-color-warning));
		}
		p {
			color: rgb(var(--input-color-warning));
		}
	`,
};
