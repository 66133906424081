import { AnyFunction, AnyObject } from '../../../types';
import { Button, Column, Input, Row, Typo } from '@ard-online/component-styleguide';
import { Link } from 'react-router-dom';
import { isValidEmail } from '../../../services/validation';
import { isTouchDevice } from '../../../services/misc';
import { PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { EndColumn } from './styles';

function CredentialsWidget({
	email,
	setEmail,
	setPassword,
	isPasswordPromt,
}: {
	email: string | null;
	setEmail: AnyFunction;
	setPassword: AnyFunction;
	isPasswordPromt?: boolean;
}) {
	/** Variables */
	function onEmailInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	function onPasswordInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword('');
		}
	}

	function onChangeEmailClick() {
		userService.email = null;
		setEmail(null);
	}

	/** JSX */
	return (
		<Row p={0} margin={0}>
			<Column p={0}>
				<p className={cx(Typo.base, Typo.body02)}>Anmelden</p>
			</Column>
			{!isPasswordPromt &&
				(userService.email ? (
					<Column p={0} margin={0}>
						<Row alignItems="center" p={0} margin={0}>
							<Column all={6} p={0} margin={0}>
								<div>{email}</div>
								<input type="hidden" name="userId" value={email ?? ''} />
							</Column>
							<Column all={6} p={0} className={cx(EndColumn.base)}>
								<Button
									type={BUTTON_TYPES.TEXT}
									text={STRINGS.credentialsWidget.emailExists.button}
									htmlAttributes={{ title: 'Andere E-Mail-Adresse eingeben', type: 'button' }}
									onClick={onChangeEmailClick}
								/>
							</Column>
						</Row>
					</Column>
				) : (
					<Column p={0}>
						<Input
							type="email"
							label="E-Mail-Adresse"
							isRequired
							errorMessage="Keine valide E-Mail-Adresse"
							onChange={onEmailInputChange}
							validation={isValidEmail}
							hasFocus={!isTouchDevice()}
							htmlAttributes={{
								autoComplete: 'email',
								title: STRINGS.credentialsWidget.emailInput.text,
							}}
							name="userId"
							id="userIdInput"
						/>
					</Column>
				))}
			{(!(userService.id && userService.email === email) || isPasswordPromt) && (
				<>
					<Column p={0} pt={2}>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onPasswordInputChange}
							hasFocus={!!email && !isTouchDevice()}
							htmlAttributes={{
								autoComplete: 'current-password',
								title: STRINGS.credentialsWidget.passwordInput.text,
							}}
							name="password"
							id="passwordInput"
						/>
					</Column>
					<Column px={0} pb={0} className={cx(EndColumn.base)}>
						<Link to={`/${PAGE_TYPES.FORGOT_PASSWORD}${email ? `#email=${email}` : ''}`}>
							{STRINGS.credentialsWidget.button}
						</Link>
					</Column>
				</>
			)}
		</Row>
	);
}

export default CredentialsWidget;
