import corejs from 'core-js';
import './libs/ati-smarttag';
import { ReactElement, useLayoutEffect, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/app/App';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as serviceWorker from './serviceWorker';
import ActionsService from './services/actions/ActionsService';
import userService from './services/user/UserService';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import settingsService from './services/settings/SettingsService';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
corejs; // prevent CRA to remove core-js

console.info(
	'👤 Account Management System',
	`🔢 Version: ${VERSION}`,
	`📦 Commit: ${COMMIT_HASH}`,
	`🕙 BuildDate: ${BUILD_DATE && new Date(BUILD_DATE).toLocaleString()}`,
);

const ScrollToTopWrapper = ({ children }: { children: ReactElement }) => {
	const location = useLocation();
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);
	return children;
};

(async () => {
	await userService.init();
	await settingsService.init();

	const rootContainer = document.getElementById('root') as HTMLElement;
	const root = createRoot(rootContainer);
	root.render(
		<StrictMode>
			<Router>
				<ActionsService>
					<ScrollToTopWrapper>
						<App />
					</ScrollToTopWrapper>
				</ActionsService>
			</Router>
		</StrictMode>,
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
})();
