import { FormEvent, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ButtonWrapper } from './styles';
import { Button, Input, Typo, Row, Column } from '@ard-online/component-styleguide';
import { Box } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import LoadingIndicator from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { isTouchDevice } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { CLIENT_TYPES, INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import PromoBoardWidget from '../../widgets/promoBoard/PromoBoardWidget';
import { AnyObject } from '../../../types';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import CreateAccountButtonWidget from '../../widgets/createAccountButton/CreateAccountButtonWidget';
import { ErrorMessages } from '../../../configs/constants';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function getDeviceAuthorizationApiUrl(userCode: string) {
	return `/device/authorize/${userCode}`;
}

function UserCodeLoginPage() {
	/** Variables */
	const navigate = useNavigate();
	const { userCode: userCodeParam } = useParams<{ userCode: string }>();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const [userCode, setUserCode] = useState(userCodeParam);
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const hasPromoBoard =
		appState.navigation.clientId === CLIENT_TYPES.MEDIATHEK ||
		appState.navigation.clientId === CLIENT_TYPES.AUDIOTHEK;

	let submittable = false;
	if ((email && password && userCode) || (userService.id && userCode)) {
		submittable = true;
	}

	let showLoadingIndicator = false;
	if (userService.id && loading) {
		showLoadingIndicator = true;
	}

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (submittable && !loading) {
			if (userService.id) {
				await authorizeDevice();
			} else {
				setLoading(true);
				setMessage(null);
				try {
					await loginWithEmailPassword(email as string, password);
					await sso(getDeviceAuthorizationApiUrl(userCode as string));
				} catch (error) {
					error instanceof Error && setMessage(error.message);
					setLoading(false);
				}
			}
		}
	}

	async function authorizeDevice() {
		if (userCode) {
			const res = await fetch(getDeviceAuthorizationApiUrl(userCode), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					idToken: userService.idToken,
					refreshToken: userService.refreshToken,
				}),
				redirect: 'manual',
			});

			if (res.type === 'opaqueredirect') {
				navigate(buildInfoLink(INFO_TYPES.DEVICE_AUTHORIZATION_COMPLETED));
			} else if (!res.ok) {
				if (userCodeParam) {
					navigate(buildInfoLink(INFO_TYPES.ERROR_DEVICE_AUTHORIZATION));
				} else {
					setLoading(false);
					setMessage(ErrorMessages.INVALID_USER_CODE);
				}
			}
		}
	}

	function onUserCodeInputChange(e: AnyObject) {
		if (e.isValid) {
			setUserCode(e.value);
		} else {
			setUserCode('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			{showLoadingIndicator ? (
				<LoadingIndicator />
			) : (
				<>
					<Row>
						<Column px={0}>
							<h1 className={cx(Typo.base, Typo.heading01)}>
								{userService.id
									? STRINGS.userCodeLoginPage.loggedIn.headline
									: STRINGS.userCodeLoginPage.loggedOut.headline}
							</h1>
						</Column>

						{hasPromoBoard && (
							<Column px={0}>
								<PromoBoardWidget />
							</Column>
						)}
						<Column p={0}>
							<form onSubmit={onSubmit}>
								<Column p={0}>
									{!userService.id && (
										<Column p={0}>
											<CredentialsWidget
												email={email}
												setEmail={setEmail}
												setPassword={setPassword}
											/>
										</Column>
									)}
									{!userCodeParam ? (
										<Column p={0} pt={2}>
											{userService.id && (
												<p className={cx(Typo.base, Typo.body02)}>
													{STRINGS.userCodeLoginPage.loggedIn.prompt.text}
												</p>
											)}
											<Input
												type="text"
												label={STRINGS.userCodeLoginPage.loggedIn.input.text}
												isRequired
												errorMessage="Kein valider Code"
												htmlAttributes={{
													minLength: 5,
													title: 'Code eingeben',
												}}
												hasFocus={!!userService.id && !isTouchDevice()}
												onChange={onUserCodeInputChange}
												name="code"
											/>
										</Column>
									) : (
										<Column p={0} pt={2}>
											{userService.id && (
												<p className={cx(Typo.base, Typo.body02)}>
													{STRINGS.userCodeLoginPage.loggedOut.prompt.text}
												</p>
											)}
										</Column>
									)}

									{message && (
										<Column>
											<MessageWidget>{message}</MessageWidget>
										</Column>
									)}

									<hr />
								</Column>
								<Row margin={0} p={0} justifyContent="center">
									<Column s={8} p={0}>
										<div className={cx(ButtonWrapper.base)}>
											<Button
												text={STRINGS.general.signIn.button}
												type={BUTTON_TYPES.PRIMARY}
												isDisabled={!submittable}
												isLoading={loading}
												htmlAttributes={{
													type: 'submit',
												}}
											/>
										</div>
									</Column>
									{userCodeParam && (
										<Column s={8} p={0}>
											<div className={cx(ButtonWrapper.base)}>
												<Button
													text={STRINGS.general.cancel.button}
													type={BUTTON_TYPES.SECONDARY}
													href={buildMainLink(PAGE_TYPES.START)}
													as={Link}
													htmlAttributes={{
														type: 'button',
													}}
												/>
											</div>
										</Column>
									)}
									<Column s={8} p={0}>
										<div className={cx(ButtonWrapper.base)}>
											<CreateAccountButtonWidget />
										</div>
									</Column>
								</Row>
							</form>
						</Column>
					</Row>

					{ssoForm}
				</>
			)}
		</main>
	);
}

export default UserCodeLoginPage;
