import { useState } from 'react';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { PAGE_TYPES } from '../../../configs/types';
import { useNavigate } from 'react-router-dom';
import { buildMainLink } from '../../../services/link';
import type { AnyFunction } from '../../../types';
import MessageWidget from '../../widgets/message/MessageWidget';
import { ErrorMessages } from '../../../configs/constants';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useTrackPageImpression } from '../../../services/tracking';

import { fetchSetOrDeleteChildPin } from '../../../services/fetch';
import userService from '../../../services/user/UserService';
import { useOnDeeplinkCancel } from '../../../services/misc/useOnDeeplinkCancel';

function ChildPinDeletePage() {
	/** Variables */
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const navigate = useNavigate();
	const onDeeplinkCancel = useOnDeeplinkCancel();

	async function onClickDelete() {
		setIsLoading(true);

		if (!isLoading) {
			try {
				await fetchSetOrDeleteChildPin();
				await userService.refreshIdToken();
				navigate(buildMainLink(PAGE_TYPES.ACCOUNT));
			} catch (error) {
				console.error(error);
				setIsLoading(false);
				setMessage(ErrorMessages.DEFAULT);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<Row>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.childrenPinDeletePage.headline}</h1>
				</Column>
				<Column>
					<p className={cx(Typo.base, Typo.body02)}>{STRINGS.childrenPinDeletePage.text}</p>
				</Column>
				{message && (
					<Column>
						<MessageWidget>{message}</MessageWidget>
					</Column>
				)}
				<Column>
					<div className={cx(ButtonsWrapper.base)}>
						<Button
							text={STRINGS.general.cancel.button}
							type={BUTTON_TYPES.SECONDARY}
							onClick={onDeeplinkCancel}
							htmlAttributes={{
								type: 'button',
							}}
						/>
						<Button
							text={STRINGS.editAccountPage.deleteKidsPin.button}
							onClick={onClickDelete}
							type={BUTTON_TYPES.PRIMARY}
							isLoading={isLoading}
						/>
					</div>
				</Column>
			</Row>
		</main>
	);
}

export default ChildPinDeletePage;
