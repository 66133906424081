import { FormEvent, useState } from 'react';
import { List } from './styles';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Typo, Input, Icon, Row, Column } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { handleConfirmPasswordError, isTouchDevice } from '../../../services/misc';
import { AnyObject } from '../../../types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { Link } from 'react-router-dom';

const BULLETPOINTS = [
	'ARD-übergreifendes Konto',
	'Merklisten und Inhalte zum Weiterschauen, -hören und -lesen',
	'Personalisierte Empfehlungen',
	'Altersfreigabe',
	'Synchronisation über alle Geräte',
];

function DeleteAccountPage() {
	/** Variables */
	const email = userService.email;
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState(null);

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (email && password && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				await userService.validatePassword(password);
				try {
					await userService.deleteMainUser();
				} catch (error) {
					console.error(error);
					setMessage(STRINGS.deleteAccountPage.submit.error.text);
					setLoading(false);
				}
			} catch (error) {
				const message = handleConfirmPasswordError(error);
				setMessage(message);
				setLoading(false);
			}
		}
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword(null);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<form onSubmit={onSubmit}>
				<Row>
					<Column>
						<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.deleteAccountPage.headline}</h1>
					</Column>
					<Column>
						<p className={cx(Typo.base, Typo.body02)}>{STRINGS.deleteAccountPage.text}</p>
					</Column>
					<Column py={0}>
						<ul className={cx(List.base)}>
							{BULLETPOINTS.map((bulletpoint, index) => (
								<li key={index}>
									<Icon name="warning" />
									{bulletpoint}
								</li>
							))}
						</ul>
					</Column>
					<Column>
						<p className={cx(Typo.base, Typo.body02)}>{STRINGS.deleteAccountPage.password.text}</p>
						<span className={cx(Typo.base, Typo.body02)}>{email}.</span>
					</Column>
					<Column s={8} py={0} margin={0}>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onInputChange}
							htmlAttributes={{
								autoComplete: 'current-password',
								title: 'Passwort eingeben',
							}}
							hasFocus={!isTouchDevice()}
						/>
					</Column>
					{message && (
						<Column>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.general.cancel.button}
								type={BUTTON_TYPES.SECONDARY}
								href={buildMainLink(PAGE_TYPES.ACCOUNT)}
								htmlAttributes={{
									type: 'button',
								}}
								as={Link}
							/>
							<Button
								type={BUTTON_TYPES.PRIMARY}
								text={STRINGS.deleteAccountPage.delete.button}
								isDisabled={!(email && password)}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</Row>
			</form>
		</main>
	);
}

export default DeleteAccountPage;
