import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const Checkbox = {
	base: css`
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		input {
			cursor: pointer;
			margin-right: 16px;
		}
		a {
			font-weight: 600;
			text-decoration: underline;
		}
	`,
};

export const SentMailAgainButtonWrapper = {
	base: css`
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		> p {
			flex-shrink: 0;
			margin-right: 10px;
		}

		.${ButtonRoot.base} {
			> span {
				padding: 0;
				text-decoration: underline;
			}
			&:after {
				content: none;
			}
		}
	`,
};

export const EmailDisplay = {
	base: css`
		display: flex;
		justify-content: space-between;
	`,
};
