import { cx } from '@linaria/core';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import userService from '../../../services/user/UserService';
import { FormEvent, useState } from 'react';
import { handleConfirmPasswordError } from '../../../services/misc';
import MessageWidget from '../../widgets/message/MessageWidget';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';
import { Box, ButtonsWrapper } from '../../app/styles';

function ConfirmPasswordPage() {
	const { setPasswordConfirmed } = useContextActions();

	const [email, setEmail] = useState(userService.email);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState(null);

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (email && password && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				await userService.validatePassword(password);
				setPasswordConfirmed();
			} catch (error) {
				const message = handleConfirmPasswordError(error);
				setMessage(message);
				setLoading(false);
			}
		}
	}

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row p={0} margin={0}>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading02)}>{STRINGS.securityEditPage.MainPWD.headline}</h1>
				</Column>
			</Row>

			<form onSubmit={onSubmit}>
				<Row p={0} margin={0}>
					<Column>
						<CredentialsWidget
							email={email}
							setEmail={setEmail}
							setPassword={setPassword}
							isPasswordPromt
						/>
					</Column>
					{message && (
						<Column>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.profilePage.confirm.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!(email && password && userService.id)}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</Row>
			</form>
		</main>
	);
}

export default ConfirmPasswordPage;
