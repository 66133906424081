import { css } from '@linaria/core';

export const SentMailAgainButtonWrapper = {
	base: css`
		display: flex;
		align-items: center;
		span {
			text-decoration: underline;
		}
	`,
};
