import { redirectToRefererApp } from '../../../../services/misc';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { buildMainLink } from '../../../../services/link';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../../configs/types';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { shouldRedirectToRefererApp } from '../../../../services/misc/shouldRedirectToRefererApp';

function AgeVerificationCompleted() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	async function onClick() {
		if (shouldRedirectToRefererApp(appState.navigation)) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.AGE_VERIFICATION_CONFIRMED);
		} else {
			navigate(buildMainLink(PAGE_TYPES.ACCOUNT));
		}
	}

	/** JSX */
	return (
		<Row>
			<Column p={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Altersfreigabe erfolgreich!</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Ab jetzt können Sie altersbeschränkte Inhalte rund um die Uhr nutzen. Sollten Sie bereits auf einem
					Gerät angemeldet sein, müssen Sie sich ab- und erneut anmelden, um die Freischaltung zu aktivieren.
				</p>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button text={STRINGS.general.ok.button} onClick={onClick} type={BUTTON_TYPES.PRIMARY} />
				</div>
			</Column>
		</Row>
	);
}

export default AgeVerificationCompleted;
