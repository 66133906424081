import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';
import { Icon, Button, Typo, Column, Row } from '@ard-online/component-styleguide';
import parseSophoraCopyText from '../../../services/misc/parseSpohoraCopyText';
import { EXTERNAL_URLS } from '../../../configs/constants';
import { useNavigate } from 'react-router-dom';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useFetch } from '../../../services/fetch';
import { AnyObject } from '../../../types';
import { Box, ParagraphsWrapper } from '../../app/styles';

function PrivacyPage() {
	/** Variables */
	const data: AnyObject = useFetch(`${EXTERNAL_URLS.SOPHORA_HOST}/Datenschutz-ARD-Konto-100~_plaintext-true.json`);
	const navigate = useNavigate();

	const title = data?.headline ?? '';
	const dataProtectionText = parseSophoraCopyText(data);

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<div className={cx(Box.base, Box.type.l)}>
			<Row>
				<Column p={0}>
					<Button
						text={STRINGS.general.back.button}
						type={BUTTON_TYPES.TEXT}
						iconLeft={<Icon name="back" />}
						onClick={() => {
							navigate(-1);
						}}
						htmlAttributes={{
							type: 'button',
						}}
					/>
				</Column>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading01)}>{title}</h1>
				</Column>
				<Column>
					<section className={cx(Typo.base, Typo.body03, ParagraphsWrapper.base)}>
						{dataProtectionText}
					</section>
				</Column>
			</Row>
		</div>
	);
}

export default PrivacyPage;
