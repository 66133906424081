import { css } from '@linaria/core';
import { Root as InputRoot } from '@ard-online/component-styleguide/dist/components/Input/styles';

export const Root = {
	base: css``,
};

export const Text = {
	base: css`
		margin-top: 24px;
	`,
};

export const InputWrapper = {
	base: css`
		width: 100%;
		max-width: 400px;
		margin: 24px auto 8px;

		.${InputRoot.base} {
			margin: 0 auto;
		}
	`,
};
