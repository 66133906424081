import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';
import { ButtonWrapper as ShowMoreButtonWrapper } from '@ard-online/component-styleguide/dist/components/ShowMore/styles';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
export const Root = {
	base: css`
		position: relative;
		background-color: rgb(var(--bottomBar-color-background));
		z-index: 2;
		margin-top: auto;
	`,
};

export const Big5IconsWrapper = {
	base: css`
		display: flex;
		width: 100%;
		justify-content: center;
	`,
};

export const Big5List = {
	base: css`
		list-style: none;
		display: flex;
		padding: 0;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	`,
};

export const Big5Icon = {
	base: css`
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 10px 2%;

		.${ButtonRoot.base} {
			div {
				width: var(--icon-sizing-icon-2xl) !important;
				height: var(--icon-sizing-icon-2xl) !important;
			}
		}

		${Media('max', 'xs')} {
			flex-basis: 25%;
		}

		h3 {
			text-transform: none !important;
			margin-top: 10px;
			color: rgb(var(--footer-color-text));
			text-align: center;
		}
	`,
};

export const ListColumn = {
	base: css`
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.${ShowMoreButtonWrapper.base} {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	`,
};

export const BottomNav = {
	base: css`
		ul {
			list-style: none;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			padding: 0;

			li {
				margin: 10px 0.75rem;
				${Media('max', 'xs')} {
					flex-basis: 100%;
					text-align: center;
					margin-right: 0;
					margin-left: 0;
					padding: 0;
				}
				padding: 0;
			}
		}
	`,
};
