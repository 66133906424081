import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typo, Input, Row, Column } from '@ard-online/component-styleguide';
import { Box, ButtonsWrapper } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../../firebase';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import type { AnyFunction, AnyObject } from '../../../types';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { useSso } from '../login/misc';
import { isGoogleApiError } from '../../../services/misc';
import PasswordWidget from '../../widgets/password/PasswordWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';

function NewPasswordPage() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const oobCode = searchParams.get('oobCode');
	const [loading, setLoading] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const isForgotPasswordPage = oobCode;
	const isNextStepAllowed = (isForgotPasswordPage && newPassword) || (newPassword && oldPassword);

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage('');

			try {
				const email = userService.email;
				// Password reset case
				if (isForgotPasswordPage) {
					await confirmPasswordReset(auth, oobCode, newPassword);
					if (email) {
						await userService.loginWithPassword(email, newPassword);
						await sso(buildInfoLink(INFO_TYPES.PASSWORD_CHANGED, appState.navigation.redirectUrl));
					}
					// Password change case
				} else if (email) {
					await userService.changePassword(oldPassword, newPassword);
					await sso(buildInfoLink(INFO_TYPES.PASSWORD_CHANGED, appState.navigation.redirectUrl));
				}
			} catch (error) {
				console.error(error);

				if (isGoogleApiError(error) && error.code === GoogleApiCodes.WRONG_PASSWORD) {
					setMessage('Altes Passwort falsch eingegeben.');
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onOldPasswordChange(e: AnyObject) {
		if (e.isValid) {
			setOldPassword(e.value);
		} else {
			setOldPassword('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!oobCode && !userService.id) {
			navigate(buildMainLink(PAGE_TYPES.START));
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<Column px={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>
						{isForgotPasswordPage
							? STRINGS.newPasswordPage.isForgotPasswordPage.headline
							: STRINGS.newPasswordPage.headline}
					</h1>
				</Column>
				<form onSubmit={onSubmit}>
					<Column p={0}>
						<p className={cx(Typo.base, Typo.body02)}>
							{isForgotPasswordPage
								? STRINGS.newPasswordPage.isForgotPasswordPage.text
								: STRINGS.newPasswordPage.text}
						</p>
					</Column>
					<Column p={0}>
						<PasswordWidget onValidPassword={setNewPassword} inputLabel="Neues Passwort" />
					</Column>
					{!oobCode && userService.id && (
						<Column p={0}>
							<p className={cx(Typo.base, Typo.body02)}>
								Bestätigen Sie die Änderung mit Ihrem bestehenden Passwort.
							</p>
							<Input
								id="oldPasswordInput"
								type="password"
								label="Altes Passwort"
								isRequired
								errorMessage="Kein valides Passwort"
								onChange={onOldPasswordChange}
								htmlAttributes={{
									autoComplete: 'current-password',
									title: 'Altes Passwort eingeben',
								}}
							/>
						</Column>
					)}

					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							{!isForgotPasswordPage && (
								<Button
									text={STRINGS.general.cancel.button}
									type={BUTTON_TYPES.SECONDARY}
									href={buildMainLink(PAGE_TYPES.ACCOUNT)}
									as={Link}
									htmlAttributes={{
										type: 'button',
									}}
								/>
							)}
							<Button
								text={isForgotPasswordPage ? STRINGS.general.next.button : 'Passwort ändern'}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!isNextStepAllowed}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
			{ssoForm}
		</main>
	);
}

export default NewPasswordPage;
