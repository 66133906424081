import { Root } from './styles';
import { Icon, Typo } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

const listItems = [
	STRINGS.promoBoardWidget.list_1.text,
	STRINGS.promoBoardWidget.list_2.text,
	STRINGS.promoBoardWidget.list_3.text,
	STRINGS.promoBoardWidget.list_4.text,
	STRINGS.promoBoardWidget.list_5.text,
];

function PromoBoardWidget() {
	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<ul>
				{listItems.map((item, index) => {
					return (
						<li key={`promoListItem${index}`}>
							<Icon name="checkmark" type="solid" />
							<p className={cx(Typo.base, Typo.body03)}>{item}</p>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default PromoBoardWidget;
