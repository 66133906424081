import { useContextActions } from '../../../services/actions/ActionsService';
import { Column, Icon, Image, Input, Row } from '@ard-online/component-styleguide';
import { isTouchDevice } from '../../../services/misc';
import { useState } from 'react';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { cx } from '@linaria/core';
import ChildLabelWidget from '../childLabel/ChildLabelWidget';
import { EditButton, ImageColumn } from './styles';
import { Link } from 'react-router-dom';
import InfoBoxWidget from '../infoBox/InfoBoxWidget';

const maxTextLength = 20;

const ErrorMessages = {
	DEFAULT: 'Bitte geben Sie einen Namen ein.',
	MAX_LENGTH: `Der Name ist zu lang (max ${maxTextLength} Zeichen).`,
};

type ProfileNameObject = {
	value: string;
	isValid: boolean;
};

type Props = {
	url?: string;
	ageRating?: number;
	isMainUser?: boolean;
};

function ProfileNameWithImageWidget({
	url = buildMainLink(`${PAGE_TYPES.PROFILE}/${PAGE_TYPES.PROFILE_IMAGE}`),
	ageRating,
	isMainUser,
}: Props) {
	/** Variables */
	const [errorMessage, setErrorMessage] = useState<string>('');

	const { appState, setNextUser } = useContextActions();

	function onInputChange(e: ProfileNameObject) {
		if (e.isValid) {
			setNextUser({ name: e.value });
			setErrorMessage('');
		} else {
			setErrorMessage(e.value.trim().length > maxTextLength ? ErrorMessages.MAX_LENGTH : ErrorMessages.DEFAULT);
		}
	}

	/** JSX */
	return (
		<Row alignItems="center">
			<Column xs={12} s={8} p={0}>
				<Row margin={0} p={0}>
					<Column px={0}>
						<Input
							type="text"
							id="profilname"
							label="Profilname"
							isRequired
							errorMessage={errorMessage}
							isShowingError={!!errorMessage}
							onChange={onInputChange}
							hasFocus={!isTouchDevice()}
							validation={(text) => !!text.trim() && text.trim().length <= maxTextLength}
							htmlAttributes={{ title: 'Profilnamen eingeben' }}
							initialValue={appState.account.nextUser.name}
						/>
					</Column>
					<Column p={0} pb={2}>
						<InfoBoxWidget>{STRINGS.profilePage.profileName.text}</InfoBoxWidget>
					</Column>
					<Column p={0}>
						{isMainUser && <InfoBoxWidget>{STRINGS.profilePage.mainAccountInfo.text}</InfoBoxWidget>}
					</Column>
				</Row>
			</Column>
			<Column xs={12} s={4} p={0} className={cx(ImageColumn.base)}>
				<Link to={url} aria-label={STRINGS.profilePage.avatarSelection.headline}>
					<Image src={appState.account.nextUser.image} ratio="1x1" alt="Momentan Ausgewähltes Profilbild" />
					<div className={cx(EditButton.base)}>
						<Icon name="edit" />
					</div>
					{ageRating !== undefined && ageRating <= 13 && <ChildLabelWidget ageRating={ageRating} />}
				</Link>
			</Column>
		</Row>
	);
}

export default ProfileNameWithImageWidget;
