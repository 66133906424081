import { isRedirectAllowed } from './index';
import userService from '../user/UserService';
import buildRedirectLink from '../link/buildRedirectLink';
import { MESSAGE_TYPES } from '../../configs/types';

export default async function redirectToRefererApp(
	redirectUrl: string,
	messageType: MESSAGE_TYPES,
	idToken?: string | null,
	showAccountMenu?: boolean,
): Promise<void> {
	if (redirectUrl) {
		redirectExternal(
			buildRedirectLink(
				redirectUrl,
				messageType,
				idToken || userService.idToken,
				userService.refreshToken,
				(userService.id && (await userService.customToken)) || null,
				showAccountMenu,
			),
		);
	}
}

export function redirectExternal(url: string): void {
	if (isRedirectAllowed(url)) {
		window.location.href = url;
	} else {
		window.location.href = '/origin_url_not_allowed';
	}
}
