import { useRef } from 'react';
import { Typo, Image, Icon } from '@ard-online/component-styleguide';
import SwiperButton from '@ard-online/component-styleguide/dist/components/Swiper/SwiperButton/SwiperButton';
import { cx } from '@linaria/core';
import { ProfileImage as ProfileImageStyle, ProfileImageSwiperContainer, Checkmark } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper/modules';
import SwiperCore from 'swiper';
import type { Swiper as SwiperInstance } from 'swiper';

type Props = {
	selectedImage: string;
	onClickImage: (image: string) => void;
	items: Array<string>;
	title: string;
};

function ProfileImageSwiperWidget({ selectedImage, onClickImage, items, title }: Props) {
	/** Variables */
	const sectionElement = useRef<HTMLDivElement>(null);
	const swiperRef = useRef<SwiperInstance | null>(null);

	let selectedImageIndex: number | undefined;

	/** Construktor-Hook & Effect-Hooks */
	SwiperCore.use([A11y, Navigation]);

	function scrollToSelectedImage(swiper: SwiperInstance) {
		swiperRef.current = swiper;

		// Auto-scroll to the child section with 200ms offset, because of the render animation
		const hasSelectedImage = items.includes(selectedImage);
		if (hasSelectedImage) {
			const selectedSection = swiperRef.current?.el?.parentElement;

			if (selectedSection) {
				setTimeout(() => {
					selectedSection.scrollIntoView({ behavior: 'smooth' });
				}, 200);
			}
		}

		// Auto-swipe to selected profile image by showing selected slide in the viewport
		if (selectedImageIndex !== undefined) {
			swiperRef.current?.slideTo(items.length > 3 ? selectedImageIndex - 1 : selectedImageIndex);
		}
	}

	/** JSX */
	return (
		<section className={cx(ProfileImageSwiperContainer.base)} ref={sectionElement} data-testid={title}>
			<h4 className={cx(Typo.base, Typo.heading04)}>{title}</h4>

			<Swiper
				onSwiper={(swiper: SwiperInstance) => scrollToSelectedImage(swiper)}
				spaceBetween={32}
				slidesPerView="auto"
				navigation={{
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}}
				a11y={{
					firstSlideMessage: 'Erster Eintrag',
					lastSlideMessage: 'Letzter Eintrag',
					prevSlideMessage: 'Vorheriger Eintrag',
					nextSlideMessage: 'Nächster Eintrag',
				}}
			>
				{items &&
					items.map((image: string, index: number) => {
						const isCurrentImage = selectedImage === image;
						if (isCurrentImage && selectedImageIndex !== index) {
							selectedImageIndex = index;
						}

						return (
							<SwiperSlide
								className={cx(ProfileImageStyle.base)}
								key={`profileImage${image}`}
								data-testid={`profileImage${image}`}
								role="slide"
							>
								<button
									type="button"
									onClick={() => onClickImage(image)}
									title={isCurrentImage ? 'Ausgewähltes Profilbild' : 'Wählbares Profilbild'}
								>
									<Image
										src={image}
										ratio="1x1"
										alt={isCurrentImage ? 'Ausgewähltes Profilbild' : 'Wählbares Profilbild'}
									/>
								</button>
								{isCurrentImage && (
									<div className={cx(Checkmark.base)}>
										<Icon name="checkmark" type="solid" />
									</div>
								)}
							</SwiperSlide>
						);
					})}

				<SwiperButton direction="left" />
				<SwiperButton direction="right" />
			</Swiper>
		</section>
	);
}

export default ProfileImageSwiperWidget;
