import cookie from 'cookie';
import type { User as FirebaseUser } from 'firebase/auth';
import {
	EmailAuthProvider,
	getAdditionalUserInfo,
	OAuthProvider,
	onIdTokenChanged,
	reauthenticateWithCredential,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
	updateEmail,
	updatePassword,
	updateProfile,
} from 'firebase/auth';
import { ErrorMessages, StorageKeys } from '../../configs/constants';
import defaultState from '../../configs/defaultState';
import { IDB_STORE_TYPES, INFO_TYPES, LOGIN_TYPES } from '../../configs/types';
import { auth } from '../../firebase';
import type { AnyObject, User } from '../../types';
import {
	fetchCustomTokenByCookies,
	fetchCustomTokenAndEmailByIdToken,
	fetchDeleteUsers,
	fetchSetUser,
	fetchUsers,
} from '../fetch';
import { KeyValueStorageService } from '../storage/StorageService';
import { sortUsers } from './misc';
import env from '../../configs/env';
import { buildInfoLink } from '../../services/link';
import { buildProfileImageUrl } from '../url';

class UserService extends KeyValueStorageService<string | null> {
	#hasLoggedInCookie = cookie.parse(document.cookie)?.amsLoggedIn === 'true';
	#idToken: string | null = null;
	#claims: AnyObject | null = null;
	#loginType: LOGIN_TYPES | null = null;
	#users: Map<string, User> = new Map();
	#listeners: Set<() => void> = new Set();

	#subscribeIdTokenChanged(): Promise<void> {
		return new Promise((resolve) => {
			onIdTokenChanged(auth, async (user: FirebaseUser | null) => {
				if (user) {
					this.#idToken = await user.getIdToken();
					this.#claims = (await user.getIdTokenResult()).claims;
					if (!this.#claims?.firebase?.identities?.[LOGIN_TYPES.OIDC_ZDF] && !this.isSubUser) {
						this.email = user.email;
					}
				} else {
					this.#idToken = null;
					this.#claims = null;
					this.#loginType = null;
				}

				resolve();
			});
		});
	}

	async init() {
		await Promise.all([super.init(), auth.authStateReady()]);
		await this.#subscribeIdTokenChanged();

		try {
			await this.#handleLogin();

			if (this.id) {
				await this.#loadUsers();
			}
		} catch (e) {
			await auth.signOut();
			if ((e as Error).message !== ErrorMessages.LOGOUT) {
				console.error(e);
			}
		}
	}

	async #handleLogin() {
		let customToken;

		if (window.location.hash.includes('customToken=')) {
			const searchParamsWithoutLeadingHash = window.location.hash.substring(1);
			this.#removeLoginData();
			const urlSearchParams = new URLSearchParams(searchParamsWithoutLeadingHash);
			customToken = urlSearchParams.get('customToken');
			this.#loginType = LOGIN_TYPES.PASSWORD;
		} else if (defaultState.navigation.idToken) {
			const data = await fetchCustomTokenAndEmailByIdToken(defaultState.navigation.idToken);
			customToken = data?.customToken;
			if (data?.email) {
				this.email = data?.email;
			}

			this.#loginType = LOGIN_TYPES.ID_TOKEN;
		} else if (this.#hasLoggedInCookie) {
			if (!auth.currentUser) {
				customToken = await fetchCustomTokenByCookies();
				this.#loginType = LOGIN_TYPES.SSO;
			} else {
				this.#loginType = LOGIN_TYPES.AUTO;
				return;
			}
		}

		await this.#loginWithCustomToken(customToken);
	}

	#removeLoginData() {
		const urlWithoutHash = window.location.pathname + window.location.search;
		window.history.replaceState('', '', urlWithoutHash);
	}

	async #loginWithCustomToken(customToken?: string | null) {
		if (customToken) {
			const { user } = await signInWithCustomToken(auth, customToken);
			this.#claims = (await user.getIdTokenResult()).claims;

			if (!user.emailVerified && !this.isSubUser && this.loginType !== LOGIN_TYPES.OIDC_ZDF) {
				throw Error(ErrorMessages.LOGOUT, { cause: 'Email not verified.' });
			}
		} else {
			throw Error(ErrorMessages.LOGOUT, { cause: 'No CustomToken found.' });
		}
	}

	async loginWithPassword(email: string, password: string) {
		const { user } = await signInWithEmailAndPassword(auth, email, password);
		return user;
	}

	async loginWithOAuthProvider(providerType: LOGIN_TYPES) {
		const provider = new OAuthProvider(providerType);
		provider.setCustomParameters({
			prompt: 'login',
		});
		const loginData = await signInWithPopup(auth, provider);
		const additionalUserInfo = getAdditionalUserInfo(loginData);
		const providerId = additionalUserInfo?.providerId;

		for (const key in LOGIN_TYPES) {
			if (LOGIN_TYPES[key as keyof typeof LOGIN_TYPES] === providerId) {
				this.#loginType = providerId;
			}
		}

		if (!loginData.user.email) {
			const additionalUserInfo = getAdditionalUserInfo(loginData);
			const providerName = providerId?.replace('oidc.', '');
			await updateEmail(loginData.user, `${providerName}.nutzer@${additionalUserInfo?.profile?.sub}.ext`);
		}

		if (!loginData.user.photoURL) {
			await updateProfile(loginData.user, {
				photoURL: defaultState.account.nextUser.image,
			});
		}
	}

	async validatePassword(password: string) {
		if (this.email) {
			if (this.isSubUser) {
				return await signInWithEmailAndPassword(auth, this.email, password);
			} else if (auth.currentUser) {
				return await reauthenticateWithCredential(
					auth.currentUser,
					EmailAuthProvider.credential(this.email, password),
				);
			}
		}
	}

	async logout() {
		await auth.signOut();
	}

	async changePassword(oldPassword: string, newPassword: string) {
		const loginData = await this.validatePassword(oldPassword);

		if (loginData) {
			await updatePassword(loginData.user, newPassword);
		}
	}

	#storeUsers(users: Array<User>) {
		users.forEach((user) => {
			this.#users.set(user.id, user);
		});
	}

	#setUserAndSort(user: User) {
		if (user.id) {
			this.#users.set(user.id, user);
			const sortedUsers = sortUsers(Array.from(this.#users.values()), this.id);
			this.#users.clear();
			this.#storeUsers(sortedUsers as Array<User>);
		}
	}

	async editUser(id: string, image?: string, name?: string, ageRating?: number, hasChildPin?: boolean) {
		const updatedUser = await fetchSetUser({
			image,
			name,
			ageRating,
			pin: hasChildPin ? this.childPin : undefined,
			id,
		});
		if (updatedUser) {
			this.#setUserAndSort(updatedUser);
		}
	}

	async deleteMainUser() {
		await fetchDeleteUsers();
		await this.clearItems(true);

		const url = new URL(`${env.HOST}/sso/logout`);
		url.searchParams.set(
			'redirect_uri',
			`${env.HOST + buildInfoLink(INFO_TYPES.ACCOUNT_DELETED, defaultState.navigation.redirectUrl)}`,
		);

		window.location.href = url.href;
	}

	async addSubUser(image?: string, name?: string, ageRating?: number, hasChildPin?: boolean) {
		const addedSubUser = await fetchSetUser({
			image,
			name,
			ageRating,
			pin: hasChildPin ? this.childPin : undefined,
			isSubUser: true,
		});
		if (addedSubUser) {
			this.#setUserAndSort(addedSubUser);
		}
	}

	async deleteSubUser(subUserId: string) {
		await fetchDeleteUsers(subUserId);
		this.#users.delete(subUserId);
	}

	isUserNameUnique(newUserName: string, newUserId?: string) {
		let isUnique = true;
		this.#users.forEach((user) => {
			if (user.name.trim().toLowerCase() === newUserName.trim().toLowerCase() && user.id !== newUserId) {
				isUnique = false;
			}
		});
		return isUnique;
	}

	async clearItems(removeEmail?: boolean) {
		if (removeEmail) {
			await super.clearItems();
		} else {
			await super.clearItemsWithExcludes([StorageKeys.EMAIL]);
		}
	}

	async refreshIdToken() {
		const idToken = (await auth.currentUser?.getIdToken(true)) ?? null;
		await auth.currentUser?.reload();
		await this.#loadUsers();
		this.#triggerUserDataChange();
		return idToken;
	}

	async #loadUsers() {
		const users = await fetchUsers();
		users && this.#storeUsers(users);
	}

	async #getCustomToken() {
		return (this.idToken && (await fetchCustomTokenAndEmailByIdToken(this.idToken))?.customToken) ?? null;
	}

	get refreshToken() {
		return auth.currentUser?.refreshToken;
	}

	get users(): Map<User['id'], User> {
		return this.#users;
	}

	get email() {
		return this.getItem(StorageKeys.EMAIL)?.replace(' ', '+') ?? null;
	}

	set email(email: string | null) {
		this.addItem(StorageKeys.EMAIL, email);
	}

	get customToken() {
		return this.#getCustomToken();
	}

	get idToken() {
		return this.#idToken;
	}

	/** Returns the id of current user. Can be the main user id or a sub user id. */
	get id() {
		return auth.currentUser?.uid;
	}

	/** Returns the main user id. When current user is main user, then userId & accountId are equal. */
	get mainUserId(): string | undefined {
		return this.#claims?.mainUserId ?? this.id;
	}

	get image() {
		return buildProfileImageUrl(auth.currentUser?.photoURL);
	}

	get loginType() {
		if (this.#claims?.firebase?.identities?.[LOGIN_TYPES.OIDC_ZDF]) {
			return LOGIN_TYPES.OIDC_ZDF;
		} else if (this.id) {
			return this.#loginType;
		}
	}

	get ageRating(): number | undefined {
		return this.#claims?.age_rating;
	}

	get pin(): string | undefined {
		return this.#claims?.pin;
	}

	get childPin(): string {
		if (this.isSubUser) {
			return this.#claims?.pin;
		}
		return this.#claims?.child_pin;
	}

	get isAgeVerified() {
		return this.ageRating !== undefined && this.ageRating >= 16 && !!this.pin;
	}

	get isSubUser() {
		return !!this.#claims?.mainUserId;
	}

	get isChild() {
		return this.ageRating !== undefined && this.ageRating < 13;
	}

	getUserById(id: string) {
		return this.#users.get(id);
	}

	// register subscriber for useSyncExternalStore
	subscribe = (listener: () => void) => {
		this.#listeners.add(listener);
		return () => {
			this.#listeners.delete(listener);
		};
	};

	// triggers subscribers of useSyncExternalStore
	#triggerUserDataChange = () => {
		for (const listener of this.#listeners) {
			listener();
		}
	};
}

const userService = new UserService(IDB_STORE_TYPES.USER);
export default userService;
