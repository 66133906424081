import state from '../../configs/defaultState';
import { PAGE_TYPES } from '../../configs/types';

export function shouldRedirectToRefererApp(navigation: typeof state.navigation) {
	return (
		navigation.redirectUrl &&
		navigation.entryPageSlug !== PAGE_TYPES.ACCOUNT &&
		navigation.entryPageSlug !== PAGE_TYPES.DEEPLINK_ACCOUNT
	);
}
