import { Box, Logo } from '../../app/styles';
import { Typo, ChannelLogo } from '@ard-online/component-styleguide';
import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';

function NotFoundPage() {
	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<header className={cx(Logo.base)}>
				<ChannelLogo channel="ard" />
			</header>
			<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.notFoundPage.headline}</h1>
			<h2 className={cx(Typo.base, Typo.heading04)}>{STRINGS.notFoundPage.kicker}</h2>
		</main>
	);
}

export default NotFoundPage;
