export enum DEVICE_TYPES {
	PHONE = 'phone',
	TABLET = 'tablet',
	RESPONSIVE = 'responsive',
}

export enum MODE_TYPES {
	IN_APP = 'inApp',
	OIDC = 'oidc',
	STANDALONE = 'standalone',
}

export enum LOGIN_TYPES {
	PASSWORD = 'password',
	ID_TOKEN = 'idToken',
	SSO = 'sso',
	AUTO = 'auto',
	OIDC_ZDF = 'oidc.zdf',
}

export enum PAGE_TYPES {
	START = '',
	// Deeplinks for use in Clients
	DEEPLINK_ACCOUNT = 'konto-verwalten',
	DEEPLINK_PROFILE_ADD = 'profil-erstellen',
	DEEPLINK_PROFILE_EDIT = 'profil-bearbeiten',
	DEEPLINK_PROFILE_MANAGE = 'profil-verwalten',
	DEEPLINK_AGE_VERIFICATION = 'altersfreigabe',
	DEEPLINK_AGE_VERIFICATION_PIN_CHANGE = 'pin-aendern',
	DEEPLINK_CHILD_PIN = 'kinderpin',

	// Common
	PASSWORD = 'passwort',
	DELETE = 'loeschen',
	EDIT = 'bearbeiten',
	CHILD_PIN = 'kinder-pin',
	// Registration
	REGISTRATION = 'registrieren',
	REGISTRATION_EMAIL_SENT = 'email-gesendet',
	// Profile
	PROFILE = 'profil',
	PROFILE_ADD = 'erstellen',
	PROFILE_CHILD = 'kind',
	PROFILE_IMAGE = 'profilbild',
	// Account
	ACCOUNT = 'konto',
	ACCOUNT_EDIT_DEPRECATED = 'profil-verwalten', // TODO: remove as soon as all clients switched to new route
	// Age verification
	AGE_VERIFICATION = 'altersfreigabe',
	AGE_VERIFICATION_ID_CARD = 'verifizierung',
	AGE_VERIFICATION_PIN = 'pin',
	// Login
	LOGIN_CONFIRM = 'login-confirm',
	LOGIN_USER_CODE = 'id',
	FORGOT_PASSWORD = 'passwort-vergessen',
	// Misc
	INFO = 'info',
	IMPRINT = 'impressum',
	PRIVACY = 'datenschutz',
	TERMS_OF_USE = 'nutzungsbedingungen',
	SETTINGS = 'einstellungen',
	NOT_FOUND = 'seite-nicht-gefunden',
	EMAIL_ACTIONS = 'email-actions',
}

/*
	Message types used by the native mobile clients to trigger actions on these types.
	These types are mainly used in the redirectToRefererApp function as redirect parameter.
	New parameters should only be added or removed if required by the mobile clients.
 */
export enum MESSAGE_TYPES {
	LOGGED_IN = 'loggedIn',
	LOGGED_OUT = 'loggedOut',
	AGE_VERIFICATION_CONFIRMED = 'ageVerificationConfirmed',
	AGE_VERIFICATION_PIN_CHANGED = 'ageVerificationPinChanged',
	TOKENS_CHANGED = 'tokensChanged',
	ACCOUNT_DELETED = 'accountDeleted',
	BACK_TO_REFERER_APP = 'backToApp',
}

export enum INFO_TYPES {
	AGE_VERIFICATION_COMPLETED = 'altersfreigabe-abgeschlossen',
	AGE_VERIFICATION_PIN_CHANGED = 'jugendschutz-code-geaendert',
	ACCOUNT_DELETED = 'konto-geloescht',
	RESET_PASSWORD_MAIL_SENT = 'passwort-zuruecksetzen-email-gesendet',
	PASSWORD_CHANGED = 'passwort-geaendert',
	DEVICE_AUTHORIZATION_COMPLETED = 'geraet-eingeloggt',
	ERROR_LINK_INVALID = 'link-nicht-valide',
	ERROR_LOGOUT_FAILED = 'logout-fehlgeschlagen',
	ERROR_DEVICE_AUTHORIZATION = 'login-fehlgeschlagen',
	PROFILE_ADDED = 'profil-erstellt',
	PROFILE_CHANGED = 'profil-geaendert',
	PROFILE_DELETED = 'profil-geloescht',
	ZDF_USER = 'zdf-nutzer',
}

export enum IDB_STORE_TYPES {
	USER = 'user',
	SETTINGS = 'settings',
}

export enum STORAGE_KEYS {
	TRACKING_ENABLED = 'trackingEnabled',
	CAMPAIGN = 'campaign',
}

export enum CLIENT_TYPES {
	MEDIATHEK = 'mt',
	AUDIOTHEK = 'at',
}

export enum THEME_TYPES {
	DEFAULT = 'default',
	LIGHT = 'light',
	DARK = 'dark',
}

// Tracking
export enum TRACKING_TYPES {
	PAGE = 'p',
}

export enum TRACKING_PARAM_TYPES {
	// Basic
	TRACKING_TYPE = 't',
	EVENTS = 'e',
	REFERER = 'r',
	VERSION = 'v',

	// User
	USER_ID = 'uId',
	USER_AGE = 'uAge',
	SIGN_IN_PROVIDER = 'signIn',
	PROFILE_ID = 'prId',

	// Page
	EXTERNAL_REFERER = 'extR',

	// Device
	DEVICE_TIMESTAMP_UTC = 'utc',
	VIEWPORT_HEIGHT = 'vh',
	VIEWPORT_WIDTH = 'vw',

	// Campain
	MEDIUM = 'med',
	CAMPAIGN = 'cam',
	TARGET = 'tar',
	ADG = 'adg',
	AD = 'ad',
	TERM = 'term',
}

export enum CHILD_PROFILE_WIDGET_TYPE {
	CREATE,
	EDIT,
}
