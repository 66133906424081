import { FormEvent } from 'react';
import { PAGE_TYPES } from '../../../../configs/types';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { Link } from 'react-router-dom';
import { buildMainLink } from '../../../../services/link';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../../firebase';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { SentMailAgainButtonWrapper } from '../styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import getContinueUrl from '../../../../services/url/getContinueUrl';
import { useHashParams } from '../../../../services/misc';

function ResetPasswordMailSent() {
	const { appState } = useContextActions();
	const hashParams = useHashParams();
	const email = hashParams?.get('email') ?? '';

	async function onClick(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (email) {
			try {
				let actionCodeSettings;

				if (appState.navigation.redirectUrl) {
					actionCodeSettings = {
						url: getContinueUrl(appState),
					};
				}

				await sendPasswordResetEmail(auth, email, actionCodeSettings);
			} catch (error) {
				console.warn('Fehler', error);
			}
		}
	}

	/** JSX */
	return (
		<Row>
			<Column p={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Sie haben Post</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Eine E-Mail wurde an <strong>{email}</strong> verschickt. Bitte beachten Sie auch den Spam-Ordner.
				</p>
				<div className={cx(SentMailAgainButtonWrapper.base)}>
					<p className={cx(Typo.base, Typo.body02)}>Keine E-Mail erhalten?</p>
					<Button text="E-Mail erneut senden" onClick={onClick} type={BUTTON_TYPES.TEXT} />
				</div>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button
						text="Abschließen"
						href={buildMainLink(PAGE_TYPES.START)}
						type={BUTTON_TYPES.PRIMARY}
						as={Link}
					/>
				</div>
			</Column>
		</Row>
	);
}

export default ResetPasswordMailSent;
