import { AccountSection, AddProfileButton, Checkmark, ColumnLink, TextWithIcon } from './styles';
import { Button, Column, Typo, Row, Icon } from '@ard-online/component-styleguide';
import { Link } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildLogoutLink, buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Box } from '../../app/styles';
import { cx } from '@linaria/core';
import ProfileListWidget from '../../widgets/ProfileListWidget/ProfileListWidget';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { MAX_SUB_USERS } from '../../../configs/constants';

const HELP_LINK_URL = 'https://hilfe.ard.de/artikel/alterssperre-und-altersbestaetigung/';

function SettingsPanel({
	title,
	children,
}: {
	title: string;
	children: Array<[React.JSX.Element?, React.JSX.Element?]>;
}) {
	return (
		<>
			<Row justifyContent="space-between" p={16} margin={0}>
				<Column xs={12} s={3} p={0} pt={2}>
					<h6 className={cx(Typo.base, Typo.heading06)}>{title}</h6>
				</Column>
				<Column xs={12} s={9} p={0}>
					{children.map(([first, second], index) => (
						<Row justifyContent="space-between" p={0} pb={16} margin={0} key={index}>
							<Column className={cx(Typo.base, Typo.body03)} p={0} pt={2} xs={12} s={7}>
								{first}
							</Column>
							<Column className={cx(Typo.base, Typo.body03, ColumnLink.base)} p={0} pt={2} xs={12} s={5}>
								{second}
							</Column>
						</Row>
					))}
				</Column>
			</Row>
			<hr />
		</>
	);
}

function EditAccountPage() {
	/** Variables */

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();
	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			{userService.id && (
				<>
					<section className={cx(AccountSection.base)}>
						<header>
							<h2 className={cx(Typo.base, Typo.heading02)}>
								{STRINGS.editAccountPage.account.headline}
							</h2>
						</header>
						<SettingsPanel title={STRINGS.editAccountPage.myData.headline}>
							{[
								[<span>{userService.email}</span>],
								[
									<span>{STRINGS.editAccountPage.myData.pwd.text}: *********</span>,
									<Link
										to={buildMainLink(PAGE_TYPES.PASSWORD)}
										title={STRINGS.editAccountPage.myData.pwdEdit.button}
									>
										{STRINGS.editAccountPage.myData.pwdEdit.button}
									</Link>,
								],
							]}
						</SettingsPanel>
						<SettingsPanel title={STRINGS.editAccountPage.kidsPin.label}>
							{userService.childPin
								? [
										[
											<div className={cx(TextWithIcon.base)}>
												<div className={cx(Checkmark.base)}>
													<Icon name="check" />
												</div>
												<span>{STRINGS.editAccountPage.kidsPinSet.label}</span>
											</div>,
											<>
												<Column p={0}>
													<Link
														to={`${PAGE_TYPES.CHILD_PIN}/${PAGE_TYPES.DELETE}`}
														title={STRINGS.editAccountPage.deleteKidsPin.button}
													>
														{STRINGS.editAccountPage.deleteKidsPin.button}
													</Link>
												</Column>
												<Column p={0} pt={8}>
													<Link
														to={`${PAGE_TYPES.CHILD_PIN}`}
														title={STRINGS.editAccountPage.editKidsPin.button}
													>
														{STRINGS.editAccountPage.editKidsPin.button}
													</Link>
												</Column>
											</>,
										],
									]
								: [
										[
											<div className={cx(TextWithIcon.base)}>
												<div className={cx(Checkmark.base, 'red')}>
													<Icon name="close" />
												</div>
												<span>{STRINGS.editAccountPage.kidsPinNotSet.label}</span>
											</div>,
											<Link
												to={`${PAGE_TYPES.CHILD_PIN}`}
												title={STRINGS.editAccountPage.createKidsPin.button}
											>
												<span>{STRINGS.editAccountPage.createKidsPin.button}</span>
											</Link>,
										],
									]}
						</SettingsPanel>
						<SettingsPanel title={STRINGS.editAccountPage.ageVerification.headline}>
							{userService.isAgeVerified
								? [
										[
											<div className={cx(TextWithIcon.base)}>
												<div className={cx(Checkmark.base)}>
													<Icon name="check" />
												</div>
												<span>{STRINGS.editAccountPage.ageVerificationSuccessful.text}</span>
											</div>,
											<Link
												to={`${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.DELETE}`}
												title={STRINGS.editAccountPage.ageVerificationRevoke.button}
											>
												{STRINGS.editAccountPage.ageVerificationRevoke.button}
											</Link>,
										],
										[
											<div className={cx(TextWithIcon.base)}>
												<div className={cx(Checkmark.base)}>
													<Icon name="check" />
												</div>
												<span>{STRINGS.editAccountPage.ageVerificationActive.text}</span>
											</div>,
											<Link
												to={`${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.EDIT}`}
												title={STRINGS.editAccountPage.ageVerificationCodeEdit.button}
											>
												{STRINGS.editAccountPage.ageVerificationCodeEdit.button}
											</Link>,
										],
									]
								: [
										[
											<span>{STRINGS.editAccountPage.ageNotVerified.text}</span>,
											<Link to={`/${PAGE_TYPES.AGE_VERIFICATION}`} title="Alter bestätigen">
												{STRINGS.editAccountPage.ageNotVerified.confirmAge.text}
											</Link>,
										],
										[
											undefined,
											<a href={HELP_LINK_URL} target="_blank" rel="noreferrer">
												{STRINGS.editAccountPage.ageNotVerified.link.text}
											</a>,
										],
									]}
						</SettingsPanel>
						<SettingsPanel title="Konto">
							{[
								[
									<span>{STRINGS.editAccountPage.signOut.text}</span>,
									<a
										title={STRINGS.editAccountPage.signOut.button}
										href={buildLogoutLink()}
										id="logout"
									>
										{STRINGS.editAccountPage.signOut.button}
									</a>,
								],
								[
									<span>{STRINGS.editAccountPage.signOutAllDevices.text}</span>,
									<a
										title={STRINGS.editAccountPage.signOutAllDevices.button}
										href={buildLogoutLink(undefined, undefined, true)}
										id="logoutGlobal"
									>
										{STRINGS.editAccountPage.signOutAllDevices.button}
									</a>,
								],
								[
									<span>Lösche dein ARD-Konto</span>,
									<Link
										title={STRINGS.editAccountPage.deleteAccount.button}
										to={buildMainLink(`${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.DELETE}`)}
										id="deleteAccount"
									>
										{STRINGS.editAccountPage.deleteAccount.button}
									</Link>,
								],
							]}
						</SettingsPanel>
					</section>
					<section>
						<header>
							<h2 className={cx(Typo.base, Typo.heading02)}>{STRINGS.editAccountPage.headline}</h2>
						</header>
						<Row margin={0}>
							<Column p={0}>
								<ProfileListWidget />
							</Column>
							<Column all={12} p={0}>
								<div className={cx(AddProfileButton.base)}>
									<Button
										text="Profil hinzufügen"
										type={BUTTON_TYPES.SECONDARY}
										iconLeft={<Icon name="add" />}
										href={buildMainLink(`${PAGE_TYPES.PROFILE}/${PAGE_TYPES.PROFILE_ADD}`)}
										as={Link}
										isDisabled={userService.users.size > MAX_SUB_USERS}
									/>
								</div>
							</Column>
						</Row>
					</section>
				</>
			)}
		</main>
	);
}

export default EditAccountPage;
