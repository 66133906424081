import { css } from '@linaria/core';
import { Typo } from '@ard-online/component-styleguide';

export const Root = {
	base: css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 100vh;

		.${Typo.body02} {
			color: rgb(var(--text-color-body-01));
		}
	`,
};
