import userService from '../../../services/user/UserService';
import PinWidget from '../../widgets/pinWidget/PinWidget';
import { cx } from '@linaria/core';
import { Box } from '../../app/styles';
import { fetchSetOrDeleteChildPin } from '../../../services/fetch';
import type { AnyFunction } from '../../../types';
import { useState } from 'react';
import WordingMatrixStringWithLink from '../../widgets/wordingMatrixStringWithLink/WordingMatrixStringWithLink';
import { EXTERNAL_URLS } from '../../../configs/constants';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { Column, Row } from '@ard-online/component-styleguide';
import { useOnDeeplinkCancel } from '../../../services/misc/useOnDeeplinkCancel';

function ChildPinPage() {
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);
	const { setNextUser } = useContextActions();
	const onDeeplinkCancel = useOnDeeplinkCancel();

	async function onSubmit(pin: string) {
		setIsLoading(true);
		try {
			await fetchSetOrDeleteChildPin(pin);
			if (pin) {
				setNextUser({ hasChildPin: true });
			}
			await userService.refreshIdToken();
			onDeeplinkCancel();
		} catch (error) {
			setIsLoading(false);
		}
	}

	function onCancel() {
		onDeeplinkCancel();
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	return (
		<main className={cx(Box.base, Box.type.m)}>
			<Row>
				<Column>
					<PinWidget
						headline={STRINGS.childrenProfilePage.restrictionPIN.headline}
						text={
							<WordingMatrixStringWithLink
								text={STRINGS.childrenProfilePage.restrictionPIN.text}
								placeholder="{helpLink}"
								linkText="ARD Hilfe"
								linkUrl={EXTERNAL_URLS.HELP_CHILDPROFILE}
							/>
						}
						initialValue={userService.childPin}
						isLoading={isLoading}
						onSubmit={onSubmit}
						onCancel={onCancel}
					/>
				</Column>
			</Row>
		</main>
	);
}

export default ChildPinPage;
