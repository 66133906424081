import userService from '../user/UserService';

export default async function fetchSetOrDeleteChildPin(pin?: string) {
	try {
		const idToken = userService.idToken;
		const fetchUrl = '/users/childPin';
		let res: Response;

		if (pin) {
			// Upsert Pin
			res = await fetch(fetchUrl, {
				method: 'PATCH',
				headers: {
					'X-Authorization': `Bearer ${idToken}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					pin,
				}),
			});
		} else {
			// Delete Pin
			res = await fetch(fetchUrl, {
				method: 'DELETE',
				headers: {
					'X-Authorization': `Bearer ${idToken}`,
				},
			});
		}

		if (!res.ok) {
			const error = new Error(`Error setting childpin | Status ${res.status}: ${await res.text()}`);
			console.error(error);
			throw error;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}
