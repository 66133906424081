import { useContextActions } from '../../../services/actions/ActionsService';
import { isGoogleApiError } from '../../../services/misc';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import userService from '../../../services/user/UserService';
import { ReactNode, useRef } from 'react';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import env from '../../../configs/env';
import { PAGE_TYPES, MODE_TYPES } from '../../../configs/types';
import { buildMainLink } from '../../../services/link';

type SsoCallback = (redirectUrl?: string) => Promise<void>;

export function useSso(): { ssoForm: ReactNode; sso: SsoCallback } {
	const { appState } = useContextActions();
	const ssoFormRef = useRef<HTMLFormElement>(null);
	const idTokenInputRef = useRef<HTMLInputElement>(null);
	const refreshTokenInputRef = useRef<HTMLInputElement>(null);
	const redirectUrlInputRef = useRef<HTMLInputElement>(null);
	const { clientId, entryPageSlug, redirectUrl: redirectUrlFromAppState } = appState.navigation;
	const { modeType } = appState.config;

	const ssoForm = (
		<>
			<form action={`${env.HOST}/sso`} method="POST" ref={ssoFormRef}>
				<input type="hidden" name="idToken" ref={idTokenInputRef} />
				<input type="hidden" name="refreshToken" ref={refreshTokenInputRef} />
				<input type="hidden" name="redirectUrl" ref={redirectUrlInputRef} />
			</form>
		</>
	);

	const redirectUrl = new URL(env.HOST);

	if (modeType && modeType !== MODE_TYPES.STANDALONE) {
		redirectUrl.searchParams.set('modeType', modeType);
	}

	if (clientId) {
		redirectUrl.searchParams.set('clientId', clientId);
	}

	if (redirectUrlFromAppState) {
		redirectUrl.searchParams.set('redirect_uri', redirectUrlFromAppState);
	}

	async function sso(redirectPath?: string) {
		if (
			idTokenInputRef.current &&
			refreshTokenInputRef.current &&
			redirectUrlInputRef.current &&
			ssoFormRef.current
		) {
			idTokenInputRef.current.value = String(userService.idToken);
			refreshTokenInputRef.current.value = String(userService.refreshToken);

			if (
				redirectUrlFromAppState &&
				(entryPageSlug === PAGE_TYPES.START || // normal login
					entryPageSlug === PAGE_TYPES.REGISTRATION || // registration completed
					entryPageSlug === PAGE_TYPES.EMAIL_ACTIONS || // email action
					(entryPageSlug === PAGE_TYPES.AGE_VERIFICATION && userService.isAgeVerified)) // is age verified
			) {
				// external login
				redirectUrlInputRef.current.value = redirectUrlFromAppState;
			} else if (redirectPath) {
				// internal login with oidc
				redirectUrlInputRef.current.value = env.HOST + redirectPath;
			} else {
				// internal login & external login with confirm
				redirectUrl.pathname = buildMainLink(entryPageSlug);
				redirectUrlInputRef.current.value = redirectUrl.href;
			}

			ssoFormRef.current.submit();
		}
	}

	return { ssoForm, sso };
}

type LoginCallback = (email: string, password: string) => Promise<void>;

export function useLoginWithEmailPassword(): LoginCallback {
	const { appState } = useContextActions();

	return async (email: string, password: string) => {
		let user;

		try {
			user = await userService.loginWithPassword(email, password);
		} catch (error: unknown) {
			if (isGoogleApiError(error) && error.code === GoogleApiCodes.TOO_MANY_REQUESTS) {
				throw new Error(ErrorMessages.DEFAULT);
			} else {
				throw new Error(ErrorMessages.EMAIL_OR_PASSWORT);
			}
		}

		if (!user.emailVerified) {
			await sendEmailVerification(user, appState);
			await userService.logout();
			throw new Error(ErrorMessages.VERIFY_EMAIL);
		}
	};
}
