import userService from '../../../services/user/UserService';
import { Image, Typo, ChildLabels, Icon } from '@ard-online/component-styleguide';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Link } from 'react-router-dom';
import { cx } from '@linaria/core';
import { ProfilesWrapper } from './styles';

function ProfileListWidget() {
	/** Variables */
	const users = Array.from(userService.users.values()).map((user) => {
		return (
			<li key={user.id}>
				<Link
					title="Profil"
					to={
						user.isSubUser
							? `${buildMainLink(PAGE_TYPES.PROFILE)}/${user.id}`
							: buildMainLink(PAGE_TYPES.PROFILE)
					}
					className={cx(ProfilesWrapper.linkRow)}
				>
					<Image src={user?.image} alt={`Profilbild von ${user.name}`} isRound />

					<span
						className={cx(Typo.base, Typo.body02)}
					>{`${user.name}${user.isSubUser ? '' : ' • Hauptprofil'}`}</span>

					<ChildLabels
						childText={STRINGS.editAccountPage.defaultChildLabel.text}
						ageText={STRINGS.editAccountPage.ageChildLabel.text}
						ageRating={user.ageRating}
						isPinProtected={!!user.pin}
					/>
					<Icon name="chevronRight" />
				</Link>
				<hr></hr>
			</li>
		);
	});

	/** JSX */
	return users.length ? (
		<div className={cx(ProfilesWrapper.base)}>
			<ul>{users}</ul>
		</div>
	) : null;
}

export default ProfileListWidget;
