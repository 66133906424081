import { FormEvent, useState } from 'react';
import { ButtonWrapper } from './styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Box } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useContextActions } from '../../../services/actions/ActionsService';
import { CLIENT_TYPES, LOGIN_TYPES, MODE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import PromoBoardWidget from '../../widgets/promoBoard/PromoBoardWidget';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import CreateAccountButtonWidget from '../../widgets/createAccountButton/CreateAccountButtonWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useSearchParams } from 'react-router-dom';

function LoginPage() {
	/** Variables */
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const hasPromoBoard =
		!userService.email &&
		(appState.navigation.clientId === CLIENT_TYPES.MEDIATHEK ||
			appState.navigation.clientId === CLIENT_TYPES.AUDIOTHEK);

	const [searchParams] = useSearchParams();
	const isEmailConfirmed = searchParams.has('emailConfirmed');
	const submittable = email && password;

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (submittable && !loading) {
			setLoading(true);
			setMessage(null);
			try {
				await loginWithEmailPassword(email, password);
				await sso();
			} catch (error) {
				error instanceof Error && setMessage(error.message);
				setLoading(false);
			}
		}
	}

	async function onClickZdf() {
		try {
			await userService.loginWithOAuthProvider(LOGIN_TYPES.OIDC_ZDF);
			await sso();
		} catch (error) {
			console.error(error);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	let headline = 'Mit dem ARD-Konto die Angebote der ARD noch bequemer nutzen';
	if (isEmailConfirmed) {
		headline = 'E-Mail-Bestätigung erfolgreich!';
	} else if (userService.email) {
		headline = 'Willkommen zurück';
	}

	return (
		<main className={cx(Box.base, hasPromoBoard ? Box.type.l : Box.type.xs)}>
			<Row>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading02)}>{headline}</h1>
				</Column>
				{isEmailConfirmed && (
					<Column>
						<p className={cx(Typo.base, Typo.body02)}>{STRINGS.loginPage.isEmailConfirmed.text}</p>
					</Column>
				)}
				{hasPromoBoard && (
					<Column xs={12} m={6}>
						<PromoBoardWidget />
					</Column>
				)}
				<Column xs={12} m={hasPromoBoard ? 6 : 12}>
					<form onSubmit={onSubmit}>
						<Column p={0}>
							<CredentialsWidget email={email} setEmail={setEmail} setPassword={setPassword} />
						</Column>
						{message && (
							<Column>
								<MessageWidget>{message}</MessageWidget>
							</Column>
						)}
						<hr />
						<Column p={0}>
							<div className={cx(ButtonWrapper.base)}>
								<Button
									text={STRINGS.general.signIn.button}
									type={BUTTON_TYPES.PRIMARY}
									isDisabled={!submittable}
									isLoading={loading}
									htmlAttributes={{
										type: 'submit',
									}}
								/>
							</div>
						</Column>
						{!userService.email && (
							<Column p={0}>
								<div className={cx(ButtonWrapper.base)}>
									<CreateAccountButtonWidget />
								</div>
							</Column>
						)}
						{appState.config.modeType !== MODE_TYPES.IN_APP && !isEmailConfirmed && !userService.email && (
							<Column p={0}>
								<div className={cx(ButtonWrapper.base, ButtonWrapper.zdf)}>
									<Button
										text="Mit ZDF-Konto anmelden"
										type={BUTTON_TYPES.PRIMARY}
										onClick={onClickZdf}
									/>
								</div>{' '}
							</Column>
						)}
					</form>
				</Column>
				{ssoForm}
			</Row>
		</main>
	);
}

export default LoginPage;
