import { useState } from 'react';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import { useNavigate } from 'react-router-dom';
import { redirectToRefererApp } from '../../../services/misc';
import userService from '../../../services/user/UserService';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import type { AnyFunction } from '../../../types';
import MessageWidget from '../../widgets/message/MessageWidget';
import { ErrorMessages } from '../../../configs/constants';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useTrackPageImpression } from '../../../services/tracking';
import { fetchDeleteAgeVerification } from '../../../services/fetch';
import { shouldRedirectToRefererApp } from '../../../services/misc/shouldRedirectToRefererApp';
import { useOnDeeplinkCancel } from '../../../services/misc/useOnDeeplinkCancel';

function AgeVerificationDeletePage() {
	/** Variables */
	const { appState } = useContextActions();
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const navigate = useNavigate();
	const onDeeplinkCancel = useOnDeeplinkCancel();

	async function onClickDelete() {
		setIsLoading(true);

		if (!isLoading) {
			try {
				const res = await fetchDeleteAgeVerification();

				if (res.ok) {
					const idToken = await userService.refreshIdToken();

					if (shouldRedirectToRefererApp(appState.navigation)) {
						await redirectToRefererApp(
							appState.navigation.redirectUrl,
							MESSAGE_TYPES.TOKENS_CHANGED,
							idToken,
						);
					} else {
						navigate(buildMainLink(PAGE_TYPES.ACCOUNT));
					}
				} else {
					setIsLoading(false);
					setMessage(ErrorMessages.DEFAULT);
				}
			} catch (error) {
				setIsLoading(false);
				setMessage(ErrorMessages.DEFAULT);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<Row>
				<Column>
					<p className={cx(Typo.base, Typo.body02)}>Schritt 2 von 2</p>
				</Column>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading01)}>
						{STRINGS.ageVerificationDeletePage.revokeAge.headline}
					</h1>
				</Column>
				<Column>
					<p className={cx(Typo.base, Typo.body02)}>{STRINGS.ageVerificationDeletePage.revokeAge.text}</p>
				</Column>
				{message && (
					<Column>
						<MessageWidget>{message}</MessageWidget>
					</Column>
				)}
				<Column>
					<div className={cx(ButtonsWrapper.base)}>
						<Button
							text={STRINGS.general.cancel.button}
							type={BUTTON_TYPES.SECONDARY}
							onClick={onDeeplinkCancel}
							htmlAttributes={{
								type: 'button',
							}}
						/>
						<Button
							text="Altersfreigabe löschen"
							onClick={onClickDelete}
							isLoading={isLoading}
							type={BUTTON_TYPES.PRIMARY}
						/>
					</div>
				</Column>
			</Row>
		</main>
	);
}

export default AgeVerificationDeletePage;
