import { FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Typo, Input, Row, Column } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { buildInfoLink } from '../../../services/link';
import { isGoogleApiError, isTouchDevice } from '../../../services/misc';
import { INFO_TYPES } from '../../../configs/types';
import { AnyObject } from '../../../types';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { cx } from '@linaria/core';
import { useTrackPageImpression } from '../../../services/tracking';

function DeleteProfilePage() {
	/** Variables */
	const email = userService.email;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState(null);
	const { userId } = useParams() as { userId: string };

	async function onDeleteSubUser(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (email && password && userId && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				await userService.validatePassword(password);
				try {
					await userService.deleteSubUser(userId);
					navigate(buildInfoLink(INFO_TYPES.PROFILE_DELETED));
				} catch (error) {
					console.error(error);
					setLoading(false);
					setMessage(ErrorMessages.DELETE_PROFILE);
				}
			} catch (error) {
				if (isGoogleApiError(error)) {
					switch (error.code) {
						case GoogleApiCodes.WRONG_PASSWORD:
							setMessage(STRINGS.deleteAccountPage.submit.wrongPassword.text);
							break;

						case GoogleApiCodes.TOO_MANY_REQUESTS:
							setMessage(STRINGS.deleteAccountPage.submit.tooManyRequests.text);
							break;

						default:
							setMessage(ErrorMessages.DEFAULT);
					}
				} else {
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onGoBack() {
		navigate('..');
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setPassword(e.value);
		} else {
			setPassword(null);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.s)}>
			<Row>
				<form onSubmit={onDeleteSubUser}>
					<Column p={0}>
						<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.deleteProfilePage.headline}</h1>
					</Column>
					<Column px={0} pb={0}>
						<p className={cx(Typo.base, Typo.body03)}>{STRINGS.deleteProfilePage.text}</p>
					</Column>
					<Column p={0}>
						<p>{email}</p>
					</Column>
					<Column p={0}>
						<Input
							type="password"
							label="Passwort"
							isRequired
							errorMessage="Kein valides Passwort"
							onChange={onInputChange}
							htmlAttributes={{
								autoComplete: 'current-password',
								title: 'Passwort eingeben',
							}}
							hasFocus={!isTouchDevice()}
						/>
					</Column>
					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.general.cancel.button}
								type={BUTTON_TYPES.SECONDARY}
								isDisabled={loading}
								onClick={onGoBack}
								htmlAttributes={{
									type: 'button',
								}}
							/>
							<Button
								text={STRINGS.deleteProfilePage.confirm.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!(email && password && userId)}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default DeleteProfilePage;
