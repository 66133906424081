import { Outlet, useNavigate } from 'react-router-dom';
import { FormEvent, useState } from 'react';
import { ErrorMessages } from '../../../configs/constants';
import { Box, ButtonsWrapper } from '../../app/styles';
import { buildInfoLink } from '../../../services/link';
import { INFO_TYPES } from '../../../configs/types';
import { Button, Column, Row } from '@ard-online/component-styleguide';
import MessageWidget from '../../widgets/message/MessageWidget';
import userService from '../../../services/user/UserService';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';
import { useOnDeeplinkCancel } from '../../../services/misc/useOnDeeplinkCancel';

type Props = {
	userId?: string;
	isEdit?: boolean;
};

function BaseProfilePage({ userId, isEdit }: Props) {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const isSubmitAllowed = appState.account.nextUser.name?.trim() !== '';
	const onDeeplinkCancel = useOnDeeplinkCancel();

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isSubmitAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				const isNameUnique = userService.isUserNameUnique(
					appState.account.nextUser.name,
					isEdit ? userId : undefined,
				);
				if (!isNameUnique) {
					setMessage(ErrorMessages.UNIQUE_PROFILE_NAME);
					return;
				}

				const { image, name, ageRating, hasChildPin } = appState.account.nextUser;
				if (userId && isEdit) {
					await userService.editUser(userId, image, name, ageRating, hasChildPin);
					await userService.refreshIdToken();
					navigate(buildInfoLink(INFO_TYPES.PROFILE_CHANGED));
				} else {
					await userService.addSubUser(image, name, ageRating, hasChildPin);
					await userService.refreshIdToken();
					navigate(buildInfoLink(INFO_TYPES.PROFILE_ADDED));
				}
			} catch (error) {
				console.error(error);
				setMessage(ErrorMessages.DEFAULT);
			} finally {
				setLoading(false);
			}
		}
	}

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<Row>
				<form onSubmit={onSubmit}>
					<Column p={0}>
						<Outlet />
					</Column>
					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.general.cancel.button}
								type={BUTTON_TYPES.SECONDARY}
								onClick={onDeeplinkCancel}
								htmlAttributes={{ type: 'button' }}
							/>
							<Button
								text={STRINGS.general.done.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!isSubmitAllowed}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default BaseProfilePage;
