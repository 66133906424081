import { css } from '@linaria/core';

export const Root = {
	base: css`
		@media (max-width: 720px) {
			order: 2;
		}
		li {
			display: flex;
			align-items: center;
			gap: var(--table-spacing-gap-td);
			margin-bottom: var(--table-spacing-gap-tr);
			svg {
				fill: rgb(var(--input-color-success));
				background: radial-gradient(circle, white 50%, rgba(0, 0, 0, 0) 51%);
				border-radius: 50%;
				width: var(--icon-sizing-icon-s);
				height: var(--icon-sizing-icon-s);
				flex-shrink: 0;
			}
		}
		p {
			display: flex;
			align-items: center;
		}
	`,
};
