import { FormEvent, useRef, useState } from 'react';
import { ZdfTermsOfUse } from './styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Box, ButtonsWrapper } from '../../app/styles';
import MessageWidget from '../../widgets/message/MessageWidget';
import { isZdfOidcClient } from '../../../services/validation';
import { useContextActions } from '../../../services/actions/ActionsService';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { useLoginWithEmailPassword, useSso } from './misc';
import CredentialsWidget from '../../widgets/credentials/CredentialsWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function OidcLoginPage() {
	/** Variables */
	const { appState } = useContextActions();
	const isZdfOidcLogin = isZdfOidcClient(appState.navigation.clientId);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [email, setEmail] = useState(userService.email);
	const [password, setPassword] = useState('');
	const { sso, ssoForm } = useSso();
	const loginWithEmailPassword = useLoginWithEmailPassword();
	const [zdfTermsOfUseCheckbox, setZdfTermsOfUseCheckbox] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const idTokenInputRef = useRef<HTMLInputElement>(null);
	const oidcAuthUrl = `/oidc/authorize?sessionId=${appState.navigation.oidcState}`;
	const isUsingCurrentUser = userService.id && email === userService.email;
	const submittable =
		((email && password) || isUsingCurrentUser) && (!isZdfOidcLogin || (isZdfOidcLogin && zdfTermsOfUseCheckbox));

	async function onSubmit(event: FormEvent) {
		if (event.nativeEvent) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			return;
		}

		if (submittable && !loading) {
			setLoading(true);
			setMessage(null);
			try {
				if (isUsingCurrentUser && idTokenInputRef.current) {
					idTokenInputRef.current.value = String(userService.idToken);
					formRef.current?.submit();
				} else {
					await loginWithEmailPassword(email as string, password);
					await sso(oidcAuthUrl);
				}
			} catch (error) {
				error instanceof Error && setMessage(error.message);
				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	// TODO 9/11/2023 esen: email anzeigen und möglichkeit account zu ändern
	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<Column px={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>
						{userService.email
							? STRINGS.oidcLoginPage.userEmailExists.headline
							: STRINGS.oidcLoginPage.userEmailMissing.headline}
					</h1>
				</Column>
				<Column px={0}>
					<form
						onSubmit={onSubmit}
						action={oidcAuthUrl}
						method="post"
						encType="application/x-www-form-urlencoded"
						ref={formRef}
					>
						<input type="hidden" name="idToken" ref={idTokenInputRef} />
						<Column p={0}>
							<CredentialsWidget email={email} setEmail={setEmail} setPassword={setPassword} />
						</Column>
						{isZdfOidcLogin && (
							<label className={cx(ZdfTermsOfUse.base)}>
								<input
									name="zdfTermsOfUse"
									type="checkbox"
									checked={zdfTermsOfUseCheckbox}
									onChange={(e) => setZdfTermsOfUseCheckbox(e.target.checked)}
									title={STRINGS.oidcLoginPage.input.text}
								/>
								<span>
									Ich akzeptiere die{' '}
									<a
										href="https://www.zdf.de/regeln-und-nutzungsbedingungen-100.html"
										target="_blank"
										rel="noopener noreferrer"
									>
										Nutzungsbedingungen
									</a>{' '}
									des ZDF und habe die{' '}
									<a href="https://www.zdf.de/dsgvo/" target="_blank" rel="noopener noreferrer">
										Datenschutzhinweise
									</a>{' '}
									zur Kenntnis genommen.
								</span>
							</label>
						)}
						{message && (
							<Column p={0}>
								<MessageWidget>{message}</MessageWidget>
							</Column>
						)}
						<Column p={0}>
							<div className={cx(ButtonsWrapper.base)}>
								<Button
									text={STRINGS.general.signIn.button}
									type={BUTTON_TYPES.PRIMARY}
									isDisabled={!submittable}
									isLoading={loading}
									htmlAttributes={{
										type: 'submit',
									}}
								/>
							</div>
						</Column>
					</form>
				</Column>
			</Row>
			{ssoForm}
		</main>
	);
}

export default OidcLoginPage;
