import { FormEvent, useState } from 'react';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Dropdown, Typo, Icon, Input, Row, Column } from '@ard-online/component-styleguide';
import { CardInfo, ImageWrapper, InputWrapper } from './styles';
import { PAGE_TYPES } from '../../../configs/types';
import { useNavigate } from 'react-router-dom';
import { useContextActions } from '../../../services/actions/ActionsService';
import type { AnyFunction, AnyObject } from '../../../types';
import type { IdentityCardData } from '../../../server/app/ageVerification/verifyIdentityCardData';
import { checkIdCardData, convertSingleLine } from '../../../server/app/ageVerification/verifyIdentityCardData';
import idCardImage from './images/idCards/idCard.png'; /* pngs instead of svgs because of dark mode bug on samsung android browsers https://jira.ard.de/browse/ARDONI7-44503 */
import passportGermanyImage from './images/idCards/passportGermany.png';
import residenceTitleVariant1Image from './images/idCards/residenceTitleVariant1.png';
import residenceTitleVariant2Image from './images/idCards/residenceTitleVariant2.png';
import parseICAO9303Passport from '../../../server/app/ageVerification/parseICAO9303Passport';
import MessageWidget from '../../widgets/message/MessageWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useTrackPageImpression } from '../../../services/tracking';
import { useOnDeeplinkCancel } from '../../../services/misc/useOnDeeplinkCancel';
import InputBadgeWidget, { InputBadgeAppearance } from '../../widgets/inputBadge/InputBadgeWidget';

const ID_CARD_TITLE = 'Personalausweis';
const PASSPORT_TITLE = 'Reisepass';
const RESIDENCE_TITLE_1 = 'Aufenthaltstitel (Variante 1)';
const RESIDENCE_TITLE_2 = 'Aufenthaltstitel (Variante 2)';

const PASSPORT_FIELD_LENGTH = 44;
const RESIDENCE_V2_FIELD_LENGTH = 36;

const TWO_FIELD_LENGTHS = Object.freeze({
	FIRST: 10,
	SECOND: 30,
});

const IDENT_NUMBER = Object.freeze({
	[ID_CARD_TITLE]: '1234567891',
	[RESIDENCE_TITLE_1]: '1234567891',
});

const LOWER_MACHINE_READABLE_ROW = Object.freeze({
	[ID_CARD_TITLE]: '123456<20103157D<<<<<<<<<<<<<2',
	[PASSPORT_TITLE]: '1234567890D<<123456F1234567<<<<<<<<<<<<<<<<4',
	[RESIDENCE_TITLE_1]: '123456F12033147TUR<<<<<<<<<<<4',
	[RESIDENCE_TITLE_2]: '0000000000GER1234567F1234567<0123456',
});

const documentImagesProps = {
	[ID_CARD_TITLE]: {
		src: idCardImage,
		alt: 'Muster der Rückseite des Personalausweises mit markierten Zeichenreihenfolgen',
	},
	[PASSPORT_TITLE]: {
		src: passportGermanyImage,
		alt: 'Muster der Vorderseite des Reisepasses mit markierten Zeichenreihenfolgen',
	},
	[RESIDENCE_TITLE_1]: {
		src: residenceTitleVariant1Image,
		alt: 'Muster der Vorderseite des Aufenthaltstitels mit markierten Zeichenreihenfolgen',
	},
	[RESIDENCE_TITLE_2]: {
		src: residenceTitleVariant2Image,
		alt: 'Muster der Vorderseite des Aufenthaltstitels mit markierten Zeichenreihenfolgen',
	},
} as const;

const dropdownSelectButton = {
	text: ID_CARD_TITLE,
	type: BUTTON_TYPES.SQUARE,
} as const;

const dropdownItems = [ID_CARD_TITLE, PASSPORT_TITLE, RESIDENCE_TITLE_1, RESIDENCE_TITLE_2].map(
	(text) =>
		({
			type: BUTTON_TYPES.TEXT_EXT,
			text,
		}) as const,
);

function getInputDescription(selectedTabTitle: string, field: number) {
	switch (selectedTabTitle) {
		case ID_CARD_TITLE:
			switch (field) {
				case 1:
					return STRINGS.ageVerificationIdCardPage.idCardTitle.case_1.text;
				case 2:
					return STRINGS.ageVerificationIdCardPage.idCardTitle.case_2.text;
			}
			break;
		case PASSPORT_TITLE:
			return STRINGS.ageVerificationIdCardPage.passPortTitle.case_1.text;
		case RESIDENCE_TITLE_1:
			switch (field) {
				case 1:
					return STRINGS.ageVerificationIdCardPage.residenceTitle.case_1.text;
				case 2:
					return STRINGS.ageVerificationIdCardPage.residenceTitle.case_2.text;
			}
			break;
		case RESIDENCE_TITLE_2:
			return STRINGS.ageVerificationIdCardPage.residenceTitle_2.text;
	}
}

function cleanNumericIdCardInput(input: string): string {
	const nonNumeric = /[^0-9]/gi;
	return input.replaceAll(/O/gi, '0').replaceAll(nonNumeric, '');
}

function AgeVerificationIdCardPage() {
	/** Variables */
	const { setAgeVerificationIdCardData } = useContextActions();
	const navigate = useNavigate();
	const [field1Value, setField1Value]: [string, AnyFunction] = useState('');
	const [field2Value, setField2Value]: [string, AnyFunction] = useState('');
	const [isField1Valid, setIsField1Valid]: [boolean, AnyFunction] = useState(false);
	const [isField2Valid, setIsField2Valid]: [boolean, AnyFunction] = useState(false);
	const [hasInput1ErrorMessage, setHasInput1ErrorMessage] = useState(false);
	const [hasInput2ErrorMessage, setHasInput2ErrorMessage] = useState(false);
	const isError = hasInput1ErrorMessage || hasInput2ErrorMessage;
	const [message, setMessage]: [string, AnyFunction] = useState('');
	const [selectedTitle, setSelectedTitle] = useState(ID_CARD_TITLE);
	const isOneLineValidation = selectedTitle === PASSPORT_TITLE || selectedTitle === RESIDENCE_TITLE_2;
	const oneFieldInputLength = selectedTitle === RESIDENCE_TITLE_2 ? RESIDENCE_V2_FIELD_LENGTH : PASSPORT_FIELD_LENGTH;
	const onDeeplinkCancel = useOnDeeplinkCancel();

	function validateField1(value: string) {
		let isValid;
		if (isOneLineValidation) {
			isValid = value?.length >= oneFieldInputLength;
		} else {
			isValid = value?.length === TWO_FIELD_LENGTHS.FIRST;
		}

		setIsField1Valid(isValid);
		return isValid;
	}

	function validateField2(value: string) {
		const isValid = value?.length === TWO_FIELD_LENGTHS.SECOND;
		setIsField2Valid(isValid);
		return isValid;
	}

	function isNextButtonEnabled() {
		if (isOneLineValidation) {
			return isField1Valid;
		}

		return isField1Valid && isField2Valid;
	}

	function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		try {
			let idCardData: IdentityCardData;
			if (selectedTitle === PASSPORT_TITLE) {
				idCardData = parseICAO9303Passport(field1Value);
			} else if (selectedTitle === RESIDENCE_TITLE_2) {
				idCardData = convertSingleLine(field1Value);
			} else {
				const cleanedField2 = cleanNumericIdCardInput(field2Value);
				idCardData = {
					idNumber: field1Value,
					birthday: cleanedField2.slice(0, 7),
					validity: cleanedField2.slice(7, 14),
					extra: cleanedField2.slice(14, -1),
					checkCode: cleanedField2.slice(-1),
				};
			}

			const { isValid: isIdCardValid } = checkIdCardData(idCardData);

			if (isIdCardValid) {
				setAgeVerificationIdCardData(idCardData);
				navigate(`./${PAGE_TYPES.AGE_VERIFICATION_PIN}`);
			} else {
				setMessage('Dokument nicht mehr gültig');
			}
		} catch (error) {
			setMessage('Angaben nicht korrekt. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.');
		}
	}

	function onDropdownChange(index: number) {
		setSelectedTitle(dropdownItems[index]?.text ?? ID_CARD_TITLE);
		setField1Value('');
		setIsField1Valid(false);
		setField2Value('');
		setIsField2Valid(false);
		setHasInput1ErrorMessage(false);
		setHasInput2ErrorMessage(false);
		setMessage('');
	}

	function onField1Change(e: AnyObject) {
		if (e.isValid) {
			setField1Value(e.value);
			setHasInput1ErrorMessage(false);
		} else {
			setField1Value('');
		}
	}

	function onField2Change(e: AnyObject) {
		if (e.isValid) {
			setField2Value(e.value);
			setHasInput2ErrorMessage(false);
		} else {
			setField2Value('');
		}
	}

	function onField1Blur() {
		if (!field1Value) {
			setHasInput1ErrorMessage(true);
		}
	}
	function onField2Blur() {
		if (!field2Value) {
			setHasInput2ErrorMessage(true);
		}
	}

	function getInputBadgeAppearance(isError: boolean, isValid: boolean) {
		if (isError) {
			return InputBadgeAppearance.ERROR;
		}
		if (isValid) {
			return InputBadgeAppearance.SUCCESS;
		}
		return undefined;
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.m)}>
			<Row>
				<Column px={0}>
					<p className={cx(Typo.base, Typo.body02)}>Schritt 1 von 2</p>
				</Column>
				<Column px={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>
						{STRINGS.ageVerificationIdCardPage.ageConfirmation.headline}
					</h1>
				</Column>
				<Column px={0}>
					<p className={cx(Typo.base, Typo.body02)}>
						{STRINGS.ageVerificationIdCardPage.ageConfirmation.text}
					</p>
				</Column>
				<Column px={0}>
					<div className={cx(CardInfo.base, isError && CardInfo.isError)}>
						<Icon name="info" />
						<p className={cx(Typo.base, Typo.body02)}>
							Nur <b>gültige</b> Ausweisdokumente können verwendet werden, keine abgelaufenen
							Ausweisdokumente.
						</p>
					</div>
				</Column>
				<Column px={0}>
					<Dropdown items={dropdownItems} selectButton={dropdownSelectButton} onChange={onDropdownChange} />
				</Column>
				<Column px={0}>
					<div className={cx(ImageWrapper.base)}>
						<img
							alt={documentImagesProps[selectedTitle as keyof typeof documentImagesProps].alt}
							src={documentImagesProps[selectedTitle as keyof typeof documentImagesProps].src}
						/>
					</div>
				</Column>
				<Column p={0}>
					<form key={selectedTitle} onSubmit={onSubmit}>
						{isOneLineValidation ? (
							<Column p={0}>
								<div className={cx(InputWrapper.base)}>
									<InputBadgeWidget
										text="1"
										appearance={getInputBadgeAppearance(hasInput1ErrorMessage, isField1Valid)}
									/>
									<Input
										label={LOWER_MACHINE_READABLE_ROW[selectedTitle]}
										onChange={onField1Change}
										onBlur={onField1Blur}
										errorMessage={`Die Eingabe muss exakt ${oneFieldInputLength} Zeichen lang sein und das „<"-Zeichen beinhalten.`}
										htmlAttributes={{
											minLength: oneFieldInputLength,
											maxLength: oneFieldInputLength,
											title: getInputDescription(selectedTitle, 1),
											autoComplete: 'off',
										}}
										hasHintValidation
										hasMonospaceFont
										hasCharCount
										validation={validateField1}
										description={getInputDescription(selectedTitle, 1)}
									/>
								</div>
							</Column>
						) : (
							<>
								<Column px={0} pt={0}>
									<div className={cx(InputWrapper.base)}>
										<InputBadgeWidget
											text="1"
											appearance={getInputBadgeAppearance(hasInput1ErrorMessage, isField1Valid)}
										/>
										<Input
											label={IDENT_NUMBER[selectedTitle as keyof typeof IDENT_NUMBER]}
											onChange={onField1Change}
											onBlur={onField1Blur}
											errorMessage={`Die Eingabe muss exakt ${TWO_FIELD_LENGTHS.FIRST} Zeichen lang sein.`}
											htmlAttributes={{
												minLength: TWO_FIELD_LENGTHS.FIRST,
												maxLength: TWO_FIELD_LENGTHS.FIRST,
												title: getInputDescription(selectedTitle, 1),
												autoComplete: 'off',
											}}
											hasHintValidation
											hasMonospaceFont
											hasCharCount
											validation={validateField1}
											description={getInputDescription(selectedTitle, 1)}
										/>
									</div>
								</Column>
								<Column p={0}>
									<div className={cx(InputWrapper.base)}>
										<InputBadgeWidget
											text="2"
											appearance={getInputBadgeAppearance(hasInput2ErrorMessage, isField2Valid)}
										/>
										<Input
											label={
												LOWER_MACHINE_READABLE_ROW[selectedTitle as keyof typeof IDENT_NUMBER]
											}
											onChange={onField2Change}
											onBlur={onField2Blur}
											errorMessage={`Die Eingabe muss exakt ${TWO_FIELD_LENGTHS.SECOND} Zeichen lang sein und das „<"-Zeichen beinhalten.`}
											htmlAttributes={{
												minLength: TWO_FIELD_LENGTHS.SECOND,
												maxLength: TWO_FIELD_LENGTHS.SECOND,
												title: getInputDescription(selectedTitle, 2),
												autoComplete: 'off',
											}}
											hasHintValidation
											hasMonospaceFont
											hasCharCount
											validation={validateField2}
											description={getInputDescription(selectedTitle, 2)}
										/>
									</div>
								</Column>
							</>
						)}
						{message && <MessageWidget>{message}</MessageWidget>}
						<Column px={0}>
							<div className={cx(ButtonsWrapper.base)}>
								<Button
									text={STRINGS.general.cancel.button}
									type={BUTTON_TYPES.SECONDARY}
									onClick={onDeeplinkCancel}
									htmlAttributes={{ type: 'button' }}
								/>
								<Button
									text={STRINGS.general.next.button}
									type={BUTTON_TYPES.PRIMARY}
									isDisabled={!isNextButtonEnabled()}
									htmlAttributes={{
										type: 'submit',
									}}
								/>
							</div>
						</Column>
					</form>
				</Column>
			</Row>
		</main>
	);
}

export default AgeVerificationIdCardPage;
