import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const AvatarCustomBox = {
	base: css`
		padding: 0;

		@media only screen and (max-width: 380px) {
			max-width: 100vw;
			min-width: unset;
			width: unset;
		}
	`,
};

export const ControlArea = {
	base: css`
		background-color: rgba(var(--background-color-overlay-alpha));
		backdrop-filter: blur(4px);
		position: sticky;
		bottom: 0;
		padding: 24px 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		max-height: 100%;
	`,
};

export const AvatarArea = {
	base: css`
		display: flex;
		padding: 0 48px 0 88px;
		${Media('max', 'm')} {
			padding: 0 24px 0 24px;
		}
		flex-wrap: wrap;
		width: 100%;
		overflow: hidden;
		box-sizing: border-box;
	`,
};

export const ProfileActions = {
	base: css`
		a {
			width: 100%;
		}
	`,
};

export const Toggle = {
	base: css`
		display: flex;
		align-items: center;
		gap: 16px;
	`,
};

export const ChildButton = {
	base: css`
		.${ButtonRoot.base} {
			background: rgb(50, 241, 241, 1);
			background-image: linear-gradient(180deg, rgb(50, 241, 241, 1) 0%, rgb(50, 241, 241, 0) 80%),
				repeating-linear-gradient(
					45deg,
					rgb(120, 120, 120, 0.2),
					rgb(120, 120, 120, 0.2) 1.4rem,
					transparent 1.4rem,
					transparent 2.8rem
				);
			border-width: 2px;
			border-style: solid;
			border-top-width: 0;
			border-left-width: 0;
			border-right-width: 0;
			box-shadow: inset 0 0 0 2px rgb(50, 241, 241, 1);
			border-color: rgb(40, 191, 191, 1);
			fill: #e25047 !important;
			color: #320064 !important;

			&:hover {
				background: rgb(255, 255, 255, 1) !important;
				background-image: linear-gradient(180deg, rgb(255, 255, 255, 1) 0%, rgb(255, 255, 255, 0) 80%),
					repeating-linear-gradient(
						45deg,
						rgb(120, 120, 120, 0.2),
						rgb(120, 120, 120, 0.2) 1.4rem,
						transparent 1.4rem,
						transparent 2.8rem
					) !important;
				box-shadow: inset 0 0 0 2px rgb(255, 255, 255, 1);
				path {
					fill: #e25047 !important;
				}
			}

			${Media('max', 's')} {
				width: 100%;
				justify-content: center;
			}
		}
	`,
};

export const AgeDropdownColumn = {
	base: css`
		position: relative;
		ul {
			max-height: 320px;
		}
	`,
};
