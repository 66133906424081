import { cx } from '@linaria/core';
import { Root } from './styles';
import { Typo } from '@ard-online/component-styleguide';

export enum InputBadgeAppearance {
	'SUCCESS',
	'ERROR',
}

type InputBadgeWidgetProps = {
	text: string;
	appearance?: InputBadgeAppearance;
};

function InputBadgeWidget({ text, appearance }: InputBadgeWidgetProps) {
	/** JSX */
	return (
		<span
			className={cx(
				Typo.base,
				Typo.info03,
				Root.base,
				appearance === InputBadgeAppearance.SUCCESS && Root.isSuccess,
				appearance === InputBadgeAppearance.ERROR && Root.isError,
			)}
		>
			{text}
		</span>
	);
}

export default InputBadgeWidget;
