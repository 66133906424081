import { useState } from 'react';
import { Chip, Column, Input, Row } from '@ard-online/component-styleguide';
import { hasAtLeast8Characters, hasNumber, hasUpperAndLowercase, isValidPassword } from '../../../services/validation';
import { isTouchDevice } from '../../../services/misc';
import { AnyFunction, AnyObject } from '../../../types';

const HELP_MESSAGES = Object.freeze({
	DEFAULT: STRINGS.passwordWidget.helpMessages.default.text,
	NOT_AT_LEAST_8_CHARACTERS: STRINGS.passwordWidget.helpMessages.notEightCharacters.text,
	NO_UPPER_AND_LOWERCASE: STRINGS.passwordWidget.helpMessages.noUpperAndLowerCase.text,
	NO_NUMBER: STRINGS.passwordWidget.helpMessages.noNumber.text,
});

function PasswordWidget({
	onValidPassword,
	hasFocus = !isTouchDevice(),
	inputLabel = 'Passwort',
}: {
	onValidPassword?: (password: string) => void;
	hasFocus?: boolean;
	inputLabel?: string;
}) {
	/** Variables */
	const [password, setPassword] = useState('');
	const [isInputValid, setIsInputValid] = useState(false);
	const [inputErrorMessage, setInputErrorMessage] = useState<string>(HELP_MESSAGES.DEFAULT);
	const [hasInputErrorMessage, setHasInputErrorMessage] = useState(false);

	function onInputChange(e: AnyObject) {
		setPassword(e.value);
		setIsInputValid(e.isValid);
		if (e.isValid) {
			onValidPassword?.(e.value);
			setHasInputErrorMessage(false);
		} else {
			onValidPassword?.('');
		}
	}

	function onBlur() {
		if (!isInputValid) {
			setHasInputErrorMessage(true);
		}
		const atLeast8Characters = hasAtLeast8Characters(password);
		const upperAndLowercase = hasUpperAndLowercase(password);
		const number = hasNumber(password);
		if ([!atLeast8Characters, !upperAndLowercase, !number].filter(Boolean).length >= 2) {
			setInputErrorMessage(HELP_MESSAGES.DEFAULT);
		} else {
			if (!atLeast8Characters) {
				setInputErrorMessage(HELP_MESSAGES.NOT_AT_LEAST_8_CHARACTERS);
			} else if (!upperAndLowercase) {
				setInputErrorMessage(HELP_MESSAGES.NO_UPPER_AND_LOWERCASE);
			} else if (!number) {
				setInputErrorMessage(HELP_MESSAGES.NO_NUMBER);
			}
		}
	}

	function validateChip(validation: AnyFunction, hasErrorMessage: boolean) {
		if (hasErrorMessage) {
			if (validation(password)) {
				return 'active';
			} else {
				return 'error';
			}
		} else {
			if (validation(password)) {
				return 'active';
			} else {
				return;
			}
		}
	}

	/** JSX */
	return (
		<Row p={0}>
			<Column p={0}>
				<Input
					id="passwordInput"
					type="password"
					label={inputLabel}
					isRequired
					errorMessage={inputErrorMessage}
					onChange={onInputChange}
					onBlur={onBlur}
					validation={isValidPassword}
					hasFocus={hasFocus}
					hasHintValidation
					htmlAttributes={{
						autoComplete: 'new-password',
						title: STRINGS.passwordWidget.input.text,
					}}
				/>
			</Column>
			<Column px={0} pb={0}>
				<Chip
					text="8 Zeichen"
					ariaLabel={HELP_MESSAGES.NOT_AT_LEAST_8_CHARACTERS}
					type={validateChip(hasAtLeast8Characters, hasInputErrorMessage)}
				/>
				<Chip
					text="Groß- & Kleinschreibung"
					ariaLabel={HELP_MESSAGES.NO_UPPER_AND_LOWERCASE}
					type={validateChip(hasUpperAndLowercase, hasInputErrorMessage)}
				/>
				<Chip
					text="1 Zahl"
					ariaLabel={HELP_MESSAGES.NO_NUMBER}
					type={validateChip(hasNumber, hasInputErrorMessage)}
				/>
			</Column>
		</Row>
	);
}

export default PasswordWidget;
