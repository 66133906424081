import { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typo, Input, Row, Column } from '@ard-online/component-styleguide';
import { Box, ButtonsWrapper } from '../../app/styles';
import { isValidEmail } from '../../../services/validation';
import MessageWidget from '../../widgets/message/MessageWidget';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildInfoLink, buildMainLink } from '../../../services/link';
import { INFO_TYPES, PAGE_TYPES } from '../../../configs/types';
import { ErrorMessages, GoogleApiCodes } from '../../../configs/constants';
import { NavigateFunction } from 'react-router';
import { isGoogleApiError, isTouchDevice, useHashParams } from '../../../services/misc';
import { AnyObject } from '../../../types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import getContinueUrl from '../../../services/url/getContinueUrl';

function navigateOnSuccess(navigate: NavigateFunction, email: string) {
	navigate(`${buildInfoLink(INFO_TYPES.RESET_PASSWORD_MAIL_SENT)}#email=${email}`);
}

function ForgotPasswordPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const hashParams = useHashParams();
	const emailParam = hashParams?.get('email') ?? '';
	const [email, setEmail] = useState(emailParam);
	const [message, setMessage] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	async function onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (email && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				let actionCodeSettings;

				if (appState.navigation.redirectUrl) {
					actionCodeSettings = {
						url: getContinueUrl(appState),
					};
				}

				await sendPasswordResetEmail(auth, email, actionCodeSettings);
				navigateOnSuccess(navigate, email);
			} catch (error) {
				if (isGoogleApiError(error) && error.code === GoogleApiCodes.USER_NOT_FOUND) {
					navigateOnSuccess(navigate, email);
				} else {
					console.error(error);
					setMessage(ErrorMessages.DEFAULT);
				}

				setLoading(false);
			}
		}
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<Column p={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>Passwort vergessen?</h1>
				</Column>
				<form onSubmit={onSubmit}>
					<Column p={0}>
						<p className={cx(Typo.base, Typo.body02)}>{STRINGS.forgotPasswordPage.form.text}</p>
					</Column>
					<Column px={0} pb={0}>
						<Input
							type="email"
							label="E-Mail-Adresse"
							isRequired
							errorMessage="Keine valide Email-Adresse"
							initialValue={emailParam}
							onChange={onInputChange}
							validation={isValidEmail}
							hasFocus={!isTouchDevice()}
							htmlAttributes={{
								autoComplete: 'email',
								title: 'E-Mail-Adresse eingeben',
							}}
						/>
					</Column>
					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.general.cancel.button}
								type={BUTTON_TYPES.SECONDARY}
								href={buildMainLink(PAGE_TYPES.ACCOUNT)}
								as={Link}
								htmlAttributes={{
									type: 'button',
								}}
							/>
							<Button
								text={STRINGS.forgotPasswordPage.form.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!email}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default ForgotPasswordPage;
