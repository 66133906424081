import { css } from '@linaria/core';

export const ChildLabel = {
	base: css`
		position: absolute;
		text-align: center;
		margin: auto;
		z-index: 10;
		transform: rotate(-3deg) translate(-50%, -60%);
		left: 50%;
		width: 100%;
	`,
	text: css`
		padding: 4px 8px;
		border-radius: 4px;
		color: rgba(90, 25, 156, 1);
		line-height: 120%;
		background: rgba(254, 190, 0, 1);
	`,
};
