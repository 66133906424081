import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';

export const AccountSection = {
	base: css`
		margin-bottom: 56px;
	`,
};

export const ColumnLink = {
	base: css`
		text-align: end;
		text-decoration: underline;
		${Media('max', 's')} {
			text-align: start;
		}
	`,
};

export const TextWithIcon = {
	base: css`
		display: flex;
		align-items: center;
	`,
};

export const Checkmark = {
	base: css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--icon-sizing-icon-xs);
		height: var(--icon-sizing-icon-xs);
		margin-right: var(--table-spacing-gap-td);
		fill: rgb(var(--icon-color-icon));
		border-radius: 100%;
		background: rgb(var(--input-color-success));
		&.red {
			background: rgb(var(--input-color-warning));
			fill: rgb(var(--icon-color-icon));
		}
		svg {
			width: var(--icon-sizing-icon-xs);
		}
	`,
};

export const AddProfileButton = {
	base: css`
		display: flex;
		justify-content: flex-end;
	`,
};
