import { useState, useEffect } from 'react';
import {
	darkArdTheme,
	lightArdTheme,
	FontSizes,
	FontStyles,
	ThemeContext,
	MainBox,
	GlobalCssVariables,
} from '@ard-online/component-styleguide';
import { GlobalStyle, Root, CenterBox } from './styles';
import ToolbarWidget from '../widgets/toolbar/ToolbarWidget';
import { useContextActions } from '../../services/actions/ActionsService';
import { MODE_TYPES, THEME_TYPES } from '../../configs/types';
import AppRoutes from './routes';
import FooterWidget from '../widgets/footer/FooterWidget';
import 'swiper/css/bundle';
import { LinariaClassName, cx } from '@linaria/core';
import { THEME_LAYOUT_TYPES, THEME_ID_TYPES, THEME_NAME_TYPES } from '@ard-online/component-styleguide/dist/types';
import userService from '../../services/user/UserService';

type Theme = {
	id: THEME_ID_TYPES;
	name: THEME_NAME_TYPES;
	base: LinariaClassName;
	layout: THEME_LAYOUT_TYPES;
};

function getTheme(themeType: THEME_TYPES) {
	switch (themeType) {
		case THEME_TYPES.DARK:
			return darkArdTheme;
		case THEME_TYPES.LIGHT:
			return lightArdTheme;
		case THEME_TYPES.DEFAULT:
			if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				return darkArdTheme;
			} else {
				return lightArdTheme;
			}
		default:
			return lightArdTheme;
	}
}

function App() {
	/** Variables */
	const { appState } = useContextActions();
	const [theme, setTheme] = useState(() => getTheme(appState.view.theme));

	function changeTheme(newTheme: Theme) {
		setTheme((prevTheme: Theme) => {
			if (prevTheme?.base !== newTheme?.base) {
				document.body.classList.remove(prevTheme?.base);
				document.body.classList.add(newTheme?.base);
			}
			return newTheme;
		});
	}

	useEffect(() => {
		const themeClassName = theme?.base;
		document.body.classList.add(themeClassName);
		return () => {
			document.body.classList.remove(themeClassName);
		};
	}, []);

	useEffect(() => {
		const newThemeId = getTheme(appState.view.theme).id;
		if (theme.id !== newThemeId) {
			changeTheme(getTheme(appState.view.theme));
		}
	}, [appState.view.theme]);

	/** JSX */
	return (
		<ThemeContext.Provider value={theme}>
			<div className={cx(Root.base, FontStyles, FontSizes, GlobalCssVariables, GlobalStyle)}>
				{appState.config.modeType !== MODE_TYPES.IN_APP && <ToolbarWidget />}
				<div className={cx(MainBox.base, CenterBox.base)}>
					<AppRoutes />
				</div>
				{!userService.isChild && <FooterWidget />}
			</div>
		</ThemeContext.Provider>
	);
}

export default App;
