import { Outlet } from 'react-router-dom';
import ConfirmPasswordPage from '../confirmPassword/ConfirmPasswordPage';
import userService from '../../../services/user/UserService';
import { LOGIN_TYPES } from '../../../configs/types';
import { useContextActions } from '../../../services/actions/ActionsService';

function ContextEditAccountPage() {
	const { appState } = useContextActions();

	if (userService.loginType === LOGIN_TYPES.PASSWORD || appState.view.isPasswordConfirmed) {
		return <Outlet />;
	} else {
		return <ConfirmPasswordPage />;
	}
}

export default ContextEditAccountPage;
