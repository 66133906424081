import isEmail from 'validator/lib/isEmail';
import matches from 'validator/lib/matches';

export function hasUpperAndLowercase(password: string): boolean {
	return matches(password, /^(?=.*[a-z])(?=.*[A-Z])/);
}

export function hasAtLeast8Characters(password: string): boolean {
	return matches(password, /^(?=.*[a-zA-Z\d]).{8,100}$/);
}

export function hasNumber(password: string): boolean {
	return matches(password, /.*[0-9].*/);
}

export function isPinValid(pin: string) {
	return /^\d{4}$/.test(pin);
}

export function isValidPassword(password: string): boolean {
	return matches(password, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/);
}

export function isValidEmail(email: string): boolean {
	return isEmail(email, { allow_utf8_local_part: false });
}
