import { Media } from '@ard-online/component-styleguide';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';
import { sizes } from '@ard-online/component-styleguide/dist/components/primitives/Media';
import { css } from '@linaria/core';

export const Root = {
	base: css`
		display: flex;
		background-color: rgb(var(--background-color-base));
		min-height: 100vh;
		flex-direction: column;

		ul {
			padding: 0;
		}
	`,
};

export const Box = {
	base: css`
		display: flex;
		flex-direction: column;
		position: relative;
		box-sizing: border-box;
		will-change: transform, opacity;
		justify-content: space-evenly;
		margin: 50px auto;

		@media only screen and (max-width: 380px) {
			max-width: 100vw;
			min-width: unset;
			width: 320px;
		}

		animation: fadeScaleIn 200ms var(--global-ease-out) both;
		@keyframes fadeScaleIn {
			0% {
				opacity: 0;
				transform: scale(0.8);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		.${ButtonRoot.base} {
			align-self: center;
		}
	`,

	type: {
		full: css`
			max-width: 100vw;
		`,
		l: css`
			max-width: ${sizes.l}px;
		`,
		m: css`
			max-width: ${sizes.m}px;
		`,
		s: css`
			max-width: ${sizes.s}px;
		`,
		xs: css`
			max-width: ${sizes.xs}px;
		`,
	},
};

export const CenterBox = {
	base: css`
		margin: auto;
	`,
};

export const GlobalStyle = css`
	/* stylelint-disable-next-line */
	:global() {
		body {
			color: rgb(var(--text-color-body-01));
			margin: 0;
			font-family: var(--typography-family-font-family), sans-serif;
		}

		a {
			color: rgb(var(--text-color-body-01));
		}

		a:hover {
			text-decoration: underline;
		}

		:active,
		:hover,
		:visited {
			outline: none;
		}

		:focus {
			outline: var(--button-secondary-border-focus) solid rgb(var(--button-secondary-color-border-focus));
			outline-offset: -2px;
		}

		hr {
			border: 0;
			width: 100%;
			border-top: 1px solid rgba(var(--text-color-body-01), 0.3);
		}
	}
`;

export const Logo = {
	base: css`
		svg {
			width: 60%;
			max-width: 167px;
			margin: 0 auto;
		}
	`,
};

export const ButtonsWrapper = {
	base: css`
		border-top: 1px solid rgba(var(--text-color-body-01), 0.3);
		padding-top: 16px;
		margin-top: 16px;
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 100%;

		> .${ButtonRoot.base} {
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}
		}

		${Media('max', 'xs')} {
			flex-direction: column-reverse;
			text-align: center;
			> .${ButtonRoot.base} {
				width: 100%;
				margin-right: 0;
				margin-bottom: 16px;
				> span {
					width: 100%;
					justify-content: center;
				}
			}
		}
	`,
};

export const ParagraphsWrapper = {
	base: css`
		h2 {
			margin-top: 32px;
		}
		p {
			margin-top: 16px;
		}
	`,
};
