import { User, UserResponseBody } from '../../types';

export function sortUsers(users: Array<User> | Array<UserResponseBody>, firstUserId?: string | null) {
	return users.sort((a: User | UserResponseBody, b: User | UserResponseBody) => {
		let aName;
		let bName;
		let aUserId;
		let bUserId;
		if ('name' in a && 'name' in b) {
			// is type User
			aName = a.name;
			bName = b.name;
			aUserId = a.id;
			bUserId = b.id;
		} else {
			// must be type UserResponeBody
			aName = (a as UserResponseBody).displayName;
			bName = (b as UserResponseBody).displayName;
			aUserId = (a as UserResponseBody).uid;
			bUserId = (b as UserResponseBody).uid;
		}

		if (aUserId === firstUserId) {
			return -1;
		}
		if (bUserId === firstUserId) {
			return 0;
		}
		return aName.localeCompare(bName);
	});
}
