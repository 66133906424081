import { redirectToRefererApp } from '../../../../services/misc';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { buildMainLink } from '../../../../services/link';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../../configs/types';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { shouldRedirectToRefererApp } from '../../../../services/misc/shouldRedirectToRefererApp';

function AgeVerificationPinChanged() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	async function onClick() {
		if (shouldRedirectToRefererApp(appState.navigation)) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.AGE_VERIFICATION_PIN_CHANGED);
		} else {
			navigate(buildMainLink(PAGE_TYPES.ACCOUNT));
		}
	}

	/** JSX */
	return (
		<Row>
			<Column p={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Jugendschutz-Code geändert</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>Ihr Jugendschutz-Code wurde erfolgreich geändert.</p>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button text={STRINGS.general.ok.button} onClick={onClick} type={BUTTON_TYPES.PRIMARY} />
				</div>
			</Column>
		</Row>
	);
}

export default AgeVerificationPinChanged;
