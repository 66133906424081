import env from './env';

export const REFRESH_URL = 'https://securetoken.googleapis.com/v1/token';
export enum StorageKeys {
	EMAIL = 'email',
	USERS = 'users',
	SESSION_CONFIRMED = 'ard-ams-session-confirmed',
}
export const DB_NAME = 'ard-ams';
export const DB_VERSION = 2;
export enum CustomClaimKeys {
	AGE_RATING = 'age_rating',
	PIN = 'pin',
	CHILD_PIN = 'child_pin',
	MAIN_USER_ID = 'mainUserId',
	SUB_USER_IDS = 'subUserIds',
}
export enum ErrorMessages {
	DEFAULT = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut.',
	VERIFY_EMAIL = 'Bitte verifizieren Sie Ihre E-Mail-Adresse. Eine Nachricht mit dem Link zur Verifizierung wurde soeben erneut an Sie gesendet.',
	EMAIL_OR_PASSWORT = 'E-Mail-Adresse und Passwort stimmen nicht überein.',
	INVALID_USER_CODE = 'Der eingegebene Code ist ungültig. Bitte überprüfen Sie Ihren Code und versuchen Sie es erneut.',
	UNIQUE_PROFILE_NAME = 'Der Profilname muss innerhalb des Kontos eindeutig sein.',
	DELETE_PROFILE = 'Das Profil konnte nicht gelöscht werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
	LOGOUT = 'LOGOUT',
}
export enum GoogleApiCodes {
	USER_NOT_FOUND = 'auth/user-not-found',
	WRONG_PASSWORD = 'auth/wrong-password',
	TOO_MANY_REQUESTS = 'auth/too-many-requests',
	EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
	EMAIL_NOT_VALID = 'auth/invalid-recipient-email',
	ID_TOKEN_EXPIRED = 'auth/id-token-expired',
	ARGUMENT_ERROR = 'auth/argument-error',
}

export const BASE_PROFILE_IMAGE_URL = `${env.HOST}/profileImages`;

export enum PROFILEIMAGE_GROUP_NAMES {
	ard = 'ARD',
	arthur = 'Arthur',
	baumhaus = 'Baumhaus',
	berndDasBrot = 'Bernd',
	charlieUndLola = 'Charlie und Lola',
	deineStars = 'Deine Stars',
	dieSendungMitDerMaus = 'Die Sendung mit der Maus / dem Elefanten',
	feuerwehrmannSam = 'Feuerwehrmann Sam',
	ichKenneEinTier = 'Ich kenne ein Tier',
	kika = 'KiKA',
	odo = 'Odo',
	rabeSocke = 'Rabe Socke',
	ricoUndOskar = 'Rico und Oskar',
	schluempfe = 'Die Schlümpfe',
	sesamstrasse = 'Sesamstraße',
	tibUndTumtum = 'Tib und Tumtum',
	tigerentenClub = 'Tigerenten Club',
	tom = 'TOM und das Erbeermarmeladenbrot mit Honig',
	trudesTier = 'Trudes Tier',
	weitereFiguren = 'Weitere Figuren',
	zoe = 'Zoés Zauberschrank',
}

export enum ProfileInfos {
	MAIN_USER_INFO = 'Das ist dein Hauptprofil. Du kannst das Hauptprofil nur löschen, indem du das Konto löschst.',
	SUB_AS_MAIN_USER_INFO = 'Hier kannst du dieses Profil aus dem ARD-Konto löschen.',
	SUB_USER_INFO = 'Weitere Einstellungen können mit ausgewähltem Hauptprofil vorgenommen werden.',
	ADD_PROFILE_INFO = 'Profilname und Profilbild helfen, dein Profil von anderen Profilen in diesem Konto zu unterscheiden. Der Profilname ist nicht öffentlich.',
	DELETE_PROFILE_HEADLINE = 'Bist du sicher, dass du das Profil löschen möchtest?',
	DELETE_PROFILE_INFO = 'Das Profil und die zugehörigen Daten werden auf allen Geräten gelöscht.',
	DELETE_PROFILE_INFO_2 = 'Dieser Schritt kann nicht rückgängig gemacht werden.',
	PASSWORD_TEXT = 'Wenn Sie sich sicher sind, bestätigen Sie zum Löschen bitte das Passwort für ',
}

export const MAX_SUB_USERS = 8;

export enum EXTERNAL_URLS {
	SOPHORA_HOST = 'https://www.daserste.de/ard/die-ard',
	HELP_CHILDPROFILE = 'https://hilfe.ard.de/artikel/kinderprofil/',
}
