import { cx } from '@linaria/core';
import { ChildLabel } from './styles';
import { Typo } from '@ard-online/component-styleguide';

type Props = {
	ageRating?: number | null;
};

function ChildLabelWidget({ ageRating }: Props) {
	/** Variables */
	const text = ageRating ? `${ageRating} Jahre` : `Kind`;

	/** JSX */
	return (
		<div className={cx(ChildLabel.base)}>
			<span className={cx(Typo.base, Typo.heading04, ChildLabel.text)}>{text}</span>
		</div>
	);
}

export default ChildLabelWidget;
