import type { ReactNode } from 'react';
import { createContext, useState, useContext, useSyncExternalStore } from 'react';
import { produce } from 'immer';
import defaultState from '../../configs/defaultState';
import type { AppState } from '../../configs/defaultState';
import type { IdentityCardData } from '../../server/app/ageVerification/verifyIdentityCardData';
import userService from '../user/UserService';
import { THEME_TYPES } from '../../configs/types';

export function doNothing(): void {
	// do nothing
}

export const ActionsContext = createContext<ActionContextValue>({
	appState: defaultState,
	setRedirectUrl: doNothing,
	setEntryPageSlug: doNothing,
	setAgeVerificationIdCardData: doNothing,
	setRegistrationMail: doNothing,
	setTheme: doNothing,
	setNextUser: doNothing,
	setPasswordConfirmed: doNothing,
});

export type ActionContextValue = {
	appState: AppState;
	setRedirectUrl: (value: string) => void;
	setEntryPageSlug: (value: string) => void;
	setAgeVerificationIdCardData: (value: IdentityCardData | null) => void;
	setRegistrationMail: (value: string) => void;
	setTheme: (value: THEME_TYPES) => void;
	setNextUser: (value: Partial<AppState['account']['nextUser']>) => void;
	setPasswordConfirmed: () => void;
};

export function useContextActions() {
	return useContext(ActionsContext);
}

type Props = {
	children: ReactNode;
	initState?: AppState;
};

type SetNextState = (arg0: AppState, arg1: AppState) => void;

function ActionsService({ children, initState = defaultState }: Props) {
	// triggers a rerender on user loggedIn, loggedOut or changed
	useSyncExternalStore(
		userService.subscribe,
		() => userService.id,
		() => userService.id,
	);

	const [state, setState] = useState(initState);

	function updateState(setNextState: SetNextState) {
		setState((prevState) =>
			produce(prevState, (draftState) => {
				setNextState(prevState, draftState);
			}),
		);
	}

	function setRedirectUrl(value: string) {
		updateState((prevState, nextState) => {
			if (prevState.navigation.redirectUrl !== value) {
				nextState.navigation.redirectUrl = value;
			}
		});
	}

	function setEntryPageSlug(value: string) {
		updateState((prevState, nextState) => {
			if (prevState.navigation.entryPageSlug !== value) {
				nextState.navigation.entryPageSlug = value;
			}
		});
	}

	function setAgeVerificationIdCardData(value: IdentityCardData | null) {
		updateState((prevState, nextState) => {
			if (prevState.ageVerification.idCardData !== value) {
				nextState.ageVerification.idCardData = value;
			}
		});
	}
	function setRegistrationMail(value: string) {
		updateState((prevState, nextState) => {
			if (prevState.account.email !== value) {
				nextState.account.email = value;
			}
		});
	}
	function setTheme(value: THEME_TYPES) {
		updateState((prevState, nextState) => {
			if (prevState.view.theme !== value) {
				nextState.view.theme = value;
			}
		});
	}
	function setNextUser(value: Partial<AppState['account']['nextUser']>) {
		updateState((prevState, nextState) => {
			if (nextState.account.nextUser !== value) {
				nextState.account.nextUser = {
					...prevState.account.nextUser,
					...value,
				};
			}
		});
	}

	function setPasswordConfirmed() {
		updateState((prevState, nextState) => {
			if (!prevState.view.isPasswordConfirmed) {
				nextState.view.isPasswordConfirmed = true;
			}
		});
	}

	return (
		<ActionsContext.Provider
			value={{
				appState: state,
				setRedirectUrl,
				setEntryPageSlug,
				setAgeVerificationIdCardData,
				setRegistrationMail,
				setTheme,
				setNextUser,
				setPasswordConfirmed,
			}}
		>
			{/* console.log({ ...{}, ...state }) */}
			{children}
		</ActionsContext.Provider>
	);
}

export default ActionsService;
