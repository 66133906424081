import { css } from '@linaria/core';
import { Media } from '@ard-online/component-styleguide';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const ImageColumn = {
	base: css`
		position: relative;
		justify-content: center;
		align-items: center;
		display: flex;

		${Media('max', 's')} {
			order: -1;
			margin-bottom: 30px;
		}

		> a {
			position: relative;
		}
		.${ImageRoot.base} {
			width: 120px;
			height: 120px;
			border-radius: 100%;
		}
		button {
			border-radius: 100%;
		}
	`,
};

export const EditButton = {
	base: css`
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		width: var(--icon-sizing-icon-l);
		height: var(--icon-sizing-icon-l);
		right: calc(var(--icon-sizing-icon-l) * -0.2);
		top: calc(var(--icon-sizing-icon-l) * -0.2);
		pointer-events: none;
		background: rgba(var(--brand-dark-primary), 1);
		border-radius: 100%;
		> svg {
			width: var(--icon-sizing-icon-s);
			height: var(--icon-sizing-icon-s);
			position: absolute;
			fill: white;
		}
	`,
};
