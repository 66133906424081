import userService from '../user/UserService';

export default async function fetchDeleteAgeVerification() {
	const idToken = userService.idToken;
	try {
		return await fetch('/age-verification', {
			method: 'DELETE',
			headers: {
				'X-Authorization': `Bearer ${idToken}`,
			},
		});
	} catch (error) {
		console.error(error);
		throw error; // need to rethrow for handling in Page onSubmit
	}
}
