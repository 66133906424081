import { css } from '@linaria/core';

export const List = {
	base: css`
		list-style: none;
		li {
			display: flex;
			gap: var(--table-spacing-gap-tr);
			align-items: center;
			margin-bottom: var(--table-spacing-padding-bottom);
			svg {
				fill: rgb(var(--input-color-warning));
				width: var(--icon-sizing-icon-xs);
				height: var(--icon-sizing-icon-xs);
			}
		}
	`,
};
