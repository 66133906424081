import { AppState } from '../../configs/defaultState';
import { sendEmailVerification as sendEmailVerificationViaFirebase, User } from 'firebase/auth';
import getContinueUrl from '../url/getContinueUrl';
import { isGoogleApiError } from '../misc';
import { ErrorMessages, GoogleApiCodes } from '../../configs/constants';

export default async function sendEmailVerification(user: User, appState: AppState): Promise<void> {
	const actionCodeSettings = {
		url: getContinueUrl(appState, user.email),
	};

	try {
		await sendEmailVerificationViaFirebase(user, actionCodeSettings);
	} catch (error) {
		console.error(error);

		if (isGoogleApiError(error) && error.code === GoogleApiCodes.TOO_MANY_REQUESTS) {
			// no email was sent (rate limit: 120 requests/hour)
			throw new Error(ErrorMessages.VERIFY_EMAIL);
		} else {
			throw new Error(ErrorMessages.DEFAULT);
		}
	}
}
