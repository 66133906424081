import { ErrorMessages, GoogleApiCodes } from '../../configs/constants';
import isGoogleApiError from './isGoogleApiError';

// Due to TS warning "Catch clause variable type annotation must be 'any' or 'unknown' if specified." we need to use "unknown" instead of "AnyObject"
function handleConfirmPasswordError(error: unknown): string {
	if (isGoogleApiError(error)) {
		switch (error.code) {
			case GoogleApiCodes.WRONG_PASSWORD:
				return STRINGS.deleteAccountPage.submit.wrongPassword.text;

			case GoogleApiCodes.TOO_MANY_REQUESTS:
				return STRINGS.deleteAccountPage.submit.tooManyRequests.text;

			default:
				return ErrorMessages.DEFAULT;
		}
	} else {
		return ErrorMessages.DEFAULT;
	}
}

export default handleConfirmPasswordError;
