import { css } from '@linaria/core';

export const Root = {
	base: css`
		position: absolute;
		z-index: 2;
		display: flex;
		/* TODO: replace by global color CSS variable when in design system available (hex & rgb) */
		background: rgb(45, 145, 255);
		transition: 0.2s background-color;
		/* TODO: replace by global color CSS variable when in design system available (hex & rgb) */
		color: rgb(255, 255, 255);
		text-align: center;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		top: calc(
			(
					var(--typography-line-height-label-01-rem) + (var(--input-spacing-padding-tb-rem) * 2) - var(
							--icon-sizing-icon-s
						)
				) / 2
		);
		left: calc(var(--input-spacing-padding-lr-rem) * 2);

		width: var(--icon-sizing-icon-s);
		height: var(--icon-sizing-icon-s);
	`,

	isSuccess: css`
		&& {
			background-color: rgb(var(--input-color-success));
		}
	`,
	isError: css`
		&& {
			background-color: rgba(var(--input-color-warning));
		}
	`,
};
