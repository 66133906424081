import { Button } from '@ard-online/component-styleguide';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { Link, useSearchParams } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function CreateAccountButtonWidget() {
	/** Variables */
	const [searchParams] = useSearchParams();
	const isEmailConfirmed = searchParams.has('emailConfirmed');

	/** JSX */
	return (
		<>
			{!userService.id && !isEmailConfirmed && (
				<Button
					href={buildMainLink(PAGE_TYPES.REGISTRATION)}
					as={Link}
					text={STRINGS.createAccountButtonWidget.button}
					type={BUTTON_TYPES.SECONDARY}
				/>
			)}
		</>
	);
}

export default CreateAccountButtonWidget;
