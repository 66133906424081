import userService from '../user/UserService';
import { User, UserRequestBody, UserResponseBody } from '../../types';

export default async function fetchSetUser({
	id,
	name,
	image,
	isSubUser,
	ageRating,
	pin,
}: Partial<User>): Promise<User | null> {
	try {
		const idToken = userService.idToken;
		let fetchUrl = '/users';
		if (id) {
			fetchUrl += `/${id}`;
		}
		const res = await fetch(fetchUrl, {
			method: id ? 'PATCH' : 'POST',
			headers: {
				'X-Authorization': `Bearer ${idToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid: id,
				displayName: name,
				photoURL: image,
				isSubUser: isSubUser,
				ageRating: ageRating,
				pin,
			} satisfies Partial<UserRequestBody>),
		});
		if (res.ok) {
			const { uid, displayName, photoURL, pin, isSubUser, ageRating }: UserResponseBody = await res.json();
			return {
				id: uid,
				name: displayName,
				image: photoURL,
				pin,
				isSubUser,
				ageRating,
			} satisfies User;
		} else {
			throw new Error(`Error ${id ? 'editing' : 'adding'} user | Status ${res.status}: ${await res.text()}`);
		}
	} catch (error) {
		throw error;
	}
}
