import { css } from '@linaria/core';

export const Root = {
	base: css`
		background-color: rgba(var(--input-color-warning), 0.7);
		border: 2px solid rgb(var(--input-color-warning));
		padding: var(--input-spacing-padding-tb) var(--input-spacing-padding-lr);
		display: flex;
		gap: var(--text-spacing-info-01);
		flex-direction: row;
		align-items: center;
		border-radius: var(--toast-corner-radius-all);

		svg {
			fill: rgb(var(--text-color-body-01));
			width: var(--icon-sizing-icon-s);
		}
	`,
	type: {
		success: css`
			background-color: rgb(var(--input-color-success));
			border: 2px solid rgb(var(--input-color-success));
		`,
		error: null,
	},
};
