import { cx } from '@linaria/core';
import { InputWrapper, Text } from './styles';
import { ButtonsWrapper } from '../../app/styles';
import { Button, Input, Typo } from '@ard-online/component-styleguide';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { AnyFunction, AnyObject } from '../../../types';
import { FormEvent, useState } from 'react';

type Props = {
	headline: string;
	text: string | React.ReactNode;
	onSubmit: AnyFunction;
	initialValue?: string;
	isLoading?: boolean;
	onCancel: AnyFunction;
};

function PinWidget({ headline, text, onSubmit, initialValue, isLoading, onCancel }: Props) {
	/** Variables */
	const [pin, setPin]: [string, AnyFunction] = useState('');

	function _onSubmit(event: FormEvent) {
		event.preventDefault();
		event.stopPropagation();
		onSubmit(pin);
	}

	function onPinInputChange(e: AnyObject) {
		if (e.isValid) {
			setPin(e.value);
		} else {
			setPin('');
		}
	}

	/** JSX */
	return (
		<form onSubmit={_onSubmit}>
			<h1 className={cx(Typo.base, Typo.heading01)}>{headline}</h1>
			<div>
				<p className={cx(Typo.base, Typo.body02, Text.base)}>{text}</p>
				<div className={cx(InputWrapper.base)}>
					<Input
						type="pin"
						label="pin"
						isRequired
						errorMessage={STRINGS.ageVerificationPinPage.changeCode.error.text}
						onChange={onPinInputChange}
						hasFocus
						initialValue={initialValue}
						hasHintValidation
						htmlAttributes={{
							title: 'PIN vergeben',
						}}
					/>
				</div>
			</div>
			<div className={cx(ButtonsWrapper.base)}>
				<Button
					text={STRINGS.general.cancel.button}
					type={BUTTON_TYPES.SECONDARY}
					onClick={onCancel}
					htmlAttributes={{
						type: 'button',
					}}
				/>
				<Button
					text={STRINGS.general.save.button}
					type={BUTTON_TYPES.PRIMARY}
					isDisabled={!pin}
					isLoading={isLoading}
					htmlAttributes={{
						type: 'submit',
					}}
				/>
			</div>
		</form>
	);
}

export default PinWidget;
