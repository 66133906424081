import { useLocation, useNavigate } from 'react-router-dom';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../configs/types';
import { buildMainLink } from '../link';
import redirectToRefererApp from './redirectToRefererApp';
import { shouldRedirectToRefererApp } from './shouldRedirectToRefererApp';
import { useContextActions } from '../actions/ActionsService';

export function useOnDeeplinkCancel() {
	const location = useLocation();
	const navigate = useNavigate();
	const { appState } = useContextActions();

	return () => {
		if (shouldRedirectToRefererApp(appState.navigation)) {
			redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.BACK_TO_REFERER_APP);
		} else if (location.key !== 'default') {
			navigate(-1);
		} else {
			navigate(buildMainLink(PAGE_TYPES.ACCOUNT));
		}
	};
}
