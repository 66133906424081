import LoadingIndicatorWidget from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { useSso } from '../login/misc';
import { useEffect } from 'react';

export default function SsoFallbackPage() {
	const { sso, ssoForm } = useSso();

	useEffect(() => {
		(async () => {
			await sso();
		})();
	}, []);

	return (
		<>
			{ssoForm}
			<LoadingIndicatorWidget />
		</>
	);
}
