import { FormEvent, useEffect, useState } from 'react';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../../firebase';
import { isGoogleApiError } from '../../../services/misc';
import { GoogleApiCodes } from '../../../configs/constants';
import PasswordWidget from '../../widgets/password/PasswordWidget';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import defaultState from '../../../configs/defaultState';
import { EmailDisplay } from './styles';

function RegistrationPasswordPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [password, setPassword] = useState('');

	const isNextStepAllowed = !!password;

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			try {
				const { user } = await createUserWithEmailAndPassword(auth, appState.account.email, password);
				await updateProfile(user, {
					displayName: user.email?.split('@')[0],
					photoURL: defaultState.account.nextUser.image,
				});
				navigate(`../${PAGE_TYPES.PROFILE}`);
			} catch (error) {
				console.error(error);

				if (isGoogleApiError(error)) {
					switch (error.code) {
						case GoogleApiCodes.EMAIL_NOT_VALID:
							setMessage('Die E-Mail-Adresse ist ungültig.');
							break;
						case GoogleApiCodes.EMAIL_ALREADY_IN_USE:
							setMessage('Das Benutzerkonto konnte leider nicht angelegt werden.');
							break;
					}
				} else {
					setMessage('Das Benutzerkonto konnte leider nicht angelegt werden.');
				}

				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (!appState.account.email) {
			navigate('..', { replace: true });
		}
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<form onSubmit={onSubmit}>
					<Column p={0}>
						<p className={cx(Typo.base, Typo.body02)}>Schritt 2 von 4</p>
					</Column>{' '}
					<Column px={0}>
						<h1 className={cx(Typo.base, Typo.heading01)}>
							{STRINGS.registrationPasswordPage.step_2.headline}
						</h1>
					</Column>
					<Column p={0}>
						<div className={cx(EmailDisplay.base)}>
							<span>{appState.account.email}</span>
							<Link to={'..'} title="Andere E-Mail-Adresse eingeben">
								{STRINGS.registrationPasswordPage.step_2.button}
							</Link>
						</div>
					</Column>
					<Column p={0}>
						<PasswordWidget onValidPassword={setPassword} />
					</Column>
					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.registrationPasswordPage.back.button}
								type={BUTTON_TYPES.SECONDARY}
								href={buildMainLink(PAGE_TYPES.START)}
								as={Link}
								htmlAttributes={{
									type: 'button',
								}}
							/>
							<Button
								text={STRINGS.general.next.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!isNextStepAllowed}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default RegistrationPasswordPage;
