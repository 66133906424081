import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTrackPageImpression } from '../../../services/tracking';
import { AgeDropdownColumn, Toggle } from './styles';
import { cx } from '@linaria/core';
import { useContextActions } from '../../../services/actions/ActionsService';
import { Icon, Column, Dropdown, Row, Switch, Typo } from '@ard-online/component-styleguide';
import InfoBoxWidget from '../../widgets/infoBox/InfoBoxWidget';
import { Button, BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { EXTERNAL_URLS } from '../../../configs/constants';
import { buildMainLink } from '../../../services/link';
import WordingMatrixStringWithLink from '../../widgets/wordingMatrixStringWithLink/WordingMatrixStringWithLink';
import ProfileNameWithImageWidget from '../../widgets/profileNameWithImage/ProfileNameWithImageWidget';

function getAgeDescription(ageRating: number) {
	switch (ageRating) {
		case 3:
		case 4:
		case 5:
			return STRINGS.childrenProfilePage.selectAge.contentInfo.option3to5.text;
		case 6:
		case 7:
		case 8:
		case 9:
		case 10:
			return STRINGS.childrenProfilePage.selectAge.contentInfo.option6to10.text;
		case 11:
		case 12:
		case 13:
			return STRINGS.childrenProfilePage.selectAge.contentInfo.option11to13.text;
		default:
			return STRINGS.childrenProfilePage.selectAge.contentInfo.defaultOption.text;
	}
}

const dropdownSelectButton = {
	text: STRINGS.childrenProfilePage.selectAge.text,
	type: BUTTON_TYPES.SQUARE,
	htmlAttributes: {
		type: 'button',
	},
};

function useDropdownItems(ageRating?: number) {
	return [
		{
			text: STRINGS.childrenProfilePage.selectAge.defaultOption.input,
			isActive: ageRating === 0 || ageRating === undefined,
			value: 0,
		},
		{ text: STRINGS.childrenProfilePage.selectAge.option3.input, isActive: ageRating === 3, value: 3 },
		{ text: STRINGS.childrenProfilePage.selectAge.option4.input, isActive: ageRating === 4, value: 4 },
		{ text: STRINGS.childrenProfilePage.selectAge.option5.input, isActive: ageRating === 5, value: 5 },
		{ text: STRINGS.childrenProfilePage.selectAge.option6.input, isActive: ageRating === 6, value: 6 },
		{ text: STRINGS.childrenProfilePage.selectAge.option7.input, isActive: ageRating === 7, value: 7 },
		{ text: STRINGS.childrenProfilePage.selectAge.option8.input, isActive: ageRating === 8, value: 8 },
		{ text: STRINGS.childrenProfilePage.selectAge.option9.input, isActive: ageRating === 9, value: 9 },
		{ text: STRINGS.childrenProfilePage.selectAge.option10.input, isActive: ageRating === 10, value: 10 },
		{ text: STRINGS.childrenProfilePage.selectAge.option11.input, isActive: ageRating === 11, value: 11 },
		{ text: STRINGS.childrenProfilePage.selectAge.option12.input, isActive: ageRating === 12, value: 12 },
		{ text: STRINGS.childrenProfilePage.selectAge.option13.input, isActive: ageRating === 13, value: 13 },
	].map((obj) => ({
		...obj,
		htmlAttributes: {
			type: 'button',
		},
	}));
}

function ChildProfilePage({ isEdit = false }) {
	/** Variables */
	const { appState, setNextUser } = useContextActions();
	const userId = useParams().userId ?? (isEdit && userService.id);
	const ageDescription = getAgeDescription(appState.account.nextUser.ageRating ?? 18);

	function onClickPinSwitch() {
		setNextUser({ hasChildPin: !appState.account.nextUser.hasChildPin });
	}

	const dropdownItems = useDropdownItems(appState.account.nextUser.ageRating);

	function onDropdownChange(index: number) {
		setNextUser({ ageRating: dropdownItems[index].value });
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		setNextUser({
			ageRating: appState.account.nextUser.ageRating ?? 0,
			hasChildPin:
				(!!userService.childPin && appState.account.nextUser.hasChildPin === undefined) ||
				appState.account.nextUser.hasChildPin,
		});
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<>
			<Row>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading01)}>
						{userId ? STRINGS.childrenProfilePage.edit.headline : STRINGS.childrenProfilePage.add.headline}
					</h1>
				</Column>
				<Column>
					<ProfileNameWithImageWidget
						ageRating={appState.account.nextUser.ageRating ?? undefined}
						url={buildMainLink(`${PAGE_TYPES.PROFILE}/${PAGE_TYPES.PROFILE_IMAGE}`)}
					/>
				</Column>
			</Row>

			<Row>
				<Column>
					<h2 className={cx(Typo.base, Typo.heading02)}>{STRINGS.profilePage.selectAge.headline}</h2>
				</Column>
				<Column className={cx(AgeDropdownColumn.base)}>
					<Dropdown selectButton={dropdownSelectButton} items={dropdownItems} onChange={onDropdownChange} />
				</Column>
				<Column>
					<InfoBoxWidget>{ageDescription}</InfoBoxWidget>
				</Column>
			</Row>

			<Row>
				<Column>
					<h2 className={cx(Typo.base, Typo.heading02)}>
						{STRINGS.childrenProfilePage.restrictionPIN.headline}
					</h2>
				</Column>
				<Column>
					<WordingMatrixStringWithLink
						text={STRINGS.childrenProfilePage.restrictionPIN.text}
						placeholder="{helpLink}"
						linkText="ARD Hilfe"
						linkUrl={EXTERNAL_URLS.HELP_CHILDPROFILE}
					/>
				</Column>
				<Column>
					{userService.childPin ? (
						<div className={cx(Toggle.base)}>
							<Switch
								id={'pin'}
								isEnabled={appState.account.nextUser.hasChildPin}
								onClick={onClickPinSwitch}
								label={STRINGS.childrenProfilePage.restrictionPIN.toggle}
							/>
							<label htmlFor={'pin'}>{STRINGS.childrenProfilePage.restrictionPIN.toggle}</label>
						</div>
					) : (
						<div>
							<Button
								// TODO: Change to correct Icon
								iconLeft={<Icon name="account" />}
								text={STRINGS.editAccountPage.createKidsPin.button}
								type={BUTTON_TYPES.SECONDARY}
								href={`/${PAGE_TYPES.PROFILE}/${PAGE_TYPES.CHILD_PIN}`}
								as={Link}
							/>
						</div>
					)}
				</Column>
			</Row>
		</>
	);
}

export default ChildProfilePage;
