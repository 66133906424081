import { ChangeEvent, FormEvent, useState } from 'react';
import { Checkbox } from './styles';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Typo, Input, Column, Row } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../../../services/validation';
import { isTouchDevice } from '../../../services/misc';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import type { AnyFunction, AnyObject } from '../../../types';
import { PAGE_TYPES } from '../../../configs/types';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function RegistrationEmailPage() {
	/** Variables */
	const navigate = useNavigate();
	const { setRegistrationMail } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox]: [boolean, AnyFunction] = useState(false);
	const [termsOfUseCheckbox, setTermsOfUseCheckbox]: [boolean, AnyFunction] = useState(false);
	const isNextStepAllowed = email && privacyPolicyCheckbox && termsOfUseCheckbox;

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setRegistrationMail(email);
			navigate(PAGE_TYPES.PASSWORD);
		}
	}

	function _onPrivacyPolicyCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
		setPrivacyPolicyCheckbox(e.target.checked);
	}

	function _onTermsOfUseCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
		setTermsOfUseCheckbox(e.target.checked);
	}

	function onInputChange(e: AnyObject) {
		if (e.isValid) {
			setEmail(e.value);
		} else {
			setEmail('');
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<form onSubmit={onSubmit}>
					<Column p={0}>
						<p className={cx(Typo.base, Typo.body02)}>Schritt 1 von 4</p>
					</Column>
					<Column px={0}>
						<h1 className={cx(Typo.base, Typo.heading01)}>E-Mail-Adresse</h1>
					</Column>

					<Column px={0}>
						<Input
							type="email"
							label="E-Mail-Adresse"
							isRequired
							errorMessage="Keine valide E-Mail-Adresse"
							onChange={onInputChange}
							validation={isValidEmail}
							hasFocus={!isTouchDevice()}
							hasHintValidation
							htmlAttributes={{
								autoComplete: 'email',
								title: 'E-Mail-Adresse eingeben',
							}}
						/>
					</Column>
					<Column p={0}>
						<label className={cx(Typo.base, Typo.body03, Checkbox.base)}>
							<input
								type="checkbox"
								aria-label="Datenschutzerklärung"
								name="Datenschutzerklärung"
								value="Datenschutzerklärung"
								onChange={_onPrivacyPolicyCheckboxChange}
								title="Datenschutzerklärung zustimmen"
							/>
							<span>
								Ich habe die <Link to={{ pathname: '/datenschutz' }}>Datenschutzerklärung</Link>{' '}
								vollständig gelesen und stimme ihr zu.
							</span>
						</label>
					</Column>
					<Column p={0}>
						<label className={cx(Typo.base, Typo.body03, Checkbox.base)}>
							<input
								type="checkbox"
								name="Nutzungsbedingungen"
								aria-label="Nutzungsbedingungen"
								value="Nutzungsbedingungen"
								onChange={_onTermsOfUseCheckboxChange}
								title="Nutzungsbedingungen zustimmen"
							/>
							<span>
								Ich habe die <Link to={{ pathname: '/nutzungsbedingungen' }}>Nutzungsbedingungen</Link>{' '}
								vollständig gelesen und stimme ihnen zu.
							</span>
						</label>
					</Column>
					<Column p={0}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.registrationEmailPage.form.back.button}
								type={BUTTON_TYPES.SECONDARY}
								href={buildMainLink(PAGE_TYPES.START)}
								as={Link}
								htmlAttributes={{
									type: 'button',
								}}
							/>
							<Button
								text="Weiter"
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!isNextStepAllowed}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default RegistrationEmailPage;
