import { useState } from 'react';
import { buildLogoutLink, buildMainLink } from '../../../services/link';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Box, ButtonsWrapper } from '../../app/styles';
import { redirectToRefererApp } from '../../../services/misc';
import { useContextActions } from '../../../services/actions/ActionsService';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../configs/types';
import userService from '../../../services/user/UserService';
import { useTrackPageImpression } from '../../../services/tracking';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import env from '../../../configs/env';
import { ChangeEmailWrapper } from './styles';

function LoginConfirmPage() {
	/** Variables */
	const { appState } = useContextActions();
	const [email, setEmail] = useState(userService.email);

	async function redirectToReferrer() {
		await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.LOGGED_IN);
	}

	function logoutAndRedirectToLoginConfirmPage() {
		userService.email = null;
		setEmail(null);

		window.location.href = buildLogoutLink(`${env.HOST}${buildMainLink(PAGE_TYPES.LOGIN_CONFIRM)}`, appState);
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.s)}>
			<Row alignItems="center" pb={0} mb={0}>
				<Column px={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.loginConfirmPage.headline}</h1>
				</Column>
				<Column px={0}>
					<p className={cx(Typo.base, Typo.body02)}>{STRINGS.loginConfirmPage.kicker}</p>
				</Column>
				{userService.email && (
					<Column p={0}>
						<div className={cx(ChangeEmailWrapper.base)}>
							<span>{email}</span>
							<Button
								type={BUTTON_TYPES.TEXT}
								text="Ändern"
								htmlAttributes={{ title: 'Andere E-Mail-Adresse eingeben', type: 'button' }}
								onClick={logoutAndRedirectToLoginConfirmPage}
							/>
						</div>
					</Column>
				)}
				<Column p={0}>
					<div className={cx(ButtonsWrapper.base)}>
						<Button
							text={STRINGS.loginConfirmPage.button}
							type={BUTTON_TYPES.PRIMARY}
							onClick={redirectToReferrer}
						/>
					</div>
				</Column>
			</Row>
		</main>
	);
}

export default LoginConfirmPage;
