import { generatePath, Navigate, resolvePath, useLocation, useParams } from 'react-router-dom';

type Props = {
	to: string;
	historyPush?: boolean;
};

function Redirect({ to, historyPush = false }: Props) {
	const location = useLocation();
	const params = useParams();

	if (!to.startsWith('http')) {
		const resolvedPath = resolvePath(to, location.pathname);
		to = generatePath(resolvedPath.pathname, params) + location.search;
	}

	return <Navigate to={to} replace={!historyPush} />;
}

export default Redirect;
