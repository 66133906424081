import { buildLogoutLink } from '../../../../services/link';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function ZdfUser() {
	/** JSX */
	return (
		<Row>
			<Column px={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Konto-Einstellungen</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Sie sind mit Ihrem ZDF-Konto angemeldet. Auf{' '}
					<a href="https://www.zdf.de" target="_blank" rel="noreferrer">
						zdf.de
					</a>{' '}
					können Sie Ihr ZDF-Konto verwalten.
				</p>
			</Column>
			<Column px={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Bitte beachten Sie: Das ZDF-Konto kann nicht für die Freischaltung gesperrter Inhalte verwendet
					werden. Die Altersbestätigung kann nur mit einem ARD-Konto durchgeführt werden.
				</p>
			</Column>
			<Column px={0}>
				<h2 className={cx(Typo.base, Typo.heading02)}>Alter bestätigen?</h2>
			</Column>
			<Column p={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Erstellen Sie ein ARD-Konto, um Inhalte mit Alterssperre rund um die Uhr abspielen zu können.
					Weitere Hinweise und Informationen rund um das ARD-Konto finden Sie auf:{' '}
					<a href="https://1.ard.de/login" target="_blank" rel="noreferrer">
						1.ard.de/login
					</a>
					.
				</p>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button text="ARD-Konto erstellen" type={BUTTON_TYPES.PRIMARY} href={buildLogoutLink()} />
				</div>
			</Column>
		</Row>
	);
}

export default ZdfUser;
