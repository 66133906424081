import { redirectToRefererApp } from '../../../../services/misc';
import { useContextActions } from '../../../../services/actions/ActionsService';
import { buildMainLink } from '../../../../services/link';
import { MESSAGE_TYPES, PAGE_TYPES } from '../../../../configs/types';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { Link, useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function AccountDeleted() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	async function onClick() {
		if (appState.navigation.redirectUrl) {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.ACCOUNT_DELETED);
		} else {
			navigate(buildMainLink(PAGE_TYPES.START));
		}
	}

	/** JSX */
	return (
		<Row>
			<Column p={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Schade, dass Sie gehen!</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Ihr ARD-Konto und alle Profile wurden gelöscht. Sie können sich natürlich jederzeit neu
					registrieren. Bis bald!
				</p>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button
						text="Ein ARD-Konto erstellen"
						href={buildMainLink(PAGE_TYPES.REGISTRATION)}
						type={BUTTON_TYPES.SECONDARY}
						as={Link}
					/>
					<Button text={STRINGS.general.done.button} onClick={onClick} type={BUTTON_TYPES.PRIMARY} />
				</div>
			</Column>
		</Row>
	);
}

export default AccountDeleted;
