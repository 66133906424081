import { css } from '@linaria/core';

export const InfoBox = {
	base: css`
		display: flex;
		justify-content: left;
		align-items: flex-start;
		box-sizing: border-box;
		border-radius: 8px;
	`,
	text: css`
		text-align: left;
		margin: 0;
	`,
	icon: css`
		display: flex;
		padding: 0 var(--infoblock-spacing-padding-description);
		svg {
			fill: rgb(var(--text-color-body-01));
			width: var(--typography-line-height-body-01-rem);
			height: var(--typography-line-height-body-01-rem);
		}
	`,
};
