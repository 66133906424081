import { isUrl } from '../validation';
import defaultState from './../../configs/defaultState';
import { BASE_PROFILE_IMAGE_URL } from '../../configs/constants';

export default function buildProfileImageUrl(image?: string | null): string {
	if (!image) {
		return defaultState.account.nextUser.image;
	}
	// TODO: Remove fallback logic once no old imageIds are saved in profiles anymore.
	const legacyImageId = image.match(/profileImages\/profileImage([1-5])\.png$/)?.[1];
	if (legacyImageId) {
		return `${BASE_PROFILE_IMAGE_URL}/${PROFILEIMAGES.ard[parseInt(legacyImageId) - 1]}`;
	}

	if (
		isUrl(image) ||
		// need this for localhost as its not a valid url without
		isUrl(image, {
			host_whitelist: ['localhost'],
		})
	) {
		return image;
	}
	// PROFILEIMAGES const object is baked on build and needs this fallback to get the domain path
	return `${BASE_PROFILE_IMAGE_URL}/${image}`;
}
