import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useContextActions } from '../../../services/actions/ActionsService';
import userService from '../../../services/user/UserService';
import defaultState from '../../../configs/defaultState';
import BaseProfilePage from './BaseProfilePage';
import { PAGE_TYPES } from '../../../configs/types';

function ContextProfilePage() {
	/** Variables */
	const userId = useParams().userId ?? userService.id;
	const { setNextUser } = useContextActions();
	const { '*': subPath } = useParams();

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (userId && subPath !== PAGE_TYPES.PROFILE_ADD) {
			const user = userService.getUserById(userId);

			if (user) {
				setNextUser({
					name: user.name,
					image: user?.image,
					ageRating: user.ageRating,
					hasChildPin: user.isSubUser && !!user.pin,
				});
			}
		}
		return () => setNextUser(defaultState.account.nextUser);
	}, []);

	useEffect(() => {
		if (userId && subPath === userId) {
			const user = userService.getUserById(userId);

			if (user) {
				setNextUser({
					ageRating: user.ageRating,
					hasChildPin: user.isSubUser && !!user.pin,
				});
			}
		} else if (subPath === PAGE_TYPES.PROFILE_ADD) {
			setNextUser({
				ageRating: undefined,
				hasChildPin: undefined,
			});
		}
	}, [subPath]);

	/** JSX */
	switch (subPath) {
		case PAGE_TYPES.PROFILE_IMAGE:
		case PAGE_TYPES.CHILD_PIN:
		case `${userId}/${PAGE_TYPES.DELETE}`:
		case `${userId}/${PAGE_TYPES.PROFILE_IMAGE}`:
			return <Outlet />;
		case PAGE_TYPES.PROFILE_ADD:
		case `${PAGE_TYPES.PROFILE_ADD}/${PAGE_TYPES.PROFILE_CHILD}`:
			return <BaseProfilePage />;
		default:
			return <BaseProfilePage userId={userId} isEdit />;
	}
}

export default ContextProfilePage;
