import { DEVICE_TYPES, MODE_TYPES, PAGE_TYPES, THEME_TYPES } from './types';
import type { IdentityCardData } from '../server/app/ageVerification/verifyIdentityCardData';
import { User } from '../types';
import { BASE_PROFILE_IMAGE_URL } from './constants';

export type AppState = {
	config: {
		deviceType: string;
		modeType: MODE_TYPES;
		tracking: boolean;
	};
	navigation: {
		entryPageSlug: string;
		redirectUrl: string;
		oidcState: string;
		idToken: string;
		clientId: string;
	};
	view: {
		theme: THEME_TYPES;
		isPasswordConfirmed: boolean;
	};
	ageVerification: {
		idCardData: IdentityCardData | null;
	};
	account: {
		email: string;
		nextUser: Omit<User, 'id' | 'pin'> & { hasChildPin?: boolean };
	};
};

const state: AppState = {
	config: {
		deviceType: DEVICE_TYPES.RESPONSIVE,
		modeType: MODE_TYPES.STANDALONE,
		tracking: false,
	},
	navigation: {
		entryPageSlug: PAGE_TYPES.START,
		redirectUrl: '',
		oidcState: '',
		idToken: '',
		clientId: '',
	},
	view: {
		theme: THEME_TYPES.DEFAULT,
		isPasswordConfirmed: false,
	},
	ageVerification: {
		idCardData: null,
	},
	account: {
		email: '',
		nextUser: {
			name: '',
			image: `${BASE_PROFILE_IMAGE_URL}/${PROFILEIMAGES.ard[0]}`,
			ageRating: undefined,
			hasChildPin: undefined,
			isSubUser: false,
		},
	},
};

/* Init AppState */
if (typeof window !== 'undefined' && window && window.location) {
	if (/Mobi|Android|iPad|iPhone|Windows Phone|BlackBerry|Tablet|Kindle|Silk/.test(navigator.userAgent)) {
		if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
			state.config.deviceType = DEVICE_TYPES.PHONE;
		} else {
			state.config.deviceType = DEVICE_TYPES.TABLET;
		}
	}

	const url = new URL(window.location.href);
	const entryPageSlug = url.pathname.replace(/^\/|\/$/g, '');
	const entryPageType = entryPageSlug.split('/').at(-1);
	let redirectUrl = url.searchParams.get('redirect_uri');
	const modeType = url.searchParams.get('modeType') as MODE_TYPES;
	const oidcState = url.searchParams.get('sessionId');
	const hashParams = new URLSearchParams(url.hash.substring(1));
	const idToken = hashParams.get('idToken');
	const clientId = url.searchParams.get('clientId');

	if (Object.values(PAGE_TYPES).includes(entryPageType as PAGE_TYPES)) {
		state.navigation.entryPageSlug = entryPageSlug;
	}

	if (redirectUrl) {
		redirectUrl = decodeURIComponent(redirectUrl);

		//TODO: Fix for wrong redirect_uri in old Android-App. Remove in future.
		redirectUrl = redirectUrl.replace(
			'https://www.ardmediathek.de/&trackingEnabled',
			'https://www.ardmediathek.de',
		);

		state.navigation.redirectUrl = redirectUrl;
	}

	if (modeType) {
		state.config.modeType = modeType;
	}

	if (oidcState) {
		state.navigation.oidcState = oidcState;
	}

	if (idToken) {
		state.navigation.idToken = idToken;
	}

	if (clientId) {
		state.navigation.clientId = clientId;
	}

	if (url.searchParams.has('trackingEnabled')) {
		state.config.tracking = true;
	}
}

export default state;
