import { useState } from 'react';
import { SentMailAgainButtonWrapper } from './styles';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { useNavigate } from 'react-router-dom';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { auth } from '../../../firebase';
import MessageWidget from '../../widgets/message/MessageWidget';
import { AnyFunction } from '../../../types';
import { ErrorMessages } from '../../../configs/constants';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import userService from '../../../services/user/UserService';

function RegistrationMailSentPage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [isLoading, setIsLoading] = useState(false);
	const [messageType, setMessageType]: ['error' | 'success' | undefined, AnyFunction] = useState('success');
	const [message, setMessage] = useState('');

	async function onClickFinish() {
		// We need to signOut here to reset the partially filled UserService
		// and ensure proper login behavior with verified email.
		await userService.logout();
		navigate(buildMainLink(PAGE_TYPES.START));
	}

	async function onSendAgainClick() {
		setIsLoading(true);
		const user = auth.currentUser;
		try {
			if (user) {
				await sendEmailVerification(user, appState);
				setMessageType('success');
				setMessage('E-Mail wurde erfolgreich versendet.');
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setMessageType('error');
				setMessage(ErrorMessages.DEFAULT);
				throw Error('No User Account found');
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setMessageType('error');
			setMessage(ErrorMessages.DEFAULT);
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<Column px={0}>
					<p className={cx(Typo.base, Typo.body02)}>Schritt 4 von 4</p>
				</Column>
				<Column px={0}>
					<h1 className={cx(Typo.base, Typo.heading01)}>Fast geschafft!</h1>
				</Column>
				<Column p={0}>
					<p className={cx(Typo.base, Typo.body02)}>
						Eine E-Mail wurde an <b>{appState.account.email}</b> verschickt.{' '}
						<b>Bitte beachten Sie auch den Spam-Ordner.</b>
					</p>
				</Column>
				<Column p={0}>
					<div className={cx(SentMailAgainButtonWrapper.base)}>
						<p className={cx(Typo.base, Typo.body02)}>Keine E-Mail erhalten?</p>
						<Button
							text={'E-Mail erneut senden'}
							type={BUTTON_TYPES.TEXT}
							isLoading={isLoading}
							onClick={onSendAgainClick}
						/>
					</div>
				</Column>
				{message && (
					<Column p={0}>
						<MessageWidget type={messageType}>{message}</MessageWidget>
					</Column>
				)}
				<Column p={0}>
					<div className={cx(ButtonsWrapper.base)}>
						<Button
							text={STRINGS.registrationMailSentPage.form.submit.button}
							type={BUTTON_TYPES.PRIMARY}
							onClick={onClickFinish}
							htmlAttributes={{
								type: 'button',
							}}
						/>
					</div>
				</Column>
			</Row>
		</main>
	);
}

export default RegistrationMailSentPage;
