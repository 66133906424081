import { cx } from '@linaria/core';
import { AvatarArea, AvatarCustomBox, ControlArea } from './styles';
import { Box } from '../../app/styles';
import { Button, Typo } from '@ard-online/component-styleguide';
import { PROFILEIMAGE_GROUP_NAMES } from '../../../configs/constants';
import ProfileImageSwiperWidget from '../../widgets/profileImageSwiper/ProfileImageSwiperWidget';
import { FormEvent, useState } from 'react';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';
import { useNavigate } from 'react-router-dom';
import { useTrackPageImpression } from '../../../services/tracking';
import { buildProfileImageUrl } from '../../../services/url';

function EditProfileImagePage() {
	/** Variables */
	const navigate = useNavigate();
	const {
		appState: {
			account: { nextUser },
		},
		setNextUser,
	} = useContextActions();

	const [selectedImage, setSelectedImage] = useState<string>(nextUser.image);

	function onClickImage(image: string) {
		setSelectedImage(image);
	}

	function onSubmitImage(e: FormEvent) {
		e.preventDefault();
		setNextUser({ image: selectedImage });
		navigate(-1);
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.full, AvatarCustomBox.base)}>
			<form onSubmit={onSubmitImage}>
				<div className={cx(AvatarArea.base)}>
					<h1 className={cx(Typo.base, Typo.heading01)}>{STRINGS.profilePage.avatarSelection.headline}</h1>

					{PROFILEIMAGES &&
						Object.keys(PROFILEIMAGES).map((groupName) => (
							<ProfileImageSwiperWidget
								key={groupName}
								selectedImage={selectedImage}
								onClickImage={onClickImage}
								items={PROFILEIMAGES[groupName].map((imageKey) => buildProfileImageUrl(imageKey))}
								title={PROFILEIMAGE_GROUP_NAMES[groupName as keyof typeof PROFILEIMAGE_GROUP_NAMES]}
							/>
						))}
				</div>

				<div className={cx(ControlArea.base)}>
					<Button
						text={STRINGS.general.cancel.button}
						type={BUTTON_TYPES.SECONDARY}
						htmlAttributes={{
							type: 'button',
						}}
						onClick={() => {
							navigate(-1);
						}}
					/>

					<Button
						text={STRINGS.general.done.button}
						type={BUTTON_TYPES.PRIMARY}
						isDisabled={selectedImage === nextUser?.image}
						htmlAttributes={{
							type: 'submit',
						}}
					/>
				</div>
			</form>
		</main>
	);
}

export default EditProfileImagePage;
