import { ReactNode } from 'react';
import { Typo } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

type SophoraParagraph = {
	type: string;
	text: string;
};
export type SophoraData = {
	headline?: string;
	teaserTextLong?: string;
	copytext?: Array<SophoraParagraph>;
	metaData?: Array<{ twitterImageSrc?: string }>;
};
export default function parseSophoraCopyText(data?: SophoraData): ReactNode {
	return data?.copytext?.map((paragraph, index) => {
		switch (paragraph.type) {
			case 'absatz':
				return (
					<p className={cx(Typo.base, Typo.body02)} key={index}>
						{paragraph.text}
					</p>
				);

			case 'ueberschrifth2':
				return (
					<h2 className={cx(Typo.base, Typo.heading03)} key={index}>
						{paragraph.text}
					</h2>
				);

			case 'ueberschrifth3':
				return (
					<h3 className={cx(Typo.base, Typo.heading04)} key={index}>
						{paragraph.text}
					</h3>
				);

			default:
				return null;
		}
	});
}
