import { Icon } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';
import { InfoBox } from './styles';
import { ReactNode } from 'react';

type InfoBoxWidgetProps = {
	children: ReactNode;
};

function InfoBoxWidget({ children }: InfoBoxWidgetProps) {
	return (
		<div className={cx(InfoBox.base)}>
			<div className={cx(InfoBox.icon)}>
				<Icon name="info" />
			</div>
			<span className={cx(InfoBox.text)}>{children}</span>
		</div>
	);
}

export default InfoBoxWidget;
