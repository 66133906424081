import { Root } from './styles';
import { Typo, Icon } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';

type Props = {
	children: string;
	type?: 'error' | 'success';
};

function MessageWidget({ children, type = 'error' }: Props) {
	/** JSX */
	return (
		<div className={cx(Root.base, Root.type[type])} role="alert">
			{type === 'error' ? <Icon name="close" /> : <Icon name="checkmark" />}
			<p className={cx(Typo.base, Typo.body03)}>{children}</p>
		</div>
	);
}

export default MessageWidget;
