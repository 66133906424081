import userService from '../user/UserService';
import { IdentityCardData } from '../../server/app/ageVerification/verifyIdentityCardData';

export default async function fetchSetAgeVerificationPin(idCardData: IdentityCardData | null, pin: string) {
	try {
		const idToken = userService.idToken;
		return await fetch('/age-verification', {
			method: idCardData ? 'PUT' : 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				'X-Authorization': `Bearer ${idToken}`,
			},
			body: JSON.stringify({ ...idCardData, pin }),
		});
	} catch (error) {
		console.error(error);
		throw error; // need to rethrow for handling in Page onSubmit
	}
}
