import { TRACKING_PARAM_TYPES, TRACKING_TYPES } from '../../configs/types';
import env from '../../configs/env';
import { nanoid } from 'nanoid';
import userService from '../user/UserService';
import { getUTCTimestamp } from '../../services/dateTime';
import { TrackingEvent } from './types';
import settingsService from '../settings/SettingsService';

const UUID_KEY = 'uuid';
const CHILD_UUID_KEY = 'c-id-uuid';

let clientId: string | null;
let childClientId: { userId: string | undefined; uuid: string } | null;

try {
	clientId = localStorage.getItem(UUID_KEY);
	const idToUuidMappingValue = sessionStorage.getItem(CHILD_UUID_KEY);
	if (idToUuidMappingValue) {
		childClientId = JSON.parse(idToUuidMappingValue).uuid;
	}
} catch (error) {
	console.error(String(error));
}

function getChildClientId() {
	if (childClientId) {
		// if it is the same child profile, return the stored uuid
		if (childClientId.uuid && childClientId.userId === userService.id) {
			return childClientId.uuid;
		}
	}

	// if it is another child profile or no uuid set, generate a new uuid
	childClientId = { userId: userService.id, uuid: nanoid() };
	try {
		sessionStorage.setItem(CHILD_UUID_KEY, JSON.stringify(childClientId));
	} catch (error) {
		console.error(String(error));
	}
	return childClientId.uuid;
}

function getAdultClientId() {
	if (!clientId) {
		clientId = nanoid();
		try {
			localStorage.setItem(UUID_KEY, clientId);
		} catch (error) {
			console.error(String(error));
		}
	}

	return clientId;
}

function getClientId() {
	if (userService.isChild) {
		// if child profile, a new uuid is generated every time the user logs in/switches to child profile
		return getChildClientId();
	} else {
		// remove the stored uuid for the child profile when switching to a regular profile
		if (childClientId) {
			sessionStorage.removeItem(CHILD_UUID_KEY);
			childClientId = null;
		}
		return getAdultClientId();
	}
}

function toSearchParams(trackingEvent: TrackingEvent) {
	const params = new URLSearchParams();

	for (const key in trackingEvent) {
		const value = trackingEvent[key];
		if (value || typeof value === 'number' || typeof value === 'boolean') {
			if (Array.isArray(value)) {
				params.set(key, JSON.stringify(value));
			} else {
				params.set(key, String(value));
			}
		}
	}

	return params;
}

function addViewport(trackingEvent: TrackingEvent): void {
	trackingEvent[TRACKING_PARAM_TYPES.VIEWPORT_HEIGHT] = window.innerHeight;
	trackingEvent[TRACKING_PARAM_TYPES.VIEWPORT_WIDTH] = window.innerWidth;
}

function addUser(trackingEvent: TrackingEvent): void {
	if (userService.id) {
		// if mainUser -> USER_ID & PROFILE_ID are same
		// if subUser -> USER_ID = mainUserId & PROFILE_ID = subUserId;
		trackingEvent[TRACKING_PARAM_TYPES.USER_ID] = userService.mainUserId;
		trackingEvent[TRACKING_PARAM_TYPES.USER_AGE] = userService.ageRating;
		trackingEvent[TRACKING_PARAM_TYPES.SIGN_IN_PROVIDER] = userService.loginType;
		trackingEvent[TRACKING_PARAM_TYPES.PROFILE_ID] = userService.id; //userId of current profile (mainUserId or subUserId)
	}
}

function addCampaign(trackingEvent: TrackingEvent): void {
	const campaign = settingsService.campaign;

	if (campaign) {
		trackingEvent[TRACKING_PARAM_TYPES.CAMPAIGN] = campaign.campaign;
		trackingEvent[TRACKING_PARAM_TYPES.MEDIUM] = campaign.medium;
		trackingEvent[TRACKING_PARAM_TYPES.ADG] = campaign.adg;
		trackingEvent[TRACKING_PARAM_TYPES.AD] = campaign.ad;
		trackingEvent[TRACKING_PARAM_TYPES.TERM] = campaign.term;
		trackingEvent[TRACKING_PARAM_TYPES.TARGET] = campaign.target;
	}
}

async function sendEvent(trackingEvent: TrackingEvent) {
	try {
		const res = await fetch(`${env.TRACKING_PROXY_HOST}/${getClientId()}?${toSearchParams(trackingEvent)}`, {
			keepalive: true, // allows request to survive when window is closed
			credentials: 'omit', // prevent sending useless cookies when deployed, default is same-origin
		});
		env.STAGE_TYPE === STAGE_TYPES.LOCAL && (await res.text()); // let Chrome display response in dev-tools
	} catch (error) {}
}

async function sendToTrackingProxy(type: TRACKING_TYPES, trackingEvent: TrackingEvent) {
	trackingEvent[TRACKING_PARAM_TYPES.VERSION] = VERSION;
	trackingEvent[TRACKING_PARAM_TYPES.TRACKING_TYPE] = type;
	trackingEvent[TRACKING_PARAM_TYPES.DEVICE_TIMESTAMP_UTC] = getUTCTimestamp();
	addUser(trackingEvent);
	addViewport(trackingEvent);
	addCampaign(trackingEvent);

	// TODO: Remove once no client sends this anymore.
	const url = new URL('a://' + trackingEvent[TRACKING_PARAM_TYPES.REFERER]);
	if (url.searchParams.has('idToken')) {
		url.searchParams.delete('idToken');
		trackingEvent[TRACKING_PARAM_TYPES.REFERER] = url.href;
	}
	// TODO: Remove once no client sends this anymore.
	if (url.searchParams.has('email')) {
		url.searchParams.delete('email');
		trackingEvent[TRACKING_PARAM_TYPES.REFERER] = url.href;
	}

	sendEvent(trackingEvent);
}

export default sendToTrackingProxy;
