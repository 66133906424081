import { useContextActions } from '../../../services/actions/ActionsService';
import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { INFO_TYPES, LOGIN_TYPES, MESSAGE_TYPES, MODE_TYPES, PAGE_TYPES } from '../../../configs/types';
import RegistrationEmailPage from '../../pages/registration/RegistrationEmailPage';
import RegistrationPasswordPage from '../../pages/registration/RegistrationPasswordPage';
import RegistrationProfilePage from '../../pages/registration/RegistrationProfilePage';
import RegistrationMailSentPage from '../../pages/registration/RegistrationMailSentPage';
import EmailActionsPage from '../../pages/emailActions/EmailActionsPage';
import ForgotPasswordPage from '../../pages/forgotPassword/ForgotPasswordPage';
import NewPasswordPage from '../../pages/newPassword/NewPasswordPage';
import LoginPage from '../../pages/login/LoginPage';
import EditAccountPage from '../../pages/editAccount/EditAccountPage';
import DeleteAccountPage from '../../pages/deleteAccount/DeleteAccountPage';
import AgeVerificationIdCardPage from '../../pages/ageVerification/AgeVerificationIdCardPage';
import AgeVerificationPinPage from '../../pages/ageVerification/AgeVerificationPinPage';
import AgeVerificationDeletePage from '../../pages/ageVerification/AgeVerificationDeletePage';
import InfoPage from '../../pages/info/InfoPage';
import NotFoundPage from '../../pages/notFound/NotFoundPage';
import ImprintPage from '../../pages/imprint/ImprintPage';
import Redirect from './Redirect';
import UserCodeLoginPage from '../../pages/login/UserCodeLoginPage';
import OidcLoginPage from '../../pages/login/OidcLoginPage';
import LoginConfirmPage from '../../pages/login/LoginConfirmPage';
import userService from '../../../services/user/UserService';
import LoadingIndicatorWidget from '../../widgets/loadingIndicator/LoadingIndicatorWidget';
import { redirectToRefererApp } from '../../../services/misc';
import PrivacyPage from '../../pages/privacy/PrivacyPage';
import TermsOfUse from '../../pages/termsOfUse/TermsOfUsePage';
import SettingsPage from '../../pages/settings/SettingsPage';
import ChildProfilePage from '../../pages/profile/ChildProfilePage';
import DeleteProfilePage from '../../pages/profile/DeleteProfilePage';
import EditProfileImagePage from '../../pages/profile/EditProfileImagePage';
import ProfilePage from '../../pages/profile/ProfilePage';
import ContextProfilePage from '../../pages/profile/ContextProfilePage';
import ChildPinDeletePage from '../../pages/childPin/ChildPinDeletePage';
import ContextEditAccountPage from '../../pages/editAccount/ContextEditAccountPage';
import ChildPinPage from '../../pages/childPin/ChildPinPage';
import { isIdCardDataFulfilled } from '../../../services/validation';
import { buildInfoLink } from '../../../services/link';
import env from '../../../configs/env';
import SsoFallbackPage from '../../pages/SsoFallback/SsoFallbackPage';

function AppRoutes() {
	/** Variables */
	const { appState } = useContextActions();
	const { pathname, search } = useLocation();
	const [searchParams] = useSearchParams(search);
	const isArdLoginType =
		userService.loginType === LOGIN_TYPES.PASSWORD ||
		userService.loginType === LOGIN_TYPES.ID_TOKEN ||
		userService.loginType === LOGIN_TYPES.SSO ||
		userService.loginType === LOGIN_TYPES.AUTO;
	const isZdfLoginType = userService.loginType === LOGIN_TYPES.OIDC_ZDF;
	const isSsoClient = env.SSO_CLIENT_HOSTS.some((host) =>
		appState.navigation.redirectUrl?.includes(host.replace(/^https:\/\/sso./, '')),
	);

	/** JSX */
	return (
		<Routes>
			{/* public legacy deeplink routes */}
			<Route path={PAGE_TYPES.DEEPLINK_ACCOUNT} element={<Redirect to={`/${PAGE_TYPES.ACCOUNT}`} />} />
			<Route path={PAGE_TYPES.DEEPLINK_PROFILE_EDIT}>
				<Route index element={<Redirect to={`/${PAGE_TYPES.PROFILE}`} />} />
				<Route path=":userId" element={<Redirect to={`/${PAGE_TYPES.PROFILE}/:userId`} />} />
			</Route>
			<Route path={PAGE_TYPES.DEEPLINK_PROFILE_MANAGE} element={<Redirect to={`/${PAGE_TYPES.ACCOUNT}`} />} />
			<Route
				path={PAGE_TYPES.DEEPLINK_PROFILE_ADD}
				element={<Redirect to={`/${PAGE_TYPES.PROFILE}/${PAGE_TYPES.PROFILE_ADD}`} />}
			/>
			<Route
				path={PAGE_TYPES.DEEPLINK_CHILD_PIN}
				element={<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.CHILD_PIN}`} />}
			/>
			<Route path={PAGE_TYPES.DEEPLINK_AGE_VERIFICATION}>
				<Route index element={<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.AGE_VERIFICATION}`} />} />
				<Route
					path={PAGE_TYPES.AGE_VERIFICATION_ID_CARD}
					element={<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.AGE_VERIFICATION}`} />}
				/>
				<Route
					path={PAGE_TYPES.AGE_VERIFICATION_PIN}
					element={
						<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.EDIT}`} />
					}
				/>
				<Route
					path={PAGE_TYPES.DEEPLINK_AGE_VERIFICATION_PIN_CHANGE}
					element={
						<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.EDIT}`} />
					}
				/>
				<Route
					path={PAGE_TYPES.DELETE}
					element={
						<Redirect to={`/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.AGE_VERIFICATION}/${PAGE_TYPES.DELETE}`} />
					}
				/>
			</Route>

			{/* AMS routes */}
			<Route
				path={`/${PAGE_TYPES.START}`}
				element={(() => {
					if (appState.config.modeType === MODE_TYPES.OIDC) {
						return <OidcLoginPage />;
					} else if (isArdLoginType) {
						if (appState.navigation.redirectUrl && pathname === `/${PAGE_TYPES.START}`) {
							if (isSsoClient) {
								return <SsoFallbackPage />;
							}
							redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.LOGGED_IN);
							return <LoadingIndicatorWidget />;
						} else {
							return <Redirect to={`/${PAGE_TYPES.ACCOUNT}`} />;
						}
					} else {
						if (isZdfLoginType) {
							return <Redirect to={buildInfoLink(INFO_TYPES.ZDF_USER)} />;
						} else {
							return <LoginPage />;
						}
					}
				})()}
			/>
			<Route path={`/${PAGE_TYPES.LOGIN_USER_CODE}/:userCode?`}>
				<Route
					index
					element={isZdfLoginType ? <Redirect to={`/${PAGE_TYPES.START}`} /> : <UserCodeLoginPage />}
				/>
			</Route>
			<Route
				path={`/${PAGE_TYPES.LOGIN_CONFIRM}`}
				element={(() => {
					if (isArdLoginType) {
						return <LoginConfirmPage />;
					} else if (isZdfLoginType) {
						return <Redirect to={`/${PAGE_TYPES.START}`} />;
					} else {
						return <LoginPage />;
					}
				})()}
			/>
			<Route
				path={`/${PAGE_TYPES.REGISTRATION}`}
				element={
					userService.id && !appState.account.email ? <Redirect to={`/${PAGE_TYPES.START}`} /> : <Outlet />
				}
			>
				<Route index element={<RegistrationEmailPage />} />
				<Route path={PAGE_TYPES.PASSWORD} element={<RegistrationPasswordPage />} />
				<Route path={PAGE_TYPES.PROFILE}>
					<Route index element={<RegistrationProfilePage />} />
					<Route path={PAGE_TYPES.PROFILE_IMAGE} element={<EditProfileImagePage />} />
				</Route>
				<Route path={PAGE_TYPES.REGISTRATION_EMAIL_SENT} element={<RegistrationMailSentPage />} />
			</Route>
			<Route
				path={`/${PAGE_TYPES.EMAIL_ACTIONS}`}
				element={searchParams.has('mode') ? <EmailActionsPage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			/>
			<Route path={`/${PAGE_TYPES.FORGOT_PASSWORD}`} element={<ForgotPasswordPage />} />
			<Route path={`/${PAGE_TYPES.PASSWORD}`} element={<NewPasswordPage />} />
			<Route
				path={`/${PAGE_TYPES.ACCOUNT}`}
				element={
					isArdLoginType ? (
						userService.isSubUser && pathname !== `/${PAGE_TYPES.ACCOUNT}/${PAGE_TYPES.CHILD_PIN}` ? (
							<Redirect to={`/${PAGE_TYPES.PROFILE}`} />
						) : (
							<ContextEditAccountPage />
						)
					) : (
						<Redirect to={`/${PAGE_TYPES.START}`} />
					)
				}
			>
				<Route index element={<EditAccountPage />} />
				<Route path={`${PAGE_TYPES.AGE_VERIFICATION}`} element={<Outlet />}>
					<Route index element={<AgeVerificationIdCardPage />} />
					<Route
						path={PAGE_TYPES.AGE_VERIFICATION_PIN}
						element={
							isIdCardDataFulfilled(appState.ageVerification.idCardData) ? (
								<AgeVerificationPinPage />
							) : (
								<Redirect to={`/${PAGE_TYPES.START}`} />
							)
						}
					/>
					<Route
						path={PAGE_TYPES.EDIT}
						element={
							userService.pin ? (
								<AgeVerificationPinPage isEdit />
							) : (
								<Redirect to={`/${PAGE_TYPES.AGE_VERIFICATION}`} />
							)
						}
					/>
					<Route path={PAGE_TYPES.DELETE} element={<AgeVerificationDeletePage />} />
				</Route>
				<Route path={`${PAGE_TYPES.CHILD_PIN}`} element={<Outlet />}>
					<Route index element={<ChildPinPage />} />
					<Route path={PAGE_TYPES.DELETE} element={<ChildPinDeletePage />} />
				</Route>
				<Route path={`${PAGE_TYPES.DELETE}`} element={<DeleteAccountPage />} />
			</Route>
			<Route
				path={`/${PAGE_TYPES.PROFILE}/*`}
				element={isArdLoginType ? <ContextProfilePage /> : <Redirect to={`/${PAGE_TYPES.START}`} />}
			>
				{/** Edit profile case */}
				<Route path={':userId?'}>
					<Route index element={<ProfilePage isEdit />} />
					<Route path={PAGE_TYPES.PROFILE_CHILD} element={<ChildProfilePage isEdit />} />
					<Route path={PAGE_TYPES.PROFILE_IMAGE} element={<EditProfileImagePage />} />
					<Route path={PAGE_TYPES.DELETE} element={<DeleteProfilePage />} />
				</Route>
				{/** Add profile case */}
				<Route path={PAGE_TYPES.PROFILE_ADD}>
					<Route index element={<ProfilePage />} />
					<Route path={PAGE_TYPES.PROFILE_CHILD} element={<ChildProfilePage />} />
				</Route>
				<Route
					path={`${PAGE_TYPES.CHILD_PIN}`}
					element={userService.childPin ? <Redirect to={`/${PAGE_TYPES.START}`} /> : <ChildPinPage />}
				/>
			</Route>
			<Route path={`/${PAGE_TYPES.INFO}/:infoType`} element={<InfoPage />} />
			<Route path={`/${PAGE_TYPES.IMPRINT}`} element={<ImprintPage />} />
			<Route path={`/${PAGE_TYPES.PRIVACY}`} element={<PrivacyPage />} />
			<Route path={`/${PAGE_TYPES.TERMS_OF_USE}`} element={<TermsOfUse />} />
			<Route path={`/${PAGE_TYPES.SETTINGS}`} element={<SettingsPage />} />
			{/* fallback */}
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default AppRoutes;
