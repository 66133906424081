import { useState } from 'react';
import { Box } from '../../app/styles';
import { INFO_TYPES } from '../../../configs/types';
import { useNavigate } from 'react-router-dom';
import userService from '../../../services/user/UserService';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildInfoLink } from '../../../services/link';
import type { AnyFunction } from '../../../types';
import { cx } from '@linaria/core';
import { useTrackPageImpression } from '../../../services/tracking';
import PinWidget from '../../widgets/pinWidget/PinWidget';
import { fetchSetAgeVerificationPin } from '../../../services/fetch';
import { Column, Row, Typo } from '@ard-online/component-styleguide';

function AgeVerificationPinPage({ isEdit = false }) {
	/** Variables */
	const { appState, setAgeVerificationIdCardData } = useContextActions();
	const navigate = useNavigate();

	const idCardData = appState.ageVerification.idCardData;
	const [isLoading, setIsLoading]: [boolean, AnyFunction] = useState(false);

	async function onSubmit(pin: string) {
		setIsLoading(true);

		if (!isLoading) {
			try {
				const res = await fetchSetAgeVerificationPin(idCardData, pin);

				if (res.ok) {
					await userService.refreshIdToken();
					setAgeVerificationIdCardData(null);
					const infoType = isEdit
						? INFO_TYPES.AGE_VERIFICATION_PIN_CHANGED
						: INFO_TYPES.AGE_VERIFICATION_COMPLETED;
					navigate(buildInfoLink(infoType));
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		}
	}

	function onCancel() {
		navigate(-1);
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.s)}>
			<Row p={0}>
				<Column p={0}>
					<p className={cx(Typo.base, Typo.body02)}>Schritt 2 von 2</p>
				</Column>
				<Column p={0}>
					<PinWidget
						headline={STRINGS.ageVerificationPinPage.changeCode.headline}
						text={STRINGS.ageVerificationPinPage.changeCode.text}
						isLoading={isLoading}
						initialValue={userService.pin}
						onSubmit={onSubmit}
						onCancel={onCancel}
					/>
				</Column>
			</Row>
		</main>
	);
}

export default AgeVerificationPinPage;
