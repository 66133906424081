import { FormEvent, useState } from 'react';
import { Box, ButtonsWrapper } from '../../app/styles';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Link, useNavigate } from 'react-router-dom';
import MessageWidget from '../../widgets/message/MessageWidget';
import { useTrackPageImpression } from '../../../services/tracking';
import { useContextActions } from '../../../services/actions/ActionsService';
import { buildMainLink } from '../../../services/link';
import { PAGE_TYPES } from '../../../configs/types';
import { updateProfile } from 'firebase/auth';
import { auth } from '../../../firebase';
import { ErrorMessages } from '../../../configs/constants';
import sendEmailVerification from '../../../services/auth/sendEmailVerification';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import ProfileNameWithImageWidget from '../../widgets/profileNameWithImage/ProfileNameWithImageWidget';

function RegistrationProfilePage() {
	/** Variables */
	const navigate = useNavigate();
	const { appState } = useContextActions();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string | null>(null);

	const isNextStepAllowed = appState.account.nextUser.name.trim() !== '';

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (isNextStepAllowed && !loading) {
			setLoading(true);
			setMessage(null);

			const user = auth.currentUser;

			try {
				if (user) {
					await updateProfile(user, {
						displayName: appState.account.nextUser.name,
						photoURL: appState.account.nextUser.image,
					});
					await sendEmailVerification(user, appState);
					navigate(`../${PAGE_TYPES.REGISTRATION_EMAIL_SENT}`);
				} else {
					throw Error('No User Account found');
				}
			} catch (error) {
				console.error(error);
				setMessage(ErrorMessages.DEFAULT);
				setLoading(false);
			}
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.xs)}>
			<Row>
				<form onSubmit={onSubmit}>
					<Column px={0}>
						<p className={cx(Typo.base, Typo.body02)}>{STRINGS.registrationProfilePage.form.step_3.text}</p>
					</Column>
					<Column px={0}>
						<h1 className={cx(Typo.base, Typo.heading01)}>
							{STRINGS.registrationProfilePage.form.step_3.headline}
						</h1>
					</Column>
					<Column px={0}>
						<p className={cx(Typo.base, Typo.body02)}>
							{STRINGS.registrationProfilePage.form.step_3.kicker}
						</p>
					</Column>
					<Column px={0}>
						<ProfileNameWithImageWidget url={PAGE_TYPES.PROFILE_IMAGE} />
					</Column>
					{message && (
						<Column p={0}>
							<MessageWidget>{message}</MessageWidget>
						</Column>
					)}
					<Column p={9}>
						<div className={cx(ButtonsWrapper.base)}>
							<Button
								text={STRINGS.registrationProfilePage.back.button}
								type={BUTTON_TYPES.SECONDARY}
								href={buildMainLink(PAGE_TYPES.START)}
								as={Link}
								htmlAttributes={{
									type: 'button',
								}}
							/>
							<Button
								text={STRINGS.general.next.button}
								type={BUTTON_TYPES.PRIMARY}
								isDisabled={!isNextStepAllowed}
								isLoading={loading}
								htmlAttributes={{
									type: 'submit',
								}}
							/>
						</div>
					</Column>
				</form>
			</Row>
		</main>
	);
}

export default RegistrationProfilePage;
