import { css } from '@linaria/core';
import { Root as ButtonRoot } from '@ard-online/component-styleguide/dist/components/Button/styles';

export const ButtonWrapper = {
	base: css`
		display: flex;
		padding: 0 var(--button-base-spacing-gap-base) var(--button-base-spacing-gap-base)
			var(--button-base-spacing-gap-base);
		.${ButtonRoot.base} {
			width: 100%;
			span {
				display: flex;
				justify-content: center;
				margin: auto;
			}
		}
	`,
	zdf: css`
		.${ButtonRoot.base} {
			width: 100%;
			background-color: #fa7d19 !important;
			color: #fff !important;
		}
	`,
};

export const ZdfTermsOfUse = {
	base: css`
		margin: 10px 0 0 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;

		input {
			margin-right: 15px;
			cursor: pointer;
		}
		a {
			font-weight: 600;
		}
	`,
};

export const CodeInputWrapper = {
	base: css`
		margin-top: var(--input-spacing-padding-tb);
		input {
			text-transform: uppercase;
			&::placeholder {
				text-transform: none;
			}
		}
	`,
};

export const ChangeEmailWrapper = {
	base: css`
		display: flex;
		align-items: center;
		justify-content: space-between;
	`,
};
