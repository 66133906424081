import { Link, useParams } from 'react-router-dom';
import { PAGE_TYPES } from '../../../configs/types';
import { Icon, Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ChildButton, ProfileActions } from './styles';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { useContextActions } from '../../../services/actions/ActionsService';
import { useTrackPageImpression } from '../../../services/tracking';
import userService from '../../../services/user/UserService';
import ProfileNameWithImageWidget from '../../widgets/profileNameWithImage/ProfileNameWithImageWidget';

function ProfilePage({ isEdit = false }) {
	/** Variables */
	const { appState } = useContextActions();
	const {
		appState: {
			account: {
				nextUser: { name, ageRating },
			},
		},
	} = useContextActions();

	const userId = useParams().userId ?? (isEdit && userService.id);
	const isSubmitAllowed = name?.trim() !== '';

	/** Construktor-Hook & Effect-Hooks */
	useTrackPageImpression();
	/** JSX */
	return (
		<Row>
			<Column px={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>
					{!userId ? STRINGS.profilePage.add.headline : STRINGS.profilePage.edit.headline}
				</h1>
			</Column>
			<Column pb={0} px={0}>
				<ProfileNameWithImageWidget
					ageRating={appState.account.nextUser.ageRating}
					isMainUser={userId === userService.mainUserId}
				/>
			</Column>
			{userId ? (
				/** Profil Editieren Flow */
				!userService.isSubUser &&
				userService.mainUserId !== userId && (
					<Column p={0} s={8}>
						<div className={ProfileActions.base}>
							<Button
								text={
									ageRating || ageRating === 0
										? STRINGS.childrenProfilePage.edit.headline
										: STRINGS.profilePage.convertToChildrenProfile.button
								}
								type={BUTTON_TYPES.TEXT_EXT}
								iconLeft={<Icon name="edit" />}
								iconRight={<Icon name="chevronRight" />}
								href={PAGE_TYPES.PROFILE_CHILD}
								as={Link}
							/>
						</div>
						<hr />
						<div className={ProfileActions.base}>
							<Button
								text={STRINGS.profilePage.delete.button}
								type={BUTTON_TYPES.TEXT_EXT}
								iconLeft={<Icon name="trashcan" />}
								iconRight={<Icon name="chevronRight" />}
								href={PAGE_TYPES.DELETE}
								as={Link}
							/>
						</div>
					</Column>
				)
			) : (
				/** Profil Erstellen Flow */
				<div className={cx(ChildButton.base)}>
					<Button
						isActive={isSubmitAllowed}
						iconLeft={<Icon name="chevronRight" />}
						text={STRINGS.profilePage.addChildrenProfile.button}
						type={BUTTON_TYPES.PRIMARY}
						href={PAGE_TYPES.PROFILE_CHILD}
						as={Link}
					/>
				</div>
			)}
		</Row>
	);
}

export default ProfilePage;
