import env from '../configs/env';

/* ZDF-IDs are checked in validation.js */
export const oidcRedirectWhitelist: Record<string, Array<RegExp>> = Object.freeze({
	local: [new RegExp(`^${env.HOST}($|/)`)],
	sonos: [
		new RegExp(
			'^https://vox-api((-dev|-stage)\\.ard\\.swr\\.digital|\\.ardaudiothek\\.de)/sonos/auth/callback($|/)',
		),
	],
	// OIDC-Client ZDF
	oZKK3boGfqqahUBoDozvAeGwh48LACct: [
		new RegExp('^https://([\\w-]+\\.)*zdf\\.(de|dev)($|/)'),
		new RegExp('^https://(cloud-test-www\\.exozet|cloud-migration-www\\.exozet)\\.zdf\\.de/mein-zdf($|/)'),
	],
	// OIDC-Client AT Amazon Voice
	'at-voice-dev-1WsxVBhpfh': [new RegExp('^https://layla\\.amazon\\.com/api/skill/link/M36MD61PHXHIY1($|/)')],
	// OIDC-Client AT Google-Assistant
	'google-action-ard-audiothek-f7e5f': [
		new RegExp('^https://oauth-redirect\\.googleusercontent\\.com/r/ard-audiothek-(f7e5f|test|debug)($|/)'),
	],
	// OIDC-Client SWR3
	'gN41iZVCMtzAQfchD7n0fKk3Tg36Liw2.www.swr3.de': [
		new RegExp('^https://www\\.swr3\\.de/extern/sso/ard/callback($|/)'),
	],
	// OIDC-Client SWR Radio-Hub
	'swr-radiohub-dev': [new RegExp('^https://api\\.lab\\.swr\\.de/radiohub(-dev|-stage)?/v2/user/auth/ard($|/)')],
	// OIDC-Client Tagesschau Meta
	'meta-1ebd1ea8092d6571': [
		new RegExp('^https://meta(-dev|-prelive)?\\.tagesschau\\.de/openid-connect/ams($|/)'),
		new RegExp('^https://meta-tagesschau\\.dev\\.erd\\.fish/openid-connect/ams($|/)'),
	],
	'kompass-coral': [new RegExp('^https://coraltalk\\.(kmp-177|dev)\\.kompass\\.gg/api/auth/oidc/callback$')],
});

export const redirectWhitelist = [
	new RegExp('^https://accounts(-dev|-test)?\\.ard\\.de($|/)'),
	new RegExp('^http://localhost:30(10|20|40)($|/)'), // local AMS, MT, AT
	new RegExp(
		'^https://(pre\\.|live\\.|sophora-preview-dev\\.|sophora-preview-prod\\.|dev\\.|test\\.|www\\.)?ardmediathek\\.de($|/)',
	),
	new RegExp('^de\\.ardmediathek\\.(ios|tvos|android)://'),
	new RegExp('^https://(dev\\.|test\\.|www\\.|preview(-test)?\\.)?ardaudiothek\\.de($|/)'),
	new RegExp('^https://at-preview\\.iap\\.infra\\.cloud\\.ard\\.de($|/)'),
	new RegExp('^ardaudiothek\\.(ios|android)://'),
	new RegExp('^https://web\\.(dev\\.|prod\\.)?cloud\\.ard\\.de($|/)'),
	new RegExp('^https://kommentare\\.mdr\\.de($|/)'),
	new RegExp('^https://(kommentare-mdr-test\\.|demo-mdr\\.talk\\.)conversar\\.io(/api/v1/auth/ard/callback|$|/)'),
	new RegExp('^https://(.*?\\.b\\.|beta\\.|www\\.)?tagesschau\\.de($|/)'),
	new RegExp('^https://(.*?\\.b\\.|beta\\.|www\\.)?sportschau\\.de($|/)'),
	new RegExp('^https://wraps\\.swr\\.de($|/)'),
	new RegExp('^(ard)?quizapp(dev|uat)?://launch'),
];
