import { PAGE_TYPES } from '../../../../configs/types';
import { Button, Column, Row, Typo } from '@ard-online/component-styleguide';
import { ButtonsWrapper } from '../../../app/styles';
import { Link } from 'react-router-dom';
import { buildMainLink } from '../../../../services/link';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function ErrorDeviceAuthorization() {
	/** JSX */
	return (
		<Row>
			<Column p={0}>
				<h1 className={cx(Typo.base, Typo.heading01)}>Login fehlgeschlagen</h1>
			</Column>
			<Column px={0} pb={0}>
				<p className={cx(Typo.base, Typo.body02)}>
					Leider ist bei dem Loginprozess etwas schief gelaufen. Bitte scanne einen neuen QR-Code auf deinem
					Fernseher.
				</p>
			</Column>
			<Column p={0}>
				<div className={cx(ButtonsWrapper.base)}>
					<Button
						text={STRINGS.general.ok.button}
						href={buildMainLink(PAGE_TYPES.ACCOUNT)}
						type={BUTTON_TYPES.PRIMARY}
						as={Link}
					/>
				</div>
			</Column>
		</Row>
	);
}

export default ErrorDeviceAuthorization;
