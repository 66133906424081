import { useMatch, useNavigate } from 'react-router-dom';
import { LogoWrapper, Navigation, Root } from './styles';
import { Button, ChannelLogo, ContextMenu, Icon, Image } from '@ard-online/component-styleguide';
import { MESSAGE_TYPES, MODE_TYPES } from '../../../configs/types';
import { buildLogoutLink } from '../../../services/link';
import { useContextActions } from '../../../services/actions/ActionsService';
import userService from '../../../services/user/UserService';
import { redirectToRefererApp } from '../../../services/misc';
import { cx } from '@linaria/core';
import { Props as ContextMenuProps } from '@ard-online/component-styleguide/dist/components/ContextMenu/ContextMenu';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

function getDomainNameWithoutSubdomain(url?: string) {
	if (url) {
		const urlParts = new URL(url).hostname.split('.');
		return urlParts
			.slice(0)
			.slice(-(urlParts.length === 4 ? 3 : 2))
			.join('.');
	}
}

function ToolbarWidget() {
	/** Variables */
	const { appState } = useContextActions();
	const navigate = useNavigate();

	const contextMenuItems: ContextMenuProps['items'] = [
		{
			text: 'Abmelden',
			type: BUTTON_TYPES.TEXT_EXT,
			href: buildLogoutLink(appState.navigation.redirectUrl),
			iconLeft: <Icon name="logout" />,
		},
	];

	let backButtonText;
	if (appState.config.modeType === MODE_TYPES.OIDC) {
		backButtonText = 'zurück';
	} else {
		const redirectDomainName = getDomainNameWithoutSubdomain(appState.navigation.redirectUrl);
		backButtonText = redirectDomainName ? `Zu ${redirectDomainName}` : undefined;
	}

	const isUserCodeLogin = !!useMatch('/id/*');

	async function onBackButtonClick() {
		if (appState.config.modeType === MODE_TYPES.OIDC) {
			navigate(-1);
		} else {
			await redirectToRefererApp(appState.navigation.redirectUrl, MESSAGE_TYPES.BACK_TO_REFERER_APP);
		}
	}

	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<div className={cx(Navigation.base)}>
				<div className={cx(LogoWrapper.base)}>
					<ChannelLogo channel="ard" />
				</div>
				{backButtonText && (
					<Button
						text={backButtonText}
						type={BUTTON_TYPES.TEXT}
						iconLeft={<Icon name="back" />}
						onClick={onBackButtonClick}
					/>
				)}
			</div>
			{userService.id && !userService.isChild && !isUserCodeLogin && (
				<ContextMenu items={contextMenuItems}>
					<Button
						text="Profil"
						type={BUTTON_TYPES.CHIP}
						isIconOnly
						iconLeft={<Image src={userService.image} />}
					/>
				</ContextMenu>
			)}
		</div>
	);
}

export default ToolbarWidget;
