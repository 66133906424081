import { css } from '@linaria/core';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';
import { Media } from '@ard-online/component-styleguide';

export const ProfileImage = {
	base: css`
		position: relative;
		padding: 4px 0; /* should be 10px, but the border is adding 6px */
		max-width: 120px;

		${Media('max', 's')} {
			max-width: 100px;
		}

		${Media('max', 'xs')} {
			max-width: 70px;
		}
		button {
			-webkit-tap-highlight-color: transparent;
			background: none;
			border: none;
			padding: 0;
			cursor: pointer;
			outline: inherit;
		}
	`,
};

export const ProfileImageSwiperContainer = {
	base: css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		margin-bottom: 24px;
		width: 100%;

		${Media('max', 'xs')} {
			gap: 8px;
		}

		h4 {
			text-align: left;
		}

		.swiper {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			overflow: visible;
			@media (hover: hover) and (pointer: fine) {
				&:hover {
					.swiper-button-prev,
					.swiper-button-next {
						opacity: 1;
					}
				}
			}
		}
		.swiper-button-next,
		.swiper-button-prev {
			top: 50% !important;
			transform: translateY(-50%);

			& > div {
				margin: 0;
			}
		}

		button {
			border: 6px solid transparent;
			border-radius: 50%;
			transition: border 0.1s ease-in-out;
			&:focus-visible,
			&:hover {
				border: 6px solid #fff;
				box-shadow: 0px 0px 8px 0px #00000040;
				> div {
					background: #fff;
				}
			}
		}

		.${ImageRoot.base} {
			border-radius: 50%;
			width: 120px;
			height: 120px;

			${Media('max', 's')} {
				width: 100px;
				height: 100px;
			}

			${Media('max', 'xs')} {
				width: 80px;
				height: 80px;
			}
		}
	`,
};

export const Checkmark = {
	base: css`
		@keyframes ams-zoom-in {
			from {
				opacity: 0;
				transform: scale3d(0.3, 0.3, 0.3);
			}

			50% {
				opacity: 1;
			}
		}
		z-index: 2;
		position: absolute;
		top: -5%;
		right: -24%;

		border-radius: 50%;
		pointer-events: none;
		animation: ams-zoom-in 0.1s ease-in-out;

		background: radial-gradient(#fff 50%, transparent 50%);
		width: var(--icon-sizing-icon-2xl);
		height: var(--icon-sizing-icon-2xl);
		svg {
			fill: rgb(var(--label-color-background-rubric));
			width: var(--icon-sizing-icon-2xl);
			height: var(--icon-sizing-icon-2xl);
			border-radius: 100%;
			position: absolute;
		}

		${Media('max', 's')} {
			top: -5%;
			right: -35%;
			width: var(--icon-sizing-icon-xl);
			height: var(--icon-sizing-icon-xl);
			svg {
				width: var(--icon-sizing-icon-xl);
				height: var(--icon-sizing-icon-xl);
			}
		}

		${Media('max', 'xs')} {
			top: -5%;
			right: -45%;
			width: var(--icon-sizing-icon-xl);
			height: var(--icon-sizing-icon-xl);
			svg {
				width: var(--icon-sizing-icon-xl);
				height: var(--icon-sizing-icon-xl);
			}
		}
	`,
};
