import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cx } from '@linaria/core';
import { Row, Column, Select, Typo, Switch, Button, Icon } from '@ard-online/component-styleguide';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';
import { THEME_TYPES } from '../../../configs/types';
import { useContextActions } from '../../../services/actions/ActionsService';
import { AnyFunction } from '../../../types';
import settingsService from '../../../services/settings/SettingsService';
import { useTrackPageImpression } from '../../../services/tracking';
import { Box } from '../../app/styles';

function SettingsPage() {
	/** Variables */
	const [trackingEnabled, setTrackingEnabled]: [boolean, AnyFunction] = useState(false);
	const { setTheme } = useContextActions();

	const navigate = useNavigate();

	function onClickTrackingSwitch() {
		const newState = !settingsService.trackingEnabled;
		settingsService.trackingEnabled = newState;
		setTrackingEnabled(newState);
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		setTrackingEnabled(settingsService.trackingEnabled);
	}, []);

	useTrackPageImpression();

	/** JSX */
	return (
		<main className={cx(Box.base, Box.type.l)}>
			<Row p={0}>
				<Column p={0}>
					<Button
						text={STRINGS.general.back.button}
						type={BUTTON_TYPES.TEXT}
						iconLeft={<Icon name="back" />}
						onClick={() => {
							navigate(-1);
						}}
						htmlAttributes={{
							type: 'button',
						}}
					/>
				</Column>
			</Row>
			<Row>
				<Column>
					<h1 className={cx(Typo.base, Typo.heading01)}>Einstellungen</h1>
				</Column>
				<Column pb={0}>
					<h2 className={cx(Typo.base, Typo.heading02)}>Datenschutz</h2>
				</Column>
				<hr />
				<Column py={0}>
					<Row justifyContent="space-between" alignItems="center" margin={0} p={0}>
						<Column all={10} p={0}>
							<p className={cx(Typo.base, Typo.body02)}>
								Anonyme Nutzungsstatistiken (Zählpixel) übertragen
							</p>
							<span className={cx(Typo.base, Typo.label02)}>
								Anonyme Nutzungsstatistiken helfen uns, die Website zu verbessern.
							</span>
						</Column>
						<Switch
							id="trackingSwitch"
							isEnabled={trackingEnabled}
							onClick={onClickTrackingSwitch}
							label="Anonyme Nutzungsstatistiken (Zählpixel) übertragen"
						/>
					</Row>
				</Column>
				<hr />
				<Column pb={0}>
					<h2 className={cx(Typo.base, Typo.heading02)}>Darstellung</h2>
				</Column>
				<hr />
				<Column py={0}>
					<Row justifyContent="space-between" alignItems="center" margin={0} p={0}>
						<Column all={6} s={8} py={0}>
							<p className={cx(Typo.base, Typo.body02)}>Design wählen</p>
						</Column>
						<Column all={6} s={4} py={0}>
							<Select label="Design auswählen" onChange={setTheme}>
								<option value={THEME_TYPES.DEFAULT} aria-label="Automatisches Design">
									Automatisch
								</option>
								<option value={THEME_TYPES.LIGHT} aria-label="Helles Design">
									Hell
								</option>
								<option value={THEME_TYPES.DARK} aria-label="Dunkles Design">
									Dunkel
								</option>
							</Select>
						</Column>
					</Row>
					<hr />
				</Column>
			</Row>
		</main>
	);
}

export default SettingsPage;
