import { css } from '@linaria/core';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const ProfilesWrapper = {
	base: css`
		width: 100%;
		ul {
			list-style-type: none;
			padding: 0;
		}

		svg {
			fill: rgb(var(--text-color-body-01));
		}

		.IconOffset {
			margin-left: -16px;
		}

		.RightAlign {
			margin-left: auto;
			width: 48px;
			height: 48px;
		}

		.${ImageRoot.base} {
			width: var(--icon-sizing-icon-s);
			height: var(--icon-sizing-icon-s);
		}
	`,
	childLabel: css`
		padding: 4px;
		border-radius: 2px;
		color: rgba(90, 25, 156, 1);
		background: rgba(254, 190, 0, 1);
		text-decoration: none;
		a & {
			text-decoration: none;
		}
	`,
	linkRow: css`
		display: flex;
		align-items: center;
		gap: var(--contextMenu-spacing-gap-item);
		height: 80px;
		padding: var(--contextMenu-spacing-padding-item);
		box-sizing: border-box;
		text-decoration: none;
		svg {
			margin-left: auto;
			width: var(--icon-sizing-icon-xs);
		}
	`,
};
