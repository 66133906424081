import { Big5Icon, Big5IconsWrapper, Big5List, BottomNav, ListColumn, Root } from './styles';
import { Icon, Button, Column, Typo, Image, Row, ShowMore } from '@ard-online/component-styleguide';
import { cx } from '@linaria/core';
import { BUTTON_TYPES, Props as ButtonProps } from '@ard-online/component-styleguide/dist/components/Button/Button';
import MTAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.mt.jpg';
import TSAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.ts.jpg';
import SPAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.sp.jpg';
import ATAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.at.jpg';
import KikaAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.kika.jpg';
import { Link } from 'react-router-dom';

const buttonCollapsed: ButtonProps = {
	text: 'Alle Angebote mit ARD-Login',
	type: BUTTON_TYPES.SECONDARY,
	iconRight: <Icon name="chevronDown" />,
	isActive: true,
};

const buttonExpanded: ButtonProps = {
	text: 'Alle Angebote mit ARD-Login',
	type: BUTTON_TYPES.SECONDARY,
	iconRight: <Icon name="chevronUp" />,
	isActive: true,
};

function FooterWidget() {
	/** JSX */
	return (
		<div className={cx(Root.base)}>
			<Row justifyContent="center">
				<Column all={12} xs={12} s={8}>
					<nav className={cx(Big5IconsWrapper.base)}>
						<ul className={cx(Big5List.base)} role="menu">
							<li className={cx(Big5Icon.base)} role="presentation">
								<Button
									type={BUTTON_TYPES.PRIMARY}
									text="ARD Mediathek"
									isIconOnly
									href="https://www.ardmediathek.de/"
									target="_blank"
									iconLeft={<Image src={MTAppIcon} ratio="1x1" />}
									accessibility="menuitem"
								/>
								<h3 className={cx(Typo.base, Typo.label02)}>ARD Mediathek</h3>
							</li>
							<li className={cx(Big5Icon.base)} role="presentation">
								<Button
									type={BUTTON_TYPES.CHIP}
									text="tagesschau"
									isIconOnly
									href="https://www.tagesschau.de/"
									target="_blank"
									iconLeft={<Image src={TSAppIcon} />}
									accessibility="menuitem"
								/>
								<h3 className={cx(Typo.base, Typo.label02)}>tagesschau</h3>
							</li>
							<li className={cx(Big5Icon.base)} role="presentation">
								<Button
									type={BUTTON_TYPES.PRIMARY}
									text="Sportschau"
									isIconOnly
									href="https://www.sportschau.de/"
									target="_blank"
									iconLeft={<Image src={SPAppIcon} ratio="1x1" />}
									accessibility="menuitem"
								/>
								<h3 className={cx(Typo.base, Typo.label02)}>Sportschau</h3>
							</li>
							<li className={cx(Big5Icon.base)} role="presentation">
								<Button
									type={BUTTON_TYPES.PRIMARY}
									text="ARD Audiothek"
									isIconOnly
									href="https://www.ardaudiothek.de"
									target="_blank"
									iconLeft={<Image src={ATAppIcon} ratio="1x1" />}
									accessibility="menuitem"
								/>
								<h3 className={cx(Typo.base, Typo.label02)}>ARD Audiothek</h3>
							</li>
							<li className={cx(Big5Icon.base)} role="presentation">
								<Button
									type={BUTTON_TYPES.PRIMARY}
									text="KiKA"
									isIconOnly
									href="https://www.kika.de/"
									target="_blank"
									iconLeft={<Image src={KikaAppIcon} ratio="1x1" />}
									accessibility="menuitem"
								/>
								<h3 className={cx(Typo.base, Typo.label02)}>KiKA</h3>
							</li>
						</ul>
					</nav>
				</Column>
			</Row>
			<Row justifyContent="center">
				<Column className={cx(ListColumn.base)} all={10}>
					<ShowMore buttonPosition="top" buttonCollapsed={buttonCollapsed} buttonExpanded={buttonExpanded}>
						<Column px={0}>
							<a href="https://www.ardmediathek.de/" target="_blank" rel="noreferrer">
								ARD Mediathek
							</a>
						</Column>
						<Column px={0}>
							<a href="https://www.ardaudiothek.de/" target="_blank" rel="noreferrer">
								ARD Audiothek
							</a>
						</Column>
					</ShowMore>
				</Column>
			</Row>
			<Row justifyContent="center">
				<Column>
					<nav className={cx(BottomNav.base)}>
						<ul>
							<li>
								<a href="https://www.ard.de/" target="_blank" rel="noreferrer">
									ARD.de
								</a>
							</li>
							<li>
								<Link to={'/impressum'}>Impressum</Link>
							</li>
							<li>
								<Link to={'/datenschutz'}>Datenschutzerklärung</Link>
							</li>
							<li>
								<Link to={'/nutzungsbedingungen'}>Nutzungsbedingungen</Link>
							</li>
							<li>
								<Link to={'/einstellungen'}>Einstellungen</Link>
							</li>
						</ul>
					</nav>
				</Column>
			</Row>
		</div>
	);
}

export default FooterWidget;
